import { FC } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import { Box } from "@hightouchio/ui";

import { BillingOrganizationQuery } from "src/graphql";
import { Modal } from "src/ui/modal";

import { PricingDisplay } from "../pricing/pricing-display";

export type PricingModalProps = {
  organization: BillingOrganizationQuery["getBillingOrganization"] | undefined;
  isOpen: boolean;
  onClose: () => void;
};

export const PricingModal: FC<Readonly<PricingModalProps>> = ({ isOpen, onClose, organization }) => {
  return (
    <Modal
      bodySx={{ position: "relative", p: 8, bg: "white" }}
      headerSx={{ display: "none" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box
        _hover={{ color: "gray.800" }}
        aria-label="Close pricing modal"
        as="button"
        color="gray.500"
        position="absolute"
        right={2}
        top={2}
        transition="all"
        transitionDuration="faster"
        type="button"
        onClick={onClose}
      >
        <Box as={XMarkIcon} boxSize={5} />
      </Box>

      <PricingDisplay organization={organization} />
    </Modal>
  );
};
