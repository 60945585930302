import { ReactElement } from "react";

import * as Yup from "yup";

import { useGoogleAdsConversionActionsQuery } from "src/graphql";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { ColumnOrConstantField } from "../../column-or-constant-field";
import { MappingsField } from "../../mappings-field";

const MAPPINGS = [
  {
    label: "GCLID",
    value: "gclid",
    type: StandardFieldType.STRING,
  },
  {
    label: "GBRAID",
    value: "gbraid",
    type: StandardFieldType.STRING,
  },
  {
    label: "WBRAID",
    value: "wbraid",
    type: StandardFieldType.STRING,
  },
  {
    label: "Conversion time",
    value: "time",
    type: StandardFieldType.DATETIME,
    required: true,
  },
  {
    label: "Conversion value",
    value: "value",
    type: StandardFieldType.NUMBER,
  },
  {
    label: "Conversion currency",
    value: "currency",
    type: StandardFieldType.STRING,
  },
  {
    label: "External attribution credit",
    value: "credit",
    type: StandardFieldType.NUMBER,
  },
  {
    label: "External attribution model",
    value: "model",
    type: StandardFieldType.STRING,
  },
  {
    label: "Order Id",
    value: "orderId",
    type: StandardFieldType.STRING,
  },
];

export function ClickConversionsForm({
  destinationId,
  config,
  setConfig,
}: Readonly<{
  destinationId: string;
  config: Record<string, unknown>;
  setConfig: (configuration: Record<string, unknown>) => void;
}>): ReactElement<any, any> {
  const {
    data: conversionActionsData,
    error: conversionActionsError,
    isFetching: loadingConversionActions,
    refetch: getConversionActions,
  } = useGoogleAdsConversionActionsQuery({
    destinationId: String(destinationId),
    mode: config?.mode as string,
    accountId: config?.accountId as string,
    loginAccountId: config?.loginAccountId as string,
  });

  const conversionActions = conversionActionsData?.googleListConversionActions?.actions;

  const conversionActionsOptions = conversionActions?.map((a) => ({
    label: a.name,
    value: a.resourceName,
  }));

  return (
    <>
      <Section>
        <ColumnOrConstantField
          columnLabel="Which column contains the conversion action name, resource name or ID?"
          constantDescription="This is the action to each conversion of this sync"
          constantInput={
            <Select
              isError={Boolean(conversionActionsError)}
              isLoading={loadingConversionActions}
              options={conversionActionsOptions}
              placeholder="Select a conversion action..."
              reload={getConversionActions}
              value={config.actionId ? conversionActionsOptions?.find((a) => config?.actionId === a.value) : null}
              width="240px"
              onChange={(action) => setConfig({ ...config, actionId: action.value })}
            />
          }
          constantLabel="What is the conversion action?"
          error={conversionActionsError?.message}
          fieldProps={{ size: "small" }}
          property="actionId"
        />
      </Section>
      <Section>
        <MappingsField options={MAPPINGS} />
      </Section>
    </>
  );
}

export const Schema = Yup.object().shape({
  accountId: Yup.string().required(),
  loginAccountId: Yup.string().nullable().notRequired(),
  mode: Yup.string().required().oneOf(["ClickConversions"]),
  mappings: COMMON_SCHEMAS.mappings,
  actionId: COMMON_SCHEMAS.columnOrConstant,
});
