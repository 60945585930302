import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS } from "src/utils/destinations";

import { ColumnOrConstantField } from "../column-or-constant-field";
import { IdMappingField } from "../id-mapping-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

const optionalIdMapping = Yup.object()
  .shape({
    from: Yup.mixed().nullable().required(),
    to: Yup.string().nullable().required(),
  })
  .notRequired()
  .default(undefined);

export const validation = Yup.object().shape({
  type: Yup.string().required().default("events"),
  mode: Yup.string().when("type", {
    is: "events",
    then: Yup.string().required().default("insert"),
    otherwise: Yup.string().required().default("upsert"),
  }),
  object: Yup.string().when("type", {
    is: "objects",
    then: Yup.string().required().default("user"),
    otherwise: Yup.string().notRequired(),
  }),
  eventName: Yup.mixed().when("type", {
    is: "events",
    then: COMMON_SCHEMAS.columnOrConstant,
    otherwise: Yup.string().notRequired(),
  }),
  userIdFrom: Yup.mixed().when("type", {
    is: "events",
    then: Yup.mixed().required(),
    otherwise: Yup.mixed().notRequired(),
  }),
  timestampFrom: Yup.mixed().notRequired(),
  externalIdMapping: optionalIdMapping.when("type", {
    is: "objects",
    then: COMMON_SCHEMAS.externalIdMapping,
    otherwise: optionalIdMapping,
  }),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
});

const TYPES = [
  { label: "Events", value: "events" },
  { label: "Objects", value: "objects" },
];

const MODES = {
  events: [{ label: "Insert", value: "insert" }],
  objects: [{ label: "Upsert", value: "upsert" }],
};

const OBJECTS = [
  { label: "User Properties", value: "user" },
  { label: "Account Properties", value: "account" },
];

const STATIC_EVENT_PROPERTIES = ["idempotency_key"];

export const HeapForm: FC = () => {
  const { hightouchColumns, errors, config, setConfig } = useDestinationForm();

  const staticMapperOpts = STATIC_EVENT_PROPERTIES.map((property) => ({
    label: property,
    value: property,
  }));

  return (
    <>
      <TypeField options={TYPES} />

      {config?.type === "objects" && (
        <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />
      )}

      {config?.type && (
        <ModeField
          options={config?.type === "events" ? MODES.events : MODES.objects}
          onChange={(mode) => setConfig({ type: config?.type, object: config?.object, mode })}
        />
      )}

      {config?.type === "events" && (
        <Section>
          <Grid gap={8}>
            <ColumnOrConstantField
              columnLabel="Which column contains the event name?"
              constantInput={
                <Input
                  defaultValue={config?.eventName}
                  error={errors?.eventName}
                  placeholder="Enter event name..."
                  sx={{ width: "340px" }}
                  onChange={(value) => {
                    setConfig({
                      ...config,
                      eventName: value,
                    });
                  }}
                />
              }
              constantLabel="What is the event name?"
              property="eventName"
            />
            <Field
              optional
              description={"If this is empty, Heap will use the time the event arrives at the server."}
              error={errors?.timestampFrom}
              label="Which column contains the event timestamp?"
              size="large"
            >
              <Select
                isClearable
                isError={errors?.timestampFrom}
                options={hightouchColumns}
                placeholder="Select a column..."
                value={config?.timestampFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, timestampFrom: val });
                }}
              />
            </Field>
            <Field
              description={"If no such identity exists, then a new user will be created with that identity."}
              error={errors?.userIdFrom}
              label="Which column contains the identity of the user corresponding to the event?"
              size="large"
            >
              <Select
                isError={errors?.userIdFrom}
                options={hightouchColumns}
                placeholder="Select a column..."
                value={config?.userIdFrom}
                width="240px"
                onChange={(selected) => {
                  const val = selected?.value;
                  setConfig({ ...config, userIdFrom: val });
                }}
              />
            </Field>
          </Grid>
        </Section>
      )}

      {config?.type === "objects" && config?.object && (
        <Section>
          <IdMappingField
            options={
              config?.object === "account"
                ? [{ label: "account_id", value: "account_id" }]
                : [{ label: "identity", value: "identity" }]
            }
          />
        </Section>
      )}

      {config?.type === "objects" && config?.object && (
        <Section>
          <MappingsField />
        </Section>
      )}

      {config?.type === "events" && (
        <>
          <Section>
            <MappingsField options={staticMapperOpts} />
          </Section>

          <Section>
            <MappingsField isCustom />
          </Section>
        </>
      )}
    </>
  );
};

export default {
  form: HeapForm,
  validation,
};
