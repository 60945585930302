import { useState } from "react";

import { ArrowPathIcon, PlusIcon as HeroPlusIcon } from "@heroicons/react/24/outline";
import { Select as HightouchUiSelect, Box, Button as HightouchUiButton } from "@hightouchio/ui";

import { useCloudCredentialsV2Query } from "../../graphql";
import { Button } from "../../ui/button";
import { PlusIcon } from "../../ui/icons";
import { Select } from "../../ui/select";
import { CreateCredential } from "./create";

export const SelectCredential = ({ isInvalid = false, useHightouchUi = false, provider, value, onChange }) => {
  const { data, isLoading, refetch, isRefetching } = useCloudCredentialsV2Query({ provider });
  const credentials = data?.getCloudCredentials ?? [];

  const options = credentials.map((cc) => ({
    label: cc.name,
    value: cc.id,
  }));

  const [isCreating, setCreating] = useState<boolean>();

  return (
    <>
      {isCreating && (
        <CreateCredential
          defaultProvider={provider}
          onClose={() => setCreating(false)}
          onCreate={({ id }) => {
            onChange(Number(id));
          }}
        />
      )}

      {useHightouchUi ? (
        <>
          <Box display="flex" gap={3}>
            <HightouchUiSelect
              isInvalid={isInvalid}
              isLoading={isLoading || isRefetching}
              optionLabel={(credential) => credential.name}
              optionValue={(credential) => String(credential.id)}
              options={credentials}
              placeholder="Select credentials..."
              value={value}
              onChange={onChange}
            />

            <HightouchUiButton icon={ArrowPathIcon} onClick={() => refetch()}>
              Refresh
            </HightouchUiButton>

            <HightouchUiButton icon={HeroPlusIcon} onClick={() => setCreating(true)}>
              New credentials
            </HightouchUiButton>
          </Box>
        </>
      ) : (
        <>
          <Select
            isLoading={isLoading}
            options={options}
            placeholder={"Select credentials..."}
            reload={refetch}
            value={value}
            onChange={(selected) => onChange(selected.value)}
          />

          <Button iconBefore={<PlusIcon />} size="small" sx={{ mt: 2 }} variant="secondary" onClick={() => setCreating(true)}>
            Create new
          </Button>
        </>
      )}
    </>
  );
};
