import { ReactNode, useEffect, FC } from "react";

import { get, isEmpty, orderBy } from "lodash";
import { QueryFunction, QueryKey, useQuery } from "react-query";
import { Close, Flex, Grid, Image, Text } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { formatFromColumnOption, formatOptionLabel } from "src/formkit/components/mappings";
import { Arrow } from "src/ui/arrow";
import { Badge } from "src/ui/badge";
import { Box, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Checkbox } from "src/ui/checkbox";
import { Circle } from "src/ui/circle";
import { Field, FieldError } from "src/ui/field";
import { SettingSolidIcon } from "src/ui/icons";
import { LightningIcon } from "src/ui/icons/lightning";
import { Input } from "src/ui/input";
import { Menu } from "src/ui/menu";
import { CreatableSelect, Option, Select } from "src/ui/select";
import { flattenOptions } from "src/ui/select/select";
import { Tooltip } from "src/ui/tooltip";
import { automap, suggest } from "src/utils/automap";
import {
  DEFAULT_MAPPINGS_DESCRIPTION,
  DEFAULT_MAPPINGS_LABEL,
  MAPPINGS_DESCRIPTION,
  MAPPINGS_LABEL,
} from "src/utils/destinations";
import { getObjectName } from "src/utils/syncs";

import { Permission } from "../permission";
import { MappingsHeader } from "./mappings-header";

type LookupConfig = {
  getQuery: (data: any) => { queryKey: QueryKey; queryFn: QueryFunction };
  getResults: (data: any) => any;
};

type MappingsFieldProps = {
  options?: any;
  reload?: () => void;
  loading?: boolean;
  disabled?: boolean;
  allEnabled?: boolean;
  isCustom?: boolean;
  isCreatable?: boolean;
  creatableTypes?: { label: string; value: string }[];
  error?: string;
  errors?: any;
  property?: string;
  lookup?: LookupConfig;
  transformOnCreate?: any;
  createTypes?: any;
  path?: string[];
  hideIdMappings?: boolean;
  required?: boolean;
  settings?: any;
  tip?: ReactNode;
  lookupObjectsMap?: Record<string, unknown>;
};

export const MappingsField: FC<Readonly<MappingsFieldProps>> = ({
  path,
  options = null,
  loading = false,
  reload,
  disabled = false,
  allEnabled = false,
  property = null,
  isCustom = false,
  isCreatable = false,
  creatableTypes = null,
  error = null,
  errors: propErrors = null,
  lookup = null,
  hideIdMappings = false,
  transformOnCreate = null,
  required = false,
  settings,
  tip,
  lookupObjectsMap,
}) => {
  const { slug, config, setConfig, errors: validationErrors, hightouchColumns } = useDestinationForm();

  const errors = propErrors || validationErrors;

  const configKey = property ? property : isCustom ? "customMappings" : "mappings";

  const mapperErrors = errors
    ? Object.entries(errors)?.reduce((acc, [k, v]) => {
        if (k?.startsWith(configKey)) {
          acc[k] = v;
        }
        return acc;
      }, {})
    : null;

  const isRequired = (field) => {
    return options?.some((o) => o.required && o.value === field);
  };

  const configMappings = orderBy(config[configKey], [(m) => !isRequired(m.to)]) || [];

  const setConfigMappings = (mappings) => {
    setConfig({ ...config, [configKey]: mappings });
  };

  useEffect(() => {
    const required =
      options
        ?.filter((o) => o.required && !configMappings?.some((m) => o.value === m.to))
        ?.filter(
          (o) =>
            hideIdMappings ||
            (config?.externalIdMapping?.to !== o?.value && !config?.externalIdMappings?.some((m) => o?.value === m?.to)),
        ) || [];
    if (required?.length) {
      const requiredMappings = required.map((r) => ({ from: null, to: r.value, object: r.object }));

      const newConfigMappings = [...requiredMappings, ...configMappings];
      setConfigMappings(newConfigMappings);
    }
  }, [options, config?.externalIdMapping, config?.externalIdMappings, hideIdMappings]);

  useEffect(() => {
    if (required && configMappings?.length < 1) {
      setConfigMappings([{ from: null, to: null }]);
    }
  }, [required, configMappings]);

  return (
    <Field
      description={
        (path ? get(MAPPINGS_DESCRIPTION[slug ?? ""], path)?.[configKey] : MAPPINGS_DESCRIPTION?.[slug ?? ""]?.[configKey]) ||
        DEFAULT_MAPPINGS_DESCRIPTION[configKey]
      }
      error={
        (!isEmpty(mapperErrors) ? mapperErrors?.[configKey] || "One or more of the mappings are incomplete." : null) || error
      }
      help={
        isCreatable
          ? `Hightouch allows you to create fields in the destination. Type in a field name that does not exist and select "Create field...".`
          : undefined
      }
      label={
        (path ? get(MAPPINGS_LABEL[slug ?? ""], path)?.[configKey] : MAPPINGS_LABEL?.[slug ?? ""]?.[configKey]) ||
        DEFAULT_MAPPINGS_LABEL[configKey]
      }
      size="large"
    >
      {allEnabled && (
        <Checkbox
          label="Sync all columns (columns will be synced with the same name)"
          sx={{ mb: 2 }}
          value={config.autoSyncColumns}
          onChange={(autoSyncColumns) => {
            setConfig({ ...config, autoSyncColumns, mappings: [] });
          }}
        />
      )}
      {(!!configMappings?.length ||
        (config?.externalIdMapping?.from && config?.externalIdMapping?.to && !isCustom) ||
        (config?.externalIdMappings?.some((m) => m.from && m.to) && !isCustom)) && (
        <MappingsHeader
          loading={loading}
          lookup={Boolean(lookup)}
          object={getObjectName(config?.object)}
          reload={reload}
          spacing={40}
        />
      )}

      <Grid gap={4}>
        {!isCustom && !hideIdMappings && (
          <>
            {config?.externalIdMapping?.from && config?.externalIdMapping?.to && (
              <Flex sx={{ alignItems: "center" }}>
                <Select disabled readOnly options={hightouchColumns} value={config?.externalIdMapping?.from} />
                <Arrow />
                <Box sx={{ flex: 1 }}>
                  <Input disabled readOnly value={config?.externalIdMapping?.to} />
                </Box>
                <Tooltip
                  sx={{ ml: 2, p: 2 }}
                  text="Matches the identifier in your model with the identifier in the destination."
                />
              </Flex>
            )}

            {config?.externalIdMappings?.some((m) => m.from && m.to) &&
              !config?.externalIdMapping &&
              config?.externalIdMappings
                ?.filter((m) => m.from && m.to)
                ?.map((m, i) => (
                  <Flex key={i} sx={{ alignItems: "center" }}>
                    <Select disabled readOnly options={hightouchColumns} value={m?.from} />
                    <Arrow />
                    <Box sx={{ flex: 1 }}>
                      <Input disabled readOnly value={m?.to} />
                    </Box>
                    <Tooltip
                      sx={{ ml: 2, p: 2 }}
                      text="Matches the identifier in your model with the identifier in the destination."
                    />
                  </Flex>
                ))}
          </>
        )}
        {configMappings?.length
          ? configMappings.map((mapping, idx) => {
              const toOptions = options?.map((o) => {
                if (
                  mapping?.to !== o?.value &&
                  (config?.externalIdMapping?.to === o?.value ||
                    config?.externalIdMappings?.find((m) => m?.to === o?.value) ||
                    config?.[configKey]?.find((m) => m?.to === o?.value))
                ) {
                  return { ...o, disabled: true };
                } else {
                  return o;
                }
              });

              const selectedOption = toOptions?.find((c) => c.value === mapping?.to);

              const mappingSettings = { ...(settings || {}), ...(selectedOption?.settings || {}) };

              const setMapping = (mapping) => {
                const m = [...configMappings];
                m[idx] = mapping;
                setConfigMappings(m);
              };

              const settingSelected = Object.keys(mappingSettings)?.some((k) => mapping?.[k]);

              const settingMenuOptions = Object.entries(mappingSettings).map(([k, v]) => ({
                label: (
                  <Checkbox
                    label={(v as any).label}
                    value={mapping?.[k]}
                    onChange={(v) => {
                      setMapping({ ...mapping, [k]: v });
                    }}
                  />
                ),
                onClick: () => {},
              }));

              return (
                <Flex key={`${idx}`} sx={{ alignItems: "center" }}>
                  {mapping?.type === "reference" && lookup ? (
                    <Lookup
                      errors={errors}
                      idx={idx}
                      labelsMap={lookupObjectsMap}
                      lookup={lookup}
                      mapping={mapping}
                      setMapping={setMapping}
                    />
                  ) : (
                    <Select
                      disabled={config.autoSyncColumns}
                      formatOptionLabel={formatFromColumnOption}
                      isError={Boolean(mapperErrors && Object.keys(mapperErrors)?.some((e) => e?.includes(`[${idx}].from`)))}
                      options={hightouchColumns}
                      placeholder={`Select a column...`}
                      value={mapping?.from}
                      onChange={(selected) => {
                        const m = [...configMappings];
                        if (!mapping?.to && toOptions?.length) {
                          m[idx] = suggest(
                            selected,
                            toOptions.filter((o) => !o.disabled),
                          );
                        } else {
                          m[idx] = { ...mapping, from: selected.value };
                        }

                        setConfigMappings(m);
                      }}
                    />
                  )}

                  <Arrow />

                  <MappingDestination
                    creatableTypes={creatableTypes}
                    disabled={disabled || config.autoSyncColumns}
                    error={Boolean(mapperErrors && Object.keys(mapperErrors)?.some((e) => e?.includes(`[${idx}].to`)))}
                    isCreatable={isCreatable}
                    loading={loading}
                    lookup={lookup}
                    mapping={mapping}
                    options={toOptions}
                    readOnly={isRequired(mapping?.to)}
                    setMapping={setMapping}
                    tip={tip}
                    transformOnCreate={transformOnCreate}
                  />

                  {(selectedOption?.settings || settings) && (
                    <Menu options={settingMenuOptions}>
                      <Flex sx={{ position: "relative", ml: 3 }}>
                        <Button propagate size="small" sx={{ minWidth: "unset" }} variant="gradient">
                          <SettingSolidIcon size={14} />
                        </Button>
                        {settingSelected && (
                          <Circle color="secondary" radius="8px" sx={{ position: "absolute", right: "-2px", top: "-2px" }} />
                        )}
                      </Flex>
                    </Menu>
                  )}

                  {isRequired(mapping?.to) ? (
                    <Tooltip sx={{ ml: 2, p: 2 }} text="Required" />
                  ) : (
                    !(required && configMappings?.length <= 1) && (
                      <Close
                        sx={{ ml: 2 }}
                        onClick={(event) => {
                          event.stopPropagation();
                          const m = [...configMappings];
                          m.splice(idx, 1);
                          setConfigMappings(m);
                        }}
                      />
                    )
                  )}
                </Flex>
              );
            })
          : null}

        <Permission>
          <Row>
            <Button
              disabled={config.autoSyncColumns}
              variant="secondary"
              onClick={() => {
                const m = [...configMappings];
                m.push({ from: null, to: null });
                setConfigMappings(m);
              }}
            >
              Add mapping
            </Button>
            <Tooltip placement="right" size={14} text={"Suggest mappings"}>
              <Button
                disabled={config.autoSyncColumns}
                sx={{ minWidth: "32px", ml: 1 }}
                variant="text-secondary"
                onClick={() => {
                  const flatColumns = flattenOptions(hightouchColumns);
                  const unmappedOptions = (!options?.length ? flatColumns : options).filter(
                    (o) =>
                      !configMappings.some((m) => m.to === o.value) &&
                      !config?.externalIdMappings?.some((m) => m.to === o.value) &&
                      config?.externalIdMapping?.to !== o.value,
                  );
                  const results = automap(hightouchColumns, unmappedOptions);
                  setConfigMappings([...configMappings, ...results]);
                }}
              >
                <LightningIcon size={16} />
              </Button>
            </Tooltip>
          </Row>
        </Permission>
      </Grid>
    </Field>
  );
};

type LookupProps = {
  idx: number;
  mapping: any;
  setMapping: any;
  lookup: LookupConfig;
  errors: any;
  labelsMap?: Record<string, unknown>; // Map of object ids to the label names if needed
};

const Lookup: FC<Readonly<LookupProps>> = ({ idx, mapping, setMapping, lookup: lookupConfig, errors, labelsMap }) => {
  const { hightouchColumns: options, sourceDefinition, destinationDefinition } = useDestinationForm();

  const lookup = mapping?.lookup;
  const setLookup = (lookup) => {
    setMapping({ ...mapping, lookup });
  };

  const query = lookupConfig.getQuery(lookup.object);

  const {
    data: objectFields,
    isLoading: objectLoading,
    error: objectError,
  } = useQuery<unknown, Error>({
    ...query,
    enabled: Boolean(lookup?.object && lookupConfig),
  });

  const fieldOptions = lookupConfig?.getResults(objectFields) || [];
  const lookupObjectLabel = labelsMap?.[lookup?.object];

  return (
    <Flex sx={{ flexDirection: "column", flex: 1 }}>
      <Flex sx={{ alignItems: "center", flex: 1 }}>
        <Flex sx={{ color: "base.4", fontSize: 2, mr: 2, fontWeight: "semi", whiteSpace: "nowrap" }}>
          Find <Badge sx={{ mx: 1, mb: -1, mr: 2 }}>{lookupObjectLabel || mapping?.lookup?.object}</Badge> ID where
        </Flex>
        <Select
          before={<Image src={destinationDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
          formatOptionLabel={formatOptionLabel}
          isError={errors && Object.keys(errors)?.some((e) => e?.includes(`[${idx}].lookup.by`))}
          isLoading={objectLoading}
          options={fieldOptions}
          placeholder={`Select a field...`}
          value={lookup?.by ? fieldOptions?.find((o) => o?.value === lookup?.by) : null}
          onChange={(selected) => {
            setLookup({ ...lookup, by: selected?.value, byType: selected?.type });
          }}
        />
      </Flex>
      <Flex sx={{ alignItems: "center", mt: 2 }}>
        <Flex sx={{ color: "base.4", fontSize: 2, mr: 2, fontWeight: "semi", whiteSpace: "nowrap" }}>
          Of <Badge sx={{ mx: 1, mb: -1 }}>{lookupObjectLabel || mapping?.lookup?.object}</Badge> equals
        </Flex>
        <Select
          before={<Image src={sourceDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
          isError={errors && Object.keys(errors)?.some((e) => e?.includes(`[${idx}].lookup.from`))}
          options={options}
          placeholder={`Select a column...`}
          value={lookup?.from ? options?.find((o) => o?.value === lookup?.from) : null}
          onChange={(selected) => {
            setLookup({ ...lookup, from: selected?.value });
          }}
        />
      </Flex>

      {objectError && <FieldError error={objectError?.message} />}
    </Flex>
  );
};

interface MappingDestinationProps {
  mapping: any;
  setMapping: any;
  loading: boolean;
  disabled: boolean;
  readOnly?: boolean;
  error: boolean;
  options: Option[];
  isCreatable: boolean;
  creatableTypes: Array<{ label: string; value: string }> | undefined | null;
  lookup?: unknown;
  transformOnCreate?: (value: string) => void;
  tip: ReactNode;
  empty?: ReactNode | null;
}

export const MappingDestination: FC<MappingDestinationProps> = ({
  mapping,
  setMapping,
  loading,
  disabled,
  readOnly = false,
  error = false,
  options,
  isCreatable,
  creatableTypes,
  lookup = null,
  transformOnCreate = null,
  tip = null,
  empty = null,
}) => {
  const selectedOption = options?.find((c) => c.value === mapping?.to);
  const referenceObjectOptions = selectedOption?.referenceObjects?.map((o) => ({ label: o?.name, value: o?.id }));
  if (options) {
    if (!isCreatable) {
      return (
        <Flex sx={{ flexDirection: "column", flex: 1 }}>
          <Select
            before={null}
            disabled={disabled}
            empty={empty}
            formatOptionLabel={formatOptionLabel}
            isError={error}
            isLoading={loading}
            options={options}
            placeholder={`Select a field...`}
            readOnly={readOnly}
            tip={tip}
            value={options?.find((c) => c.value === mapping?.to) || null}
            onChange={(selected) => {
              let newMapping;
              if (selected?.type === "REFERENCE" && lookup) {
                if (mapping?.from) {
                  newMapping = {
                    lookup: { by: null, byType: null, from: mapping?.from, object: selected?.referenceObjects[0]?.id },
                    to: selected.value,
                    object: selected?.object?.id,
                    type: "reference",
                  };
                } else {
                  newMapping = {
                    lookup: { from: undefined, by: null, byType: null, object: selected?.referenceObjects?.[0]?.id },
                    to: selected.value,
                    object: selected?.object?.id,
                    type: "reference",
                  };
                }
              } else {
                if (mapping?.lookup) {
                  newMapping = {
                    from: mapping?.lookup?.from,
                    to: selected.value,
                    object: selected?.object?.id,
                    type: "standard",
                  };
                } else {
                  newMapping = {
                    from: mapping?.from,
                    to: selected.value,
                    object: selected?.object?.id,
                    type: "standard",
                  };
                }
              }
              setMapping(newMapping);
            }}
          />
          {selectedOption?.type === "REFERENCE" && selectedOption?.referenceObjects?.length > 1 && (
            <Flex sx={{ alignItems: "center", mt: 2 }}>
              <Text sx={{ color: "base.4", fontSize: 2, fontWeight: "semi", whiteSpace: "nowrap", mr: 2 }}>Linked to:</Text>
              <Select
                options={referenceObjectOptions}
                value={referenceObjectOptions?.find((o) => o.value === mapping?.lookup?.object) || null}
                onChange={(selected) => {
                  const newMapping = {
                    lookup: { by: null, byType: null, from: undefined, object: selected?.value },
                    to: mapping?.to,
                    object: mapping?.object,
                    type: "reference",
                  };
                  setMapping(newMapping);
                }}
              />
            </Flex>
          )}
        </Flex>
      );
    } else {
      return (
        <Flex sx={{ flexDirection: "column", flex: 1 }}>
          <CreatableSelect
            isClearable
            disabled={disabled}
            empty={empty}
            formatCreateLabel={(string) => {
              return `Create field "${string}"...`;
            }}
            formatOptionLabel={formatOptionLabel}
            isError={error}
            isLoading={loading}
            isValidNewOption={(inputValue, _selectValue, selectOptions) => {
              return !selectOptions.find((v) => v.value === inputValue) && Boolean(inputValue);
            }}
            options={options}
            placeholder={`Select or add a field...`}
            tip={tip}
            value={mapping?.to}
            onChange={(v) => {
              const newMapping = { ...mapping, to: v?.value };
              setMapping(newMapping);
            }}
            onCreateOption={(v) => {
              const to = transformOnCreate ? transformOnCreate(v) : v;
              const newMapping = { ...mapping, to };
              setMapping(newMapping);
            }}
          />
          {creatableTypes && mapping?.to && !options?.find((c) => c.value === mapping?.to) && (
            <Select
              options={creatableTypes}
              placeholder={"Select the type of the field..."}
              sx={{ mt: 2 }}
              value={creatableTypes?.find((o) => o.value === mapping?.fieldType) || null}
              onChange={(selected) => {
                const newMapping = {
                  ...mapping,
                  fieldType: selected?.value,
                };
                setMapping(newMapping);
              }}
            />
          )}
        </Flex>
      );
    }
  } else {
    return (
      <Input
        disabled={disabled}
        error={!!error}
        placeholder={`Enter a field...`}
        value={mapping?.to || ""}
        onChange={(value) => {
          const newMapping = { ...mapping, to: value || null };
          setMapping(newMapping);
        }}
      />
    );
  }
};
