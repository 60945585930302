import { FC } from "react";

import { Box, Spinner, Badge, Text, Tooltip, Pill } from "@hightouchio/ui";

import { DestinationDefinitionsQuery, OrganizationDestinationTypesQuery } from "src/graphql";
import { freeDestinationsTypes, premiumDestinationsTypes } from "src/hooks/use-entitlement";

const unknownDestination = {
  type: "unknown",
  name: "Unknown",
  icon: "",
};
interface DestinationDefinition {
  type: string;
  name: string;
  icon: string;
}

interface Props {
  destinationTypes: OrganizationDestinationTypesQuery | undefined;
  isLoadingDestinationTypes: boolean;
  definitions: DestinationDefinitionsQuery | undefined;
  isLoadingDefinitions: boolean;
}

export const DestinationTiles: FC<Props> = ({
  destinationTypes,
  isLoadingDestinationTypes,
  definitions,
  isLoadingDefinitions,
}) => {
  return (
    <Box>
      {isLoadingDestinationTypes || isLoadingDefinitions ? (
        <Box display="flex" justifyContent="center" py={6}>
          <Spinner />
        </Box>
      ) : (
        <Box display="grid" gap={4} gridTemplateColumns={["1fr", "1fr", "repeat(2,1fr)", "repeat(3, 1fr)", "repeat(3, 264px)"]}>
          {destinationTypes?.getOrganizationDestinationTypes.map((destinationType) => {
            if (!destinationType) {
              return null;
            }

            const definition: DestinationDefinition =
              definitions?.getDestinationDefinitions.find((dest) => dest.type === destinationType.type) || unknownDestination; // If defintion can not be found in the loaded definitions

            return (
              <Box
                key={definition.type}
                alignItems="center"
                border="1px"
                borderColor="gray.300"
                borderRadius="md"
                display="flex"
                gap={2}
                position="relative"
                px={3}
                py={5}
              >
                {definition.icon && <Box alt={definition.name} as="img" flex="none" src={definition.icon} width={9} />}

                <Box flex="1" minWidth={0}>
                  <Text fontWeight="medium">{definition.name}</Text>
                </Box>

                {(freeDestinationsTypes.includes(destinationType.type) ||
                  premiumDestinationsTypes.includes(destinationType.type)) && (
                  <Box position="absolute" right="-10px" top="-10px">
                    <Badge color="blue">{freeDestinationsTypes.includes(destinationType.type) ? "Free" : "Premium"}</Badge>
                  </Box>
                )}

                <Box flex="none">
                  <Tooltip message={`There are ${destinationType.count} destination(s) of this type in the organization.`}>
                    <Pill>{destinationType.count}</Pill>
                  </Tooltip>
                </Box>
              </Box>
            );
          })}

          {destinationTypes?.getOrganizationDestinationTypes?.length === 0 && <Text>No destinations found</Text>}
        </Box>
      )}
    </Box>
  );
};
