import { FormkitLayout } from "./layouts";
import { FormkitModifier } from "./modifiers";
import { FormkitComponent } from "./components";

export type FormkitNode = FormkitLayout | FormkitModifier | FormkitComponent;

export type BaseFormkitNode = {
  type: NodeType;
};

export enum NodeType {
  Layout = "layout",
  Modifier = "modifier",
  Component = "component",
}
