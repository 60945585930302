import { FC } from "react";

import { Image, Heading, Box } from "theme-ui";

import { Row } from "src/ui/box";
import { InlineInput } from "src/ui/input";

type Props = {
  title: string;
  icon?: string;
  rightToolbar?: JSX.Element[];
  onNameChange?: (name: string) => void;
};

export const Header: FC<Readonly<Props>> = ({ title, icon, rightToolbar, onNameChange }) => {
  return (
    <Row sx={{ mb: 8, alignItems: "center" }}>
      <Row sx={{ flexGrow: 1 }}>
        {icon && <Image alt={title} mr={4} src={icon} width="24px" />}
        {onNameChange ? (
          <InlineInput value={title} onChange={onNameChange} />
        ) : (
          <Heading sx={{ fontSize: 24, fontWeight: 500 }} variant="h3">
            {title}
          </Heading>
        )}
      </Row>
      {rightToolbar && (
        <Row sx={{ alignItems: "center" }}>
          {rightToolbar.map((each, index) => {
            return (
              <Box key={index} ml={4}>
                {each}
              </Box>
            );
          })}
        </Row>
      )}
    </Row>
  );
};
