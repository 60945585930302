import { Buffer } from "buffer";

import { useEffect, useMemo, useState } from "react";

import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import { Heading } from "@hightouchio/ui";
import { useToasts } from "react-toast-notifications2";
import { Image } from "theme-ui";

import { SidebarForm } from "src/components/page";
import { SourceForm } from "src/components/sources/setup";
import { SourceCatalog } from "src/components/sources/source-catalog";
import { Testing } from "src/components/sources/testing";
import { useSourceTesting } from "src/components/sources/testing/hooks";
import { useUser } from "src/contexts/user-context";
import {
  ListSourceTestStepsQueryVariables,
  SourceDefinition,
  useCreateSourceV2Mutation,
  useFormkitSourceDefinitionQuery,
  useSampleDataSourceDefinitionsQuery,
  useSourceDefinitionsQuery,
  useUpdateSourceV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { ButtonProps } from "src/ui/button/button";
import { Field } from "src/ui/field";
import { RepeatIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Step } from "src/ui/wizard/wizard";
import { ResourceType, useResourceSlug } from "src/utils/slug";
import { useSource } from "src/utils/sources";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

export type NewSource = { id?: string; definition: SourceDefinition | undefined };

type CreateSourceProps = {
  initialStep?: number;
  previousSourceDefinition?: SourceDefinition;
  onSubmit?: (args: NewSource) => void;
  onConnectClick?(defintion: SourceDefinition): void;
  isDeclaration?: boolean;
};

export const useCreateSourceWizard = ({
  initialStep = 0,
  previousSourceDefinition,
  onSubmit,
  onConnectClick,
  isDeclaration = false,
}: Readonly<CreateSourceProps>) => {
  const { addToast } = useToasts();
  const {
    data: { source: sourceFromQueryString },
  } = useQueryString();
  const { user } = useUser();
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(ResourceType.Source);
  //Selected source (from Step 1) or matching source from ID
  const [sourceDefinition, setSourceDefinition] = useState<SourceDefinition>();
  const [config, setConfig] = useState<Record<string, unknown>>();
  const [tunnelId, setTunnelId] = useState<string | null>();
  const [credentialId, setCredentialId] = useState<string>();
  const [lightningEnabled, setLightningEnabled] = useState<boolean | undefined>(false);
  const [plannerDatabase, setPlannerDatabase] = useState<string | undefined>();
  const [isContinueModalOpen, setIsContinueModalOpen] = useState<boolean>(false);

  const { results: testResults, steps: testSteps, getTestSteps, runTest, timeElapsed } = useSourceTesting();

  const [step, setStep] = useWizardStepper(initialStep);

  const {
    data: { id: oAuthId, onboardingSourceId }, //IDs would exist for sources that are authenticated by oauth, yet can still be incomplete.
  } = useQueryString();

  const id = oAuthId ?? onboardingSourceId;

  // Retrieve the source if already exists (Steps 2 and 3)
  const { data: existingOauthSource } = useSource(id ?? "", {
    pause: !id,
  });
  const matchingSourceConfig = existingOauthSource?.config;
  const matchingSourceDefinition = existingOauthSource?.definition;

  const { mutateAsync: createSource, isLoading: creating } = useCreateSourceV2Mutation();
  const { mutateAsync: updateSource, isLoading: updating } = useUpdateSourceV2Mutation();

  const { data: sourceDefinitionsData, isLoading: loadingCatalog } = useSourceDefinitionsQuery();
  const sourceDefinitions = sourceDefinitionsData?.getSourceDefinitions;

  const { data: sampleDataSourcesData, isLoading: loadingSampleDataSources } = useSampleDataSourceDefinitionsQuery();
  const sampleDataSources = sampleDataSourcesData?.getSampleDataSourceDefinitions;

  const { data: formkitMethods, isLoading: loadingFormkit } = useFormkitSourceDefinitionQuery(
    { type: sourceDefinition?.type || "" },
    { enabled: Boolean(sourceDefinition), select: (data) => data.formkitSourceDefinition },
  );

  const isOAuth = useMemo(() => {
    if (Array.isArray(formkitMethods)) {
      if (formkitMethods.length === 1) {
        return formkitMethods[0]?.method === "oauth";
      }
      return formkitMethods.find((s) => config?.methodKey === s.key)?.method === "oauth";
    }
    return false;
  }, [formkitMethods, config?.methodKey]);

  //Set source, if found by possibly existing ID.
  useEffect(() => {
    if (matchingSourceConfig && matchingSourceDefinition) {
      setConfig(matchingSourceConfig);
      setSourceDefinition(matchingSourceDefinition);
      setStep(1);
    }
  }, [id, Boolean(matchingSourceConfig), Boolean(matchingSourceDefinition)]);

  useEffect(() => {
    if (step === 2) {
      analytics.track("Source Slug Screen Viewed", {
        source_type: sourceDefinition?.type ?? "",
      });
    }
  }, [step]);

  useEffect(() => {
    if (sourceFromQueryString && !sourceDefinition) {
      const decodeSource = JSON.parse(Buffer.from(sourceFromQueryString, "base64").toString());
      if (decodeSource.type === "googlesheets") {
        setSourceDefinition(sourceDefinitions?.find((def) => def.type === "googlesheets"));
      }
      setStep(1);
    }
  }, [sourceDefinitions, sourceFromQueryString]);

  // move to second step if source definition is provided
  useEffect(() => {
    if (previousSourceDefinition) {
      setSourceDefinition(previousSourceDefinition);
      if (previousSourceDefinition.isSampleDataSource) {
        setConfig({});
      }
      setStep(1);
    }
  }, [previousSourceDefinition]);

  // Set the name and slug of the source automatically
  useEffect(() => {
    if (sourceDefinition?.name) {
      setName(sourceDefinition.name);
    }
  }, [sourceDefinition?.name]);

  const create = async () => {
    let createdId: string | undefined;

    const slug = await getSlug(name);

    if (id) {
      const { updateSourceWithSecrets } = await updateSource({
        id: String(id),
        object: {
          slug,
          config,
          name,
          setup_complete: true,
        },
      });
      createdId = updateSourceWithSecrets?.id.toString();
    } else {
      const { createSourceWithSecrets } = await createSource({
        object: {
          slug,
          name,
          config,
          type: sourceDefinition?.isSampleDataSource ? "sample-data" : sourceDefinition?.type,
          setup_complete: true,
          created_by: user?.id != null ? String(user?.id) : undefined,
          tunnel_id: tunnelId,
          credential_id: credentialId ? String(credentialId) : undefined,
          plan_in_warehouse: lightningEnabled,
          sample_data_source_id: sourceDefinition?.isSampleDataSource ? sourceDefinition.type : null,
          plan_in_warehouse_config: lightningEnabled
            ? {
                plannerDatabase,
              }
            : null,
        },
      });
      createdId = createSourceWithSecrets?.id.toString();
    }

    addToast(`Source ${name} created!`, {
      appearance: "success",
    });

    analytics.track("Source Created", {
      source_name: name,
      source_type: sourceDefinition?.type ?? "",
    });

    onSubmit?.({ id: createdId, definition: sourceDefinition });
  };

  const variables: ListSourceTestStepsQueryVariables = {
    sourceId: id,
    sourceType: sourceDefinition?.type,
    configuration: config,
    credentialId: credentialId ? Number(credentialId) : undefined,
    tunnelId: tunnelId ? String(tunnelId) : undefined,
    warehousePlanConfig: lightningEnabled ? { plannerDatabase } : undefined,
  };

  const steps: Step[] = [
    {
      title: "Select source",
      continue: isDeclaration ? undefined : "Click on a source to continue",
      header: (
        <Row
          sx={{
            alignItems: "center",
            gap: 4,
          }}
        >
          <Heading size="lg">Select a data source</Heading>
        </Row>
      ),
      render: () => (
        <Column sx={{ gap: 8 }}>
          {sampleDataSources && sourceDefinitions && (
            <SourceCatalog
              sampleDataSources={sampleDataSources}
              selection={sourceDefinition}
              sourceDefinitions={sourceDefinitions}
              onSelect={(source) => {
                setSourceDefinition(source);
                if (source?.isSampleDataSource) {
                  setConfig({});
                }
                if (!isDeclaration) {
                  setStep((step) => step + 1);
                }
              }}
            />
          )}
        </Column>
      ),
    },
  ];

  const LIGHTNING_ONLY_TEST_IDS = ["validatePlannerSchemaPermissions", "validateAuditSchemaPermissions"];
  const allNonLightningTestsPassed = testResults?.stepResults
    ?.filter((r) => !LIGHTNING_ONLY_TEST_IDS.includes(r.id))
    .every((r) => r.success);
  const canProgressWithFailedTest =
    testResults?.success === false && allNonLightningTestsPassed && sourceDefinition?.supportsInWarehouseDiffing;

  if (!sourceDefinition?.isSampleDataSource) {
    const continueProps: ButtonProps = { form: "source-form", type: "submit" };
    steps.push(
      ...[
        {
          title: `Connect source`,
          loading: loadingFormkit,
          continue: isOAuth && !id ? "Authorize connection to continue" : undefined,
          continueProps,
          disabled: tunnelId === null,
          onContinue: () => {},
          header: (
            <Row sx={{ alignItems: "center", gap: 4 }}>
              <Image src={sourceDefinition?.icon} sx={{ width: "32px", objectFit: "contain" }} />
              <Heading size="lg">
                {isOAuth ? `Authorize connection to ${sourceDefinition?.name}` : `Connect to ${sourceDefinition?.name}`}
              </Heading>
            </Row>
          ),
          render: () => {
            if (!sourceDefinition) {
              return null;
            }

            return (
              <Row sx={{ alignItems: "flex-start", gap: 8 }}>
                <Column sx={{ flexGrow: 1, gap: 8 }}>
                  <SourceForm
                    config={config}
                    credentialId={credentialId}
                    definition={sourceDefinition}
                    disableAuthMethod={Boolean(id)}
                    hasSetupLightning={false}
                    isSetup={true}
                    lightningEnabled={lightningEnabled}
                    plannerDatabase={plannerDatabase}
                    setConfig={setConfig}
                    setCredentialId={setCredentialId}
                    setLightningEnabled={setLightningEnabled}
                    setPlannerDatabase={setPlannerDatabase}
                    setTunnelId={setTunnelId}
                    sourceId={id}
                    tunnelId={tunnelId}
                    onConnectClick={onConnectClick}
                    onSubmit={async () => {
                      await getTestSteps(variables);
                      runTest(variables);
                      setStep(step + 1);
                    }}
                  />
                </Column>
                <SidebarForm docsUrl={sourceDefinition?.docs ?? ""} name={sourceDefinition?.name ?? ""} />
              </Row>
            );
          },
        },
        {
          title: `Test source`,
          disabled: !testResults?.success && !canProgressWithFailedTest,
          continueTooltip:
            !testResults?.success && !canProgressWithFailedTest ? "Cannot continue with setup until tests pass" : undefined,
          continueLabel:
            !testResults?.success && canProgressWithFailedTest ? "Continue without Lightning sync engine" : undefined,
          onContinue:
            !testResults?.success && canProgressWithFailedTest
              ? () => {
                  setIsContinueModalOpen(true);
                }
              : undefined,
          actions:
            testResults?.success === false ? (
              <Button
                iconBefore={<RepeatIcon color="gray.700" />}
                label={"Test again"}
                size="large"
                sx={{ ml: 4 }}
                variant="secondary"
                onClick={async () => {
                  await runTest(variables);
                }}
              />
            ) : undefined,

          header: (
            <Row sx={{ alignItems: "center", gap: 4 }}>
              <Image src={sourceDefinition?.icon} sx={{ width: "32px", objectFit: "contain" }} />
              <Heading size="lg">Test connection to {sourceDefinition?.name}</Heading>
            </Row>
          ),
          render: () => {
            if (!sourceDefinition) {
              return null;
            }

            return (
              <>
                <Row sx={{ alignItems: "flex-start", mt: 8 }}>
                  <Column sx={{ flexGrow: 1, gap: 8 }}>
                    <Testing
                      config={config}
                      credentialId={credentialId}
                      plannerDatabase={plannerDatabase}
                      results={testResults}
                      sourceDefinition={sourceDefinition}
                      steps={testSteps}
                      timeElapsed={timeElapsed}
                    />
                  </Column>
                  <SidebarForm docsUrl={sourceDefinition?.docs ?? ""} name={sourceDefinition?.name ?? ""} />
                </Row>
                <Modal
                  footer={
                    <>
                      <Button
                        iconBefore={<ArrowLeftIcon width={14} />}
                        variant="secondary"
                        onClick={() => {
                          setIsContinueModalOpen(false);
                        }}
                      >
                        Go back
                      </Button>
                      <Button
                        iconAfter={<ArrowRightIcon color="white" width={14} />}
                        onClick={() => {
                          setPlannerDatabase(undefined);
                          setLightningEnabled(false);
                          setStep((step) => step + 1);
                          setIsContinueModalOpen(false);
                        }}
                      >
                        Continue with read-only Standard sync engine
                      </Button>
                    </>
                  }
                  footerSx={{ justifyContent: "space-between" }}
                  isOpen={isContinueModalOpen}
                  sx={{ maxWidth: 600 }}
                  title={"Lightning sync engine will be disabled"}
                  onClose={() => {
                    setIsContinueModalOpen(false);
                  }}
                >
                  Hightouch was unable to verify the read and write permissions required by the Lightning sync engine. Please
                  address the failed connection tests or continue with the read-only Standard sync engine.
                </Modal>
              </>
            );
          },
        },
      ],
    );
  }

  steps.push({
    title: "Finalize source",
    submitting: creating || updating,
    disabled: !name,
    header: <Heading>Finalize settings for this source</Heading>,
    render: () => (
      <Column sx={{ gap: 16, maxWidth: "600px" }}>
        <Field
          description="Including details about the source's environment (prod/dev), data contents, and owners"
          label="Source name"
        >
          <Input value={name} onChange={(value) => setName(value)} />
        </Field>
      </Column>
    ),
  });

  return {
    createSource: create,
    loading: loadingCatalog || loadingSampleDataSources,
    sourceDefinition,
    step,
    steps,
    setStep,
    id: id,
  };
};
