import { FC, Fragment, useEffect } from "react";

import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { useQuery } from "react-query";
import { Grid, Flex, Image } from "theme-ui";

import { Badge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { FieldError } from "src/ui/field";
import { ArrowRightIcon, XIcon } from "src/ui/icons";
import { Select } from "src/ui/select";
import { fetcher } from "src/utils/fetcher";

import { useFormkitContext } from "./formkit-context";
import { formatOptionLabel, formatFromColumnOption } from "./mappings";
import { MappingsHeader } from "./mappings-header";

type AssociationOption = {
  label: string;
  value: string;
  type: "REFERENCE";
  // Note: association field and object type could be different
  // Ex: label: contact_to_company - objectType: company
  objectType?: string; // Associated object type
  objectLabel?: string; // Associated object type label/name
};

type Props = {
  name: string;
  options?: AssociationOption[];
  loading?: any;
  object?: string;
  error?: string;
  excludeMappings?: string[];
  reload?: () => void;
  ascOptions?: any;
};

/**
 * Example of an associationMapping
  {
    to: "company",
    type: "reference",
    lookup: {
      by: "name",
      from: "company_obj",
      byType: "STRING",
      object: "company",
    },
  }

  to: the association field you're linking (most of the time, it's the associated
    object, but could be named differently)
  lookup: find the company object by name with the model's `company_name` field
*/

export const AssociationMappings: FC<Readonly<Props>> = ({
  name,
  object,
  options,
  loading,
  reload,
  error,
  excludeMappings = [],
  ascOptions,
}) => {
  const { watch, setValue } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    name,
  });
  const { columns, sourceDefinition, destinationDefinition } = useFormkitContext();

  const watchFieldArray = watch(name);

  const controlledFields =
    fields?.map((field, index) => {
      return {
        ...field,
        ...watchFieldArray[index],
      };
    }) || [];

  useEffect(() => {
    const subscription = watch((state, { name: key }) => {
      const currentMappings = state[name];

      // handles when externalIdMapping overlaps with current component
      if (currentMappings && key && excludeMappings.includes(key)) {
        const value = state[key];
        let otherMappings: any[] = [];
        if (Array.isArray(value)) {
          otherMappings = value;
        } else if (typeof value === "object") {
          otherMappings = [value];
        }

        const fieldsWithoutExcluded = currentMappings.filter((c) => !otherMappings.some((e) => c.to === e.to));
        setValue(name, fieldsWithoutExcluded);
      }
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  const excludedFields: any[] = [];

  for (const key of excludeMappings) {
    const watchMapping = watch(key);
    if (Array.isArray(watchMapping)) {
      excludedFields.push(...watchMapping);
    } else if (typeof watchMapping === "object") {
      excludedFields.push(watchMapping);
    }
  }

  // handles existing mappings
  const isOptionExcluded = (option) => {
    const valueAlreadyMapped = controlledFields.some(({ to }) => to === option.value);
    const usedInOtherMappings = excludedFields.some(({ to }) => to === option.value);

    return valueAlreadyMapped || usedInOtherMappings;
  };

  if (!watchFieldArray) {
    return null;
  }

  const graphQLFetch = async ({ query, variables }) => {
    if (query) {
      const fetch = fetcher(query, variables);

      const response = await fetch();

      return response ? Object.values(response as Record<string, unknown>)?.[0] : undefined;
    }
  };

  return (
    <Column>
      <Grid sx={{ gridTemplateColumns: "1fr max-content 1fr max-content", alignItems: "center" }}>
        {controlledFields?.length > 0 && <MappingsHeader columns={4} loading={loading} object={object} reload={reload} />}

        {controlledFields.map(({ id }, index) => (
          <Fragment key={id}>
            <Controller
              name={`${name}.${index}`}
              render={(data) => {
                const destinationFieldOptions = options?.map((option) => {
                  if (isOptionExcluded(option)) {
                    return { ...option, disabled: true };
                  } else {
                    return option;
                  }
                });

                const association = data?.field?.value;
                const asyncAscOptions = !Array.isArray(ascOptions) && ascOptions !== null && ascOptions !== undefined;
                const queryVariables = ascOptions?.variables?.input?.variables;

                const {
                  data: associatedObjectFields,
                  error: queryError,
                  refetch,
                  isFetching,
                } = useQuery<any, Error>(
                  JSON.stringify({
                    name,
                    variables: {
                      ...ascOptions?.variables,
                      input: {
                        ...ascOptions?.variables?.input,
                        variables: { object: association?.lookup?.object, ...queryVariables },
                      },
                    },
                  }),
                  {
                    queryFn: () =>
                      graphQLFetch({
                        query: ascOptions?.query,
                        variables: {
                          input: {
                            ...ascOptions?.variables?.input,
                            variables: { object: association?.lookup?.object, ...queryVariables },
                          },
                        },
                      }),
                    enabled: Boolean(asyncAscOptions && association?.to),
                  },
                );

                const selectFieldProps = {
                  formatOptionLabel,
                  options: destinationFieldOptions,
                  value: data.field.value.to,
                  onChange: (option) => {
                    data.field.onChange({
                      ...data.field.value,
                      to: option?.value,
                      lookup: {
                        object: option?.objectType || option?.value,
                        objectLabel: option?.objectLabel || option?.objectType || option?.value,
                        from: association?.lookup?.from,
                      },
                    });
                  },
                };

                return (
                  <>
                    {association?.to ? (
                      <Flex sx={{ flexDirection: "column", flex: 1 }}>
                        {/** Field in the association */}
                        <Flex sx={{ alignItems: "center", flex: 1 }}>
                          <Flex sx={{ color: "base.4", fontSize: 2, mr: 2, fontWeight: "semi", whiteSpace: "nowrap" }}>
                            Find{" "}
                            <Badge sx={{ mx: 1, mb: -1, mr: 2 }}>
                              {association?.lookup?.objectLabel || association?.lookup?.object}
                            </Badge>{" "}
                            ID where
                          </Flex>
                          <Select
                            before={<Image src={destinationDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
                            formatOptionLabel={formatOptionLabel}
                            isLoading={isFetching}
                            options={associatedObjectFields}
                            placeholder={`Field`}
                            reload={refetch}
                            value={association?.lookup?.by}
                            onChange={(option) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: { ...association?.lookup, by: option?.value || undefined, byType: option?.type },
                              });
                            }}
                          />
                        </Flex>

                        {/** Field in the model */}
                        <Flex sx={{ alignItems: "center", mt: 2 }}>
                          <Flex sx={{ color: "base.4", fontSize: 2, mr: 2, fontWeight: "semi", whiteSpace: "nowrap" }}>
                            Of{" "}
                            <Badge sx={{ mx: 1, mb: -1 }}>
                              {association?.lookup?.objectLabel || association?.lookup?.object}
                            </Badge>{" "}
                            equals
                          </Flex>
                          <Select
                            before={<Image src={sourceDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
                            formatOptionLabel={formatFromColumnOption}
                            options={columns}
                            placeholder={`Column`}
                            value={association?.lookup?.from}
                            onChange={(option) => {
                              data.field.onChange({
                                ...data.field.value,
                                lookup: { ...association?.lookup, from: option?.value },
                              });
                            }}
                          />
                        </Flex>

                        {queryError && <FieldError error={queryError} />}
                      </Flex>
                    ) : (
                      <Select
                        before={<Image src={sourceDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
                        formatOptionLabel={formatFromColumnOption}
                        options={columns}
                        placeholder={`Column`}
                        value={association?.lookup?.from}
                        onChange={(option) => {
                          data.field.onChange({
                            ...data.field.value,
                            lookup: { ...association?.lookup, from: option?.value },
                          });
                        }}
                      />
                    )}

                    <ArrowRightIcon color="base.3" size={16} />

                    <Select {...selectFieldProps} placeholder="Select a field..." />

                    <Button variant="plain" onClick={() => remove(index)}>
                      <XIcon color="base.6" size={18} />
                    </Button>
                  </>
                );
              }}
            />
          </Fragment>
        ))}
      </Grid>

      <FieldError error={error} />

      <Row sx={{ mt: 4 }}>
        <Button variant="secondary" onClick={() => append({ type: "reference" })}>
          Add mapping
        </Button>
      </Row>
    </Column>
  );
};
