import { FC } from "react";

import { useQuery } from "react-query";
import { Image } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Badge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { FieldError } from "src/ui/field";
import { Select } from "src/ui/select";

import { graphQLFetch } from "../formkit";
import { useFormkitContext } from "./formkit-context";
import { formatOptionLabel, formatFromColumnOption } from "./mappings";

type Lookup = {
  object: string;
  from: string;
  by: string;
  byType: string;
};

export type AssociationOptions = Record<string, Array<any>> | { variables: any; query: any };

type Props = {
  name: string;
  // Static association options or handler reference to fetch props base on asc object
  associationOptions?: AssociationOptions;
  associationObjectLabels?: Record<string, unknown>;
  index: number;
  onChange?: (value: any) => void;
  value: { type: "reference"; to: string; lookup: Lookup };
};

const retrieveErrorMessage = (errors, index: number, name: string): string | undefined => {
  for (const direction of ["by", "from"]) {
    const mappingPath = `${name}[${index}].lookup.${direction}`;
    const errorMessage = errors?.[mappingPath];
    if (typeof errorMessage === "string") {
      return errorMessage.replace(mappingPath, "This");
    }
  }
  return "";
};

export const AssociationMapper: FC<Readonly<Props>> = ({ associationOptions, index, name, onChange, value }) => {
  const { columns, sourceDefinition, destinationDefinition } = useFormkitContext();
  const { errors } = useDestinationForm();

  const asyncAscOptions =
    associationOptions?.query &&
    typeof associationOptions?.query === "string" &&
    associationOptions !== null &&
    associationOptions !== undefined;

  const {
    data: associatedObjectFields,
    error: queryError,
    refetch,
    isFetching,
  } = useQuery<any>(
    JSON.stringify({
      name,
      variables: {
        ...associationOptions?.variables,
        input: { ...associationOptions?.variables?.input, variables: { object: value?.lookup?.object ?? value?.to } },
      },
    }),
    {
      queryFn: () =>
        graphQLFetch({
          query: associationOptions?.query,
          variables: {
            ...associationOptions?.variables,
            input: { ...associationOptions?.variables?.input, variables: { object: value?.lookup?.object ?? value?.to } },
          },
        }),
      enabled: Boolean((asyncAscOptions && value?.lookup?.object) ?? value?.to),
    },
  );

  const ascFields = asyncAscOptions ? associatedObjectFields : associationOptions?.[value?.lookup?.object];

  const errorToRender = retrieveErrorMessage(errors, index, name) || (queryError as any);

  return (
    <Column>
      {/** Field in the destination */}
      <Row sx={{ alignItems: "center", flex: 1 }}>
        <Row sx={{ color: "base.4", mr: 2, fontWeight: "semi", whiteSpace: "nowrap", alignItems: "center" }}>
          Find <Badge sx={{ mx: 1, mb: -1, mr: 2 }}>{value?.lookup?.object ?? value?.to}</Badge> where
        </Row>
        <Select
          before={<Image src={destinationDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
          formatOptionLabel={formatOptionLabel}
          isError={!!errors?.[`${name}[${index}].lookup.by`]}
          isLoading={isFetching}
          options={ascFields}
          placeholder={`Select a field...`}
          reload={refetch}
          sx={{ width: "200px" }}
          value={value?.lookup?.by}
          onChange={(option) => {
            if (typeof onChange === "function") {
              onChange({
                ...value,
                lookup: { ...value?.lookup, by: option?.value || undefined, byType: option?.type },
              });
            }
          }}
        />
      </Row>

      {/** Field in the model */}
      <Row sx={{ alignItems: "center", mt: 2 }}>
        <Row sx={{ color: "base.4", mr: 2, fontWeight: "semi", whiteSpace: "nowrap", alignItems: "center" }}>equals</Row>
        <Select
          before={<Image src={sourceDefinition?.icon} sx={{ height: "14px", ml: 2, mr: 0 }} />}
          formatOptionLabel={formatFromColumnOption}
          isError={!!errors?.[`${name}[${index}].lookup.from`]}
          options={columns}
          placeholder={`Select a column...`}
          value={value?.lookup?.from}
          onChange={(option) => {
            if (typeof onChange === "function") {
              onChange({ ...value, lookup: { ...value?.lookup, from: option?.value } });
            }
          }}
        />
      </Row>

      {errorToRender && <FieldError error={errorToRender} />}
    </Column>
  );
};
