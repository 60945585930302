import { FC } from "react";

import { Page } from "src/components/layout";
import { PricingDisplay } from "src/components/pricing/pricing-display";
import { useBillingOrganizationQuery } from "src/graphql";

export const Pricing: FC = () => {
  const { data: orgData } = useBillingOrganizationQuery();
  const organization = orgData?.getBillingOrganization;

  return (
    <Page crumbs={[{ label: "Back to settings", link: "/settings/billing" }]} size="full">
      {organization && <PricingDisplay organization={organization} />}
    </Page>
  );
};
