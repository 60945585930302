import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SequencesIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M5.02783 12.007C5.02783 13.1118 4.13284 14.0073 3.02783 14.0073C1.92347 14.0073 1.02783 13.1118 1.02783 12.007C1.02783 10.9028 1.92347 10.0073 3.02783 10.0073C4.13284 10.0073 5.02783 10.9028 5.02783 12.007Z" />
      <path d="M11.0278 12.007C11.0278 13.1118 10.1328 14.0073 9.02783 14.0073C7.92347 14.0073 7.02783 13.1118 7.02783 12.007C7.02783 10.9028 7.92347 10.0073 9.02783 10.0073C10.1328 10.0073 11.0278 10.9028 11.0278 12.007Z" />
      <path d="M17.0278 12.007C17.0278 13.1118 16.1328 14.0073 15.0278 14.0073C13.9235 14.0073 13.0278 13.1118 13.0278 12.007C13.0278 10.9028 13.9235 10.0073 15.0278 10.0073C16.1328 10.0073 17.0278 10.9028 17.0278 12.007Z" />
      <line strokeLinecap="round" strokeOpacity="0.35" strokeWidth="2" x1="3.12158" x2="14.5718" y1="12.0972" y2="12.0972" />
      <path
        clipRule="evenodd"
        d="M22.5959 12.7869C23.0963 12.3871 23.0971 11.6264 22.5975 11.2256L19.0424 8.37371C18.3879 7.84863 17.4167 8.3146 17.4167 9.15373V10.9688H13.9097C13.3574 10.9688 12.9097 11.4166 12.9097 11.9688C12.9097 12.5211 13.3574 12.9688 13.9097 12.9688H17.4167V14.846C17.4167 15.6842 18.386 16.1504 19.0409 15.6272L22.5959 12.7869Z"
        fillOpacity="0.35"
        fillRule="evenodd"
      />
    </Icon>
  );
};
