import { FC } from "react";

import { get } from "lodash";
import { Flex } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Arrow } from "src/ui/arrow";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";
import {
  DEFAULT_ID_MAPPING_LABEL,
  DEFAULT_ID_MAPPING_DESCRIPTION,
  ID_MAPPING_DESCRIPTION,
  ID_MAPPING_LABEL,
} from "src/utils/destinations";

import { MappingsHeader } from "./mappings-header";

type FromIdFieldProps = {
  fieldName: string;
  path?: string[];
};

export const FromIdField: FC<Readonly<FromIdFieldProps>> = ({ fieldName, path }) => {
  const { slug, config, setConfig, hightouchColumns, errors } = useDestinationForm();

  return (
    <Field
      description={
        (path ? get(ID_MAPPING_DESCRIPTION[slug ?? ""], path) : ID_MAPPING_DESCRIPTION[slug ?? ""]) ||
        DEFAULT_ID_MAPPING_DESCRIPTION
      }
      error={errors?.fromId}
      label={(path ? get(ID_MAPPING_LABEL[slug ?? ""], path) : ID_MAPPING_LABEL[slug ?? ""]) || DEFAULT_ID_MAPPING_LABEL}
      size="large"
    >
      <MappingsHeader object={config?.object} />
      <Flex sx={{ alignItems: "center" }}>
        <Select
          isError={Boolean(errors?.fromId)}
          options={hightouchColumns}
          placeholder="Select a column..."
          value={config?.fromId}
          onChange={(selected) => {
            const val = selected.value;
            setConfig({
              ...config,
              fromId: val,
            });
          }}
        />
        <Arrow />
        <Select
          options={[{ label: fieldName, value: null }]}
          placeholder={`Select a field...`}
          value={{ label: fieldName, value: null }}
          onChange={() => {}}
        />
      </Flex>
    </Field>
  );
};
