import { useState, useEffect, FC } from "react";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { compact, uniqBy } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { Flex, Text } from "theme-ui";
import { useDebounce } from "use-debounce";
import { object, string, number } from "yup";

import { Slug } from "src/components/slug";
import { SelectRegion } from "src/components/workspaces/select-region";
import { useUser } from "src/contexts/user-context";
import {
  AvailableWorkspacesQuery,
  Organization,
  useAvailableWorkspacesQuery,
  useCreateWorkspaceMutation,
  useIsWorkspaceSlugAvailableQuery,
  usePartnerConnectLinkWorkspaceMutation,
  useWorkspacesQuery,
  WorkspacesQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Card } from "src/ui/card";
import { Field } from "src/ui/field";
import { ChevronLeftIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { NewSelect } from "src/ui/new-select";
import { useNavigate } from "src/utils/navigate";
import { generateSlug } from "src/utils/slug";
import { switchWorkspace } from "src/utils/workspaces";

import { colors } from "../../../../design";

const defaultRegion = "us-east-1";

const calculateOrganizations = (
  workspacesData: WorkspacesQuery | undefined,
  availableData: AvailableWorkspacesQuery | undefined,
  userOrganization: Organization | null | undefined, // this is the user's SSO organization (if it is a SSO user)
) => {
  // if the user is in an organization, they should only be able to create workspaces in that organization
  if (userOrganization) {
    return {
      defaultOrganization: userOrganization,
      orgs: [{ label: userOrganization.name, value: userOrganization.id }],
    };
  }

  if (!workspacesData || !availableData) {
    return { defaultOrganization: null, orgs: [] };
  }
  const workspaces = workspacesData.workspaces;
  const orgs = uniqBy(
    compact([
      ...(workspaces || []).map((ws) => ws.organization),
      ...(availableData.getAvailableWorkspaces.joinable || []).map((ws) => ws.organization),
    ]),
    "id",
  );

  if (orgs.length > 0) {
    return {
      defaultOrganization: orgs[0],
      orgs: [{ label: "No organization", value: -1 }, ...orgs.map((org) => ({ label: org.name, value: org.id }))],
    };
  }
  return { defaultOrganization: null, orgs: [] };
};

export const NewWorkspace: FC = () => {
  const { multiRegionEnabled } = useFlags();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location?.state as { partnerConnection: any } | undefined;
  const { user, organization } = useUser();
  const { data: workspaceData } = useWorkspacesQuery();
  const { data: availableData } = useAvailableWorkspacesQuery();

  const [error, setError] = useState<string | null>(null);

  const { defaultOrganization, orgs } = calculateOrganizations(workspaceData, availableData, organization);
  const multipleOrganization = Boolean(orgs.length);

  const { mutateAsync: linkWorkspace } = usePartnerConnectLinkWorkspaceMutation();
  const { mutateAsync: createWorkspace } = useCreateWorkspaceMutation();
  const validationSchema = object().shape({
    name: string().required("Name is required."),
    slug: string().required("Slug is required."),
    region: string().required("Region is required."),
    organization: number().nullable(),
  });
  const { register, control, handleSubmit, watch, formState, setValue } = useForm<{
    name: string;
    slug: string;
    region: string;
    organization: string;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      name: undefined,
      slug: undefined,
      region: defaultRegion,
      organization: defaultOrganization?.id,
    },
  });
  const { errors } = formState;

  const [isCreatingWorkspace, setIsCreatingWorkspace] = useState(false);

  const contact = () => {
    const intercom = window["Intercom"];

    if (typeof intercom === "function") {
      intercom("showNewMessage", "Hi, I'm having trouble creating a workspace.");
    }

    analytics.track("Error CTA Clicked", {
      user_id: user?.id,
    });
  };

  const submit = async ({ name, slug, region, organization }) => {
    try {
      setError(null);
      setIsCreatingWorkspace(true);

      const data = await createWorkspace({
        name,
        slug,
        region,
        organization: organization === -1 ? undefined : organization,
      });
      analytics.track("New Workspace Created", {
        workspace_id: data?.createWorkspace?.id,
      });

      if (state?.partnerConnection) {
        await linkWorkspace({ uuid: state.partnerConnection.uuid, workspaceId: Number(data?.createWorkspace?.id) });
        navigate(`/partner-connect/${state.partnerConnection.uuid}`, { slug: false });
      } else {
        await switchWorkspace(data?.createWorkspace?.id, `/${slug}`);
      }
    } catch (error: unknown) {
      setIsCreatingWorkspace(false);
      handleWorkspaceCreateError(error as Error);
    }
  };

  const handleWorkspaceCreateError = (error: Error) => {
    Sentry.captureException(error);

    if (error?.message.startsWith("SSO users are not allowed to create workspaces")) {
      setError(`${error.message}.`);
      return;
    }

    setError("There was a problem creating your workspace.");
  };

  // Watch name and autogenerate slugs.
  const name = watch("name");
  useEffect(() => {
    // Do not generate slug if the user has dirtied the field themselves.
    if (name && !formState.dirtyFields.slug) {
      const generatedSlug = generateSlug(name);
      setValue("slug", generatedSlug, { shouldDirty: false, shouldTouch: false, shouldValidate: false });
    }
  }, [name]);

  const slug = watch("slug");
  const [debouncedSlug, { isPending: isPendingSlugCheck }] = useDebounce(slug, 300);

  const { data: availableSlugResponse, isLoading: slugLoading } = useIsWorkspaceSlugAvailableQuery(
    { slug: debouncedSlug ?? "" },
    { enabled: !isCreatingWorkspace && Boolean(debouncedSlug) },
  );

  const slugAvailable = Boolean(availableSlugResponse?.isWorkspaceSlugAvailable);

  if (user && user.not_member_of_current_workspace) {
    return (
      <Card>
        <Text sx={{ fontSize: 4, fontWeight: "bold", mb: 4 }}>Can not create a workspace while impersonating</Text>
        <Button
          iconBefore={<ChevronLeftIcon color={"base.5"} size={18} />}
          sx={{ flexGrow: 1 }}
          variant="text-secondary"
          onClick={() => navigate(-1)}
        >
          Back
        </Button>
      </Card>
    );
  }

  return (
    <Fade
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100vw",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <Card sx={{ mx: "auto", width: "460px", maxWidth: "100%" }}>
        <Text sx={{ textAlign: "center", fontSize: 7, fontWeight: "bold", mb: 10 }}>New workspace</Text>

        <Field error={errors?.name?.message} label="Name">
          <Input autoFocus {...register("name")} placeholder="The ACME Company" />
        </Field>

        <Field error={errors?.slug?.message} label="" sx={{ mt: 6 }}>
          <Controller
            control={control}
            name="slug"
            render={({ field }) => (
              <Row sx={{ alignItems: "center", flexDirection: "row" }}>
                <Text color="base.8" sx={{ pr: "5px", borderRadius: "5px 0 0 5px" }}>
                  app.hightouch.com/
                </Text>
                <Slug
                  {...field}
                  available={slugAvailable}
                  loading={slugLoading || isPendingSlugCheck()}
                  placeholder={"acme-production"}
                />
              </Row>
            )}
          />
        </Field>

        {multiRegionEnabled && (
          <Field
            error={errors?.region?.message}
            help={
              <Text sx={{ fontSize: 1, color: "base.8" }}>
                <Text variant="p">
                  Your workspace will be hosted in this region. Choose the option that is best for your performance and
                  regulatory needs.
                </Text>
                <Text sx={{ pt: 2, fontWeight: "bold" }}>Note: your workspace region cannot be changed later.</Text>
              </Text>
            }
            label={"Region"}
            sx={{ mt: 9 }}
          >
            <Controller control={control} name={"region"} render={({ field }) => <SelectRegion {...field} />} />
          </Field>
        )}
        {multipleOrganization && (
          <Field error={errors?.organization?.message} label={"Organization"} sx={{ mt: 9 }}>
            <Controller
              control={control}
              name={"organization"}
              render={({ field }) => (
                <NewSelect
                  options={orgs}
                  {...field}
                  disabled={orgs.length === 1}
                  placeholder="Select an organization..."
                  onChange={(value) => field.onChange(value)}
                />
              )}
            />
          </Field>
        )}

        {error && (
          <Message
            sx={{
              width: "unset",
              maxWidth: "unset",
              bg: "base.1",
              borderRadius: 1,
              p: 4,
              border: `1px solid ${colors.base[2]}`,
              mt: 10,
              background: "reds.0",
            }}
            variant="error"
          >
            <Flex sx={{ alignItems: "center", justifyContent: "space-between", gap: 3 }}>
              <Column sx={{ ml: 2 }}>
                <Text as="p" color="base.6" mb={4} sx={{ fontSize: "13px" }}>
                  {error}
                </Text>
                <Text as="p" color="base.6" sx={{ fontSize: "13px" }}>
                  <Link onClick={contact}>Click here</Link>
                  {"  "}
                  to chat with us.
                </Text>
              </Column>
            </Flex>
          </Message>
        )}

        <Button
          disabled={slugLoading || isPendingSlugCheck() || !slugAvailable}
          loading={isCreatingWorkspace}
          size="large"
          sx={{ mt: 12, mb: 4 }}
          onClick={handleSubmit(submit)}
        >
          Create workspace
        </Button>

        <Button size="large" variant="white" onClick={() => navigate(-1)}>
          Cancel
        </Button>
      </Card>
    </Fade>
  );
};
