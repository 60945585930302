import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SyncIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <g opacity={0.4}>
        <path d="M9.7,15.55H1.81c-1,0-1.81,.8-1.81,1.78s.81,1.78,1.81,1.78h7.89c1,0,1.81-.8,1.81-1.78s-.81-1.78-1.81-1.78Z" />
        <path d="M24,4.05c0-.98-.81-1.78-1.81-1.78h-7.89c-1,0-1.81,.8-1.81,1.78s.81,1.78,1.81,1.78h7.89c1,0,1.81-.8,1.81-1.78Z" />
      </g>
      <g>
        <path d="M8.25,4.05c0,2.24-1.85,4.06-4.13,4.06S0,6.29,0,4.05,1.85,0,4.13,0s4.13,1.82,4.13,4.05Z" />
        <path d="M24,17.28c0,2.24-1.85,4.05-4.13,4.05s-4.13-1.82-4.13-4.05,1.85-4.06,4.13-4.06,4.13,1.82,4.13,4.06Z" />
      </g>
    </Icon>
  );
};
