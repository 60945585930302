import { useEffect, useState } from "react";

import { useToasts } from "react-toast-notifications2";
import { Image } from "theme-ui";

import { DestinationSelect, Destination } from "src/components/destinations/destination-select";
import { DestinationForm } from "src/components/destinations/sync-form";
import { ModelSelect } from "src/components/models/model-select";
import { ScheduleManager } from "src/components/schedule/schedule-manager";
import { ScheduleType } from "src/components/schedule/types";
import { DraftProvider } from "src/contexts/draft-context";
import { useUser } from "src/contexts/user-context";
import {
  useCreateSyncMutation,
  useModelQuery,
  DestinationDefinitionFragment as DestinationDefinition,
  ModelQuery,
  ResourceToPermission,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { Step } from "src/ui/wizard/wizard";
import { isScheduleComplete } from "src/utils/schedule";
import { ResourceType, useResourceSlug } from "src/utils/slug";
import { useSource } from "src/utils/sources";
import { useQueryString } from "src/utils/use-query-string";
import { useWizardStepper } from "src/utils/use-wizard-stepper";

type CreateSyncWizard = {
  initialStep?: number;
  model?: ModelQuery["segments_by_pk"];
  destination?: Destination;
  destinationDefinition?: DestinationDefinition;
  onSubmit?: ({ id }: { id: string }) => void;
};

export const useCreateSyncWizard = ({
  initialStep = 0,
  model: onboardingModel,
  destination: onboardingDestination,
  destinationDefinition: onboardingDestinationDefinition,
  onSubmit,
}: CreateSyncWizard) => {
  const { data: params, loading: loadingParams } = useQueryString();
  const { addToast } = useToasts();
  const { user, workspace } = useUser();
  const [destination, setDestination] = useState<Destination | undefined>(onboardingDestination);
  const [destinationDefinition, setDestinationDefinition] = useState<DestinationDefinition | undefined>(
    onboardingDestinationDefinition,
  );
  const [selectedModelId, setSelectedModelId] = useState<string>(onboardingModel?.id);
  const [schedule, setSchedule] = useState<any>({ type: ScheduleType.MANUAL });
  const [config, setConfig] = useState<any>();
  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(ResourceType.Sync);
  const [step, setStep] = useWizardStepper(initialStep);

  const { data: model } = useModelQuery(
    {
      id: selectedModelId,
    },
    { enabled: Boolean(selectedModelId), select: (data) => data.segments_by_pk },
  );
  const { mutateAsync: createSync } = useCreateSyncMutation();
  const { data: source, loading: loadingSource } = useSource(model?.connection?.id);

  const create = async () => {
    const slug = await getSlug(name);

    const data = await createSync({
      object: {
        slug,
        destination_id: destination!.id,
        segment_id: model?.id,
        config: { ...config, configVersion: destinationDefinition?.configVersion },
        schedule: schedule?.type === "manual" ? null : schedule,
        created_by: user?.id != null ? String(user?.id) : undefined,
        draft: workspace?.approvals_required,
      },
    });

    const id = data?.insert_destination_instances_one?.id;

    analytics.track("Sync Created", {
      destination_id: destination?.id,
      destination_name: destination?.name,
      destination_type: destinationDefinition?.name,
      model_id: model?.id,
      model_name: model?.name,
      schedule_type: schedule?.type,
    });

    // If we need to create a draft, we don't want to open the toast here.
    if (!workspace?.approvals_required) {
      addToast(`Sync created!`, {
        appearance: "success",
      });
    }

    if (typeof onSubmit === "function" && id) {
      onSubmit({ id });
    }

    return { id };
  };

  useEffect(() => {
    if (params.model) {
      setSelectedModelId(params?.model);
    }
  }, [params]);

  useEffect(() => {
    if (step === 1) {
      analytics.track("Add Sync Model Selected", {
        model_name: model?.name,
        source_type: model?.connection?.type,
        query_type: model?.query_type,
      });
    }
    if (step === 2) {
      analytics.track("Add Sync Destination Selected", {
        model_name: model?.name,
        source_type: model?.connection?.type,
        query_type: model?.query_type,
        destination_type: destinationDefinition?.name,
      });
    }
  }, [step]);

  useEffect(() => {
    if (model?.name && destination?.name) {
      setName(`${model?.name} to ${destination?.name}`);
    }
  }, [model?.name, destination?.name]);

  const selectModelStep = {
    title: "Select model",
    continue: "Click on a model to continue",
    header: <Heading>Select a model</Heading>,
    render: () => (
      <DraftProvider
        initialResourceIsDraft={model?.draft || false}
        resourceId={model?.id}
        resourceType={ResourceToPermission.Sync}
      >
        <ModelSelect
          onSelect={(selection) => {
            setSelectedModelId(selection.id);
            setStep((step) => step + 1);
          }}
        />
      </DraftProvider>
    ),
  };

  const steps: Step[] = [
    {
      title: "Select destination",
      continue: "Click on a destination to continue",
      header: <Heading>Select a destination</Heading>,
      render: () => (
        <DestinationSelect
          allowedDestinations={source?.definition?.allowedDestinations}
          loading={loadingSource}
          onSelect={({ destination, definition }) => {
            setDestination(destination);
            setDestinationDefinition(definition);
            setStep((step) => step + 1);
          }}
        />
      ),
    },
    {
      title: "Configure sync",
      continueProps: { form: "destination-form", type: "submit" },
      onContinue: () => {}, // required to defer continue to DestinationForm onSubmit
      header: (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <Image
            alt={destinationDefinition?.name}
            src={destinationDefinition?.icon}
            sx={{ width: "32px", objectFit: "contain" }}
          />
          <Heading>Configure sync to {destination?.name || destinationDefinition?.name}</Heading>
        </Row>
      ),
      render: () => {
        if (destination && destinationDefinition && model && source?.definition) {
          return (
            <DestinationForm
              hideSave
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={model}
              slug={destination.type}
              sourceDefinition={source.definition}
              onSubmit={(config) => {
                setConfig(config);
                setStep((step) => step + 1);
                return Promise.resolve();
              }}
            />
          );
        }
        return null;
      },
    },
    {
      title: "Schedule sync",
      disabled: !isScheduleComplete(schedule),
      header: <Heading>Set a schedule for this sync</Heading>,
      render: () => (
        <Column sx={{ gap: 8, maxWidth: "600px" }}>
          <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
        </Column>
      ),
    },
  ];

  return {
    createSync: create,
    setStep,
    step,
    steps: params?.model ? steps : [selectModelStep, ...steps],
    loading: loadingParams,
  };
};
