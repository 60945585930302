import { FC, useState, useEffect } from "react";

import {
  Alert,
  Box,
  FormField,
  Heading,
  Link,
  Paragraph,
  Radio,
  RadioGroup,
  Spinner,
  Button,
  Select,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Grid } from "theme-ui";
import { string, object, number } from "yup";

import { Help } from "src/components/help";
import { Settings } from "src/components/settings";
import { useUser } from "src/contexts/user-context";
import {
  useExternalBucketsQuery,
  useCreateExternalBucketMutation,
  useUpdateExternalBucketMutation,
  useTestStorageQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import { Container } from "src/ui/box";

import { SelectCredential } from "../../components/credentials";
import { Code } from "../../ui/code";
import AWS_REGION_OPTIONS from "../../utils/aws-region-options";

enum StorageType {
  "S3" = "s3",
  "GCP" = "gcp",
}

export const Storage: FC = () => {
  const client = useQueryClient();
  const { data: bucketData, isLoading } = useExternalBucketsQuery();
  const { data: entitlementsData } = useEntitlements(false);
  const externalStorageEnabled = entitlementsData.entitlements.externalStorage;

  const bucket = bucketData?.external_buckets?.[0];
  const config = bucket?.config;
  const credential = bucket?.cloud_credential;
  const [type, setType] = useState<StorageType>(StorageType.S3);

  // Feature flag external bucket storage if the user does not already have it set up.
  const testConfig = async ({ type, config, credentialId }) => {
    const variables = { type, config: JSON.stringify(config), credentialId };

    try {
      const { testStorageConnection } = await client.fetchQuery(useTestStorageQuery.getKey(variables), {
        queryFn: useTestStorageQuery.fetcher(variables),
      });
      return { success: Boolean(testStorageConnection), error: "" };
    } catch (error) {
      return { success: false, error: error.message };
    }
  };

  useEffect(() => {
    if (bucket) {
      setType(bucket?.type as StorageType);
    }
  }, [bucket]);

  const openIntercom = (message: string) => {
    const intercom = window["Intercom"];

    if (typeof intercom === "function") {
      intercom("showNewMessage", message);
    }
  };

  if (isLoading) {
    return (
      <Settings route="storage">
        <Container size="medium">
          <Box alignItems="center" display="flex" height="100%" justifyContent="center">
            <Spinner />
          </Box>
        </Container>
      </Settings>
    );
  }

  if (!externalStorageEnabled && !bucket) {
    return (
      <Settings route="storage">
        <Alert
          actionText="Upgrade plan"
          message={
            <>
              Upgrade to Business Tier to access this feature. By default, your sync history is stored in a secure, encrypted
              bucket hosted by Hightouch. Business Tier customers can choose to store their sync history in an S3 or GCS bucket
              they control. By electing to manage your own bucket, you can exercise full control over access, object lifecycle,
              and expiration settings. This is recommended for enterprises with elevated compliance needs. Read our{" "}
              <Link href="https://hightouch.com/docs/workspace-management/rbac">docs</Link> to learn more.
            </>
          }
          title="Custom external storage is not available in your workspace."
          variant="warning"
          onAction={() => openIntercom("Hi, I'm interested in trying out the Business-tier external storage feature.")}
        />
      </Settings>
    );
  }

  return (
    <Settings route="storage">
      <Container center={false} size="medium" sx={{ mx: "auto" }}>
        {config && (
          <Alert
            actionText="Contact us"
            message="If you need to change your storage location, please reach out to our customer success team."
            title="Storage location changes"
            variant="info"
            onAction={() => openIntercom("Hi, I'd like to change my storage location.")}
          />
        )}

        <Heading mt={config ? 8 : 0}>Storage</Heading>
        <Paragraph mt={1}>Store sync logs in a bucket you control.</Paragraph>

        <Grid gap={6} mt={6}>
          <FormField label="Cloud provider">
            <RadioGroup
              isDisabled={Boolean(config)}
              orientation="vertical"
              value={type}
              onChange={(newType) => setType(newType as StorageType)}
            >
              <Radio label="Amazon S3" value={StorageType.S3} />
              <Radio label="Google Cloud Storage" value={StorageType.GCP} />
            </RadioGroup>
          </FormField>

          {type === "s3" ? (
            <S3Form config={config} credential={credential} />
          ) : (
            <GCPForm config={config} credential={credential} testConfig={testConfig} />
          )}

          <Help docs={`${import.meta.env.VITE_DOCS_URL}/security/storage`} label="External cloud storage" />
        </Grid>
      </Container>
    </Settings>
  );
};

const S3Form: FC<Readonly<{ config: any; credential: { id: number; stripped_config?: any } | null | undefined }>> = ({
  config,
  credential,
}) => {
  const { toast } = useToast();
  const { workspace } = useUser();

  const validationSchema = object().shape({
    bucket: string().required("A bucket is required"),
    region: string().required("A region is required"),
    credentialId: number().required("A credential ID is required"),
  });

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: { ...config, credentialId: credential?.id },
  });

  const { isLoading: creating, mutateAsync: createExternalBucket } = useCreateExternalBucketMutation();
  const { isLoading: updating, mutateAsync: updateExternalBucket } = useUpdateExternalBucketMutation();

  const loading = creating || updating;

  const submit = async ({ bucket, region, credentialId }) => {
    const newConfig = {
      bucket,
      region,
    };

    if (config) {
      await updateExternalBucket({
        workspaceId: workspace?.id,
        append: {
          config: newConfig,
        },
        set: {
          type: StorageType.S3,
          credential_id: credentialId,
        },
      });
    } else {
      await createExternalBucket({
        object: {
          type: StorageType.S3,
          credential_id: credentialId,
          config: newConfig,
        },
      });
    }

    toast({
      id: "s3-form",
      title: "Amazon S3 configuration saved",
      variant: "success",
    });
  };

  return (
    <>
      <Controller
        control={control}
        name="region"
        render={({ field }) => {
          return (
            <FormField error={errors.region ? String(errors.region.message) : undefined} label="Region">
              <Select
                isDisabled={!!config}
                isInvalid={Boolean(errors.region)}
                optionLabel={(option) => option.label}
                optionValue={(option) => option.value}
                options={AWS_REGION_OPTIONS}
                placeholder="Select region..."
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="bucket"
        render={({ field }) => {
          return (
            <FormField error={errors.bucket ? String(errors.bucket.message) : undefined} label="Bucket name">
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(errors.bucket)}
                placeholder="my-bucket"
                value={field.value ?? ""}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="credentialId"
        render={({ field }) => {
          return (
            <FormField error={errors.credentialId ? String(errors.credentialId.message) : undefined} label="AWS credentials">
              <SelectCredential
                useHightouchUi
                isInvalid={Boolean(errors.credentialId)}
                provider="aws"
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Box>
        <Button isLoading={loading} variant="primary" onClick={handleSubmit(submit)}>
          Save
        </Button>
      </Box>
    </>
  );
};

const GCPForm: FC<
  Readonly<{
    config: any;
    credential: { id: number; stripped_config?: any } | null | undefined;
    testConfig: ({ type, config, credentialId }) => Promise<{ success: boolean; error: string }>;
  }>
> = ({ config, credential, testConfig }) => {
  const { toast } = useToast();
  const { workspace } = useUser();
  const [saving, setSaving] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const validationSchema = object().shape({
    bucketName: string().required("A bucket is required"),
    projectId: string().required("A project ID is required"),
    credentialId: number().required("A credential ID is required"),
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: config
      ? {
          ...config,
          credentialId: credential?.id,
        }
      : undefined,
  });

  const { mutateAsync: createExternalBucket } = useCreateExternalBucketMutation();
  const { mutateAsync: updateExternalBucket } = useUpdateExternalBucketMutation();

  const submit = async ({ bucketName, projectId, credentialId }) => {
    setSaving(true);
    setError("");

    const newConfig = { bucketName, projectId };

    const { success, error } = await testConfig({
      type: StorageType.GCP,
      config: newConfig,
      credentialId,
    });

    if (success) {
      if (config) {
        await updateExternalBucket({
          workspaceId: workspace?.id,
          append: {
            config: newConfig,
          },
          set: {
            type: StorageType.GCP,
            credential_id: credentialId,
          },
        });
      } else {
        await createExternalBucket({
          object: {
            type: StorageType.GCP,
            config: newConfig,
            credential_id: credentialId,
          },
        });
      }

      toast({
        id: "gcp-form",
        title: "Google Cloud Storage configuration saved",
        variant: "success",
      });
    } else {
      setError(error);
    }

    setSaving(false);
  };

  return (
    <>
      <Controller
        control={control}
        name="projectId"
        render={({ field }) => {
          return (
            <FormField error={errors.projectId ? String(errors.projectId.message) : undefined} label="Project ID">
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(errors.projectId)}
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="bucketName"
        render={({ field }) => {
          return (
            <FormField error={errors.bucketName ? String(errors.bucketName.message) : undefined} label="Bucket name">
              <TextInput
                isDisabled={Boolean(config)}
                isInvalid={Boolean(errors.bucketName)}
                value={field.value}
                onChange={field.onChange}
              />
            </FormField>
          );
        }}
      />

      <Controller
        control={control}
        name="credentialId"
        render={({ field }) => (
          <FormField
            error={errors.credentialId ? String(errors.credentialId.message) : undefined}
            label="Google Cloud credentials"
          >
            <SelectCredential
              useHightouchUi
              isInvalid={Boolean(errors.credentialId)}
              provider="gcp"
              value={field.value}
              onChange={field.onChange}
            />
          </FormField>
        )}
      />

      {config && credential && credential.stripped_config && (
        <FormField
          description="Run these two commands in your Google Cloud Shell to grant the service account access to Google Cloud Storage."
          label="Authentication commands"
        >
          <Code title="Grant read access">
            <div>gcloud projects add-iam-policy-binding {config?.projectId} \ </div>
            <div>--member serviceAccount:{credential.stripped_config.client_email} \ </div>
            <div>--role roles/storage.objectViewer</div>
          </Code>
          <br />

          <Code title="Grant write access">
            <div>gcloud projects add-iam-policy-binding {config?.projectId} \ </div>
            <div>--member serviceAccount:{credential.stripped_config.client_email} \ </div>
            <div>--role roles/storage.objectCreator</div>
          </Code>
        </FormField>
      )}

      {error && (
        <Box maxWidth="100%" width="md">
          <Alert
            isDismissible
            message={error}
            title="Failed to save configuration"
            variant="error"
            onDismiss={() => setError("")}
          />
        </Box>
      )}

      <Box>
        <Button isLoading={saving} variant="primary" onClick={handleSubmit(submit)}>
          Save
        </Button>
      </Box>
    </>
  );
};
