import { useState, FC, useMemo, useEffect } from "react";

import { CheckIcon } from "@heroicons/react/24/outline";
import { Box, Button, FormField, TextInput, Select, Paragraph, Text, ButtonGroup } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useLocation } from "react-router-dom";

import { useUser } from "src/contexts/user-context";
import { InitialQuery, useSendInviteMutation } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { roleDisabled } from "src/pages/settings/roles";
import { Modal } from "src/ui/modal";

interface InviteFormProps {
  name: string;
  close: () => void;
  open: boolean;
}

type Role = InitialQuery["workspaces"][number]["roles"][number];

export const InviteFormModal: FC<Readonly<InviteFormProps>> = ({ name, close, open }) => {
  const { workspace } = useUser();
  const location = useLocation();
  const [email, setEmail] = useState<string>("");
  const [invited, setInvited] = useState<boolean>(false);
  const { isLoading: inviting, mutateAsync: sendInvite } = useSendInviteMutation();

  const { authDisableNonSso, appAllowRoles } = useFlags();

  const roles = useMemo<Role[]>(() => {
    return [...(workspace?.roles ?? [])].sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1));
  }, [workspace?.roles]);

  const [role, setRole] = useState(workspace?.default_role_id);

  useEffect(() => {
    setRole(workspace?.default_role_id);
  }, [workspace?.default_role_id]);

  const handleClose = () => {
    close();
    setInvited(false);
    setEmail("");
  };

  if (authDisableNonSso) {
    return (
      <Modal
        info
        isOpen={open}
        sx={{ maxWidth: "441px", width: "100%" }}
        title={`Invite a teammate to ${name}`}
        onClose={handleClose}
      >
        <Paragraph>
          Inviting teammates by email is disabled in this workspace. Please use the SSO identity provider connected to your
          organization.
        </Paragraph>
      </Modal>
    );
  }

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        invited ? (
          <ButtonGroup>
            <Button
              onClick={() => {
                setInvited(false);
                setEmail("");
              }}
            >
              Invite more people
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Done
            </Button>
          </ButtonGroup>
        ) : (
          <ButtonGroup>
            <Button onClick={handleClose}>Cancel</Button>
            <Button
              isDisabled={!email || !workspace}
              isLoading={inviting}
              variant="primary"
              onClick={async () => {
                if (!email || !role) {
                  return;
                }
                await sendInvite({ recipientEmail: email, recipientRoleID: role });
                setInvited(true);
                analytics.track("Teammate Invited", {
                  workspace_id: workspace?.id,
                  workspace_slug: workspace?.slug,
                  origin_page: location.pathname,
                });
              }}
            >
              Send invitation
            </Button>
          </ButtonGroup>
        )
      }
      isOpen={open}
      sx={{ maxWidth: "441px", width: "100%" }}
      title={`Invite a teammate to ${name}`}
      onClose={handleClose}
    >
      {invited ? (
        <Box>
          <Box
            alignItems="center"
            bg="green.200"
            borderRadius="full"
            boxSize={14}
            display="flex"
            justifyContent="center"
            mx="auto"
          >
            <Box as={CheckIcon} boxSize={8} color="green.700" />
          </Box>

          <Box maxWidth="sm" mt={2} mx="auto">
            <Paragraph textAlign="center">
              You invited <Text fontWeight="semibold">{email}</Text> to <Text fontWeight="semibold">{name}</Text>.
            </Paragraph>
          </Box>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={6}>
          <FormField label="Email">
            <TextInput placeholder="jane@acme.com" value={email} onChange={(event) => setEmail(event.target.value)} />
          </FormField>

          <FormField
            description="Depending on your role selection, new users may be able to create workspaces and invite other team members."
            label="Role"
          >
            <Select
              isOptionDisabled={(role) => roleDisabled(appAllowRoles, workspace, role)}
              optionLabel={(role) => role.name}
              optionValue={(role) => role.id}
              options={roles}
              placeholder="Select role for this user..."
              value={role}
              width="3xs"
              onChange={setRole}
            />
          </FormField>
        </Box>
      )}
    </Modal>
  );
};
