import { useCallback, useEffect, useState } from "react";

import { Action, useKBar, useRegisterActions } from "kbar2";
import { useFlags } from "launchdarkly-react-client-sdk";
import { debounce } from "lodash";
import { useQuery } from "react-query";

import { useNavigate } from "src/utils/navigate";

import { useUser } from "../../contexts/user-context";
import { SearchWorkspacesQuery, SearchWorkspacesQueryVariables, useSearchWorkspacesQuery } from "../../graphql";
import {
  AudienceIcon,
  DestinationIcon,
  DocsIcon,
  ModelIcon,
  SettingSolidIcon,
  SourceIcon,
  SyncIcon,
  UsersIcon,
} from "../../ui/icons";
import { fetcher } from "../../utils/fetcher";
import { switchWorkspace } from "../../utils/workspaces";
import { useCommandBar } from "../commandbar/context";

const searchWorkspaceLimit = 50;

/**
 * Add dynamic Workspace options to the CommandBar
 */
function useSwitchWorkspaceActions() {
  const { user } = useUser();
  const { setIsFetching } = useCommandBar();

  // Register root action.
  useRegisterActions(
    user
      ? [
          {
            id: "workspace.switch",
            section: {
              name: "User",
              priority: 5,
            },
            name: "Switch workspace",
            keywords: "workspace",
            icon: UsersIcon as any,
            shortcut: ["s", "w"],
          },
        ]
      : [],
    [user],
  );

  // Subscribe to search query.
  const { search, currentRootActionId } = useKBar((state) => ({
    search: state.searchQuery,
    currentRootActionId: state.currentRootActionId,
  }));

  // Only assign the search state to our query variable if we're in the Switch Workspace action.
  const [searchTerm, _setSearchTerm] = useState("%%");
  const setSearchTerm = useCallback(
    debounce((term) => _setSearchTerm(`%${term}%`), 350, { leading: true }),
    [],
  );
  if (currentRootActionId === "workspace.switch") {
    setSearchTerm(search);
  }

  // Search for workspaces matching query.
  const { data: workspaceData, isFetching } = useQuery<SearchWorkspacesQuery>(
    useSearchWorkspacesQuery.getKey({ query: searchTerm, limit: searchWorkspaceLimit }),
    fetcher<SearchWorkspacesQuery, SearchWorkspacesQueryVariables>(
      useSearchWorkspacesQuery.document,
      { query: searchTerm, limit: searchWorkspaceLimit },
      {
        enabled: Boolean(user && currentRootActionId === "workspace.switch"),
        isAdmin: user?.is_admin,
      },
    ),
  );
  useEffect(() => setIsFetching(isFetching), [isFetching]);

  // Dynamically register workspace options.
  useRegisterActions(
    user
      ? (workspaceData?.workspaces || []).map((w) => {
          return {
            id: `workspace.switch.${w.id}`,
            section: "Workspace",
            parent: "workspace.switch",
            name: `${w.name}`,
            subtitle: `${w.slug}`,
            keywords: [w.id, w.name, w.slug].join(" "),
            perform: () => switchWorkspace(w.id),
          };
        })
      : [],
    [user, searchTerm, workspaceData],
  );
}

function useDocsActions() {
  useRegisterActions(
    [
      {
        id: "docs",
        section: "Docs",
        name: "Docs",
        keywords: "docs documentation",
        icon: DocsIcon as any,
        perform: () => (window.location.href = import.meta.env.VITE_DOCS_URL as string),
      },
    ],
    [],
  );
}

export function useWorkspaceActions() {
  // Mount routes to CommandBar
  const actions: Action[] = [];

  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const { audiencesEnabled } = useFlags();

  useDocsActions();
  useSwitchWorkspaceActions();

  if (user) {
    if (workspace) {
      // Nav actions.
      actions.push(
        ...[
          /* Sync Actions */
          {
            id: "syncs",
            section: {
              name: "Workspace",
              priority: 10,
            },
            name: "Syncs",
            keywords: "syncs",
            icon: SyncIcon as any,
            perform: () => navigate("/syncs"),
          },
          {
            parent: "syncs",
            section: "Workspace",
            id: "syncs.new",
            name: "New sync",
            keywords: "syncs new add create",
            perform: () => navigate("/syncs/new"),
          },

          /* Model Actions */
          {
            id: "models",
            section: "Workspace",
            name: "Models",
            keywords: "models",
            icon: ModelIcon as any,
            perform: () => navigate("/models"),
          },
          {
            parent: "models",
            section: "Workspace",
            id: "models.new",
            name: "New model",
            keywords: "models new add create",
            perform: () => navigate("/models/new"),
          },

          ...(audiencesEnabled
            ? [
                {
                  id: "audiences",
                  section: "Workspace",
                  name: "Audiences",
                  keywords: "audiences",
                  icon: AudienceIcon as any,
                  perform: () => navigate("/audiences"),
                },
                {
                  id: "audiences.new",
                  parent: "audiences",
                  section: "Workspace",
                  name: "New audience",
                  keywords: "audiences new add create",
                  perform: () => navigate("/audiences/new"),
                },
              ]
            : []),

          /* Source Actions */
          {
            id: "sources",
            section: "Workspace",
            name: "Sources",
            keywords: "sources",
            icon: SourceIcon as any,
            perform: () => navigate("/sources"),
          },
          {
            parent: "sources",
            section: "Workspace",
            id: "sources.new",
            name: "New source",
            keywords: "sources new add create",
            perform: () => navigate("/sources/new"),
          },

          /* Destination Actions */
          {
            id: "destinations",
            section: "Workspace",
            name: "Destinations",
            keywords: "destinations",
            icon: DestinationIcon as any,
            perform: () => navigate("/destinations"),
          },
          {
            section: "Workspace",
            parent: "destinations",
            id: "destinations.new",
            name: "New destination",
            keywords: "destinations new add create",
            perform: () => navigate("/destinations/new"),
          },

          /* Settings */
          {
            id: "settings",
            section: "Workspace",
            name: "Settings",
            keywords: "settings",
            icon: SettingSolidIcon as any,
            perform: () => navigate("/settings"),
          },
          ...[
            { id: "workspace", name: "Workspace", page: "/workspace" },
            { id: "members", name: "Members", page: "/members" },
            { id: "integrations", name: "Integrations", page: "/integrations" },
            { id: "alerts", name: "Alerts", page: "/alerts" },
            { id: "api-keys", name: "API Keys", page: "/api-keys" },
            { id: "storage", name: "Storage", page: "/storage" },
            { id: "tunnels", name: "Tunnels", page: "/tunnels" },
            { id: "git-sync", name: "Git sync", page: "/git-sync" },
          ].map(({ id, name, page }) => ({
            parent: "settings",
            id: `settings.${id}`,
            section: "Workspace",
            name: name,
            keywords: `settings ${id}`,
            perform: () => navigate(`/settings${page}`),
          })),
        ].filter((a) => !!a),
      );
    }
  }
  useRegisterActions(actions, [user, workspace, actions, audiencesEnabled]);
}
