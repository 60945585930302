import { FC } from "react";

import { get } from "lodash";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import {
  DEFAULT_DELETE_MODE_DESCRIPTION,
  DEFAULT_DELETE_MODE_LABELS,
  DELETE_MODE_DESCRIPTIONS,
  DELETE_MODE_LABELS,
} from "src/utils/destinations";

type DeleteFieldProps = {
  modes: string[];
};

type OptionField = {
  label: string;
  value: string | undefined;
  description?: string;
};

export const DeleteField: FC<Readonly<DeleteFieldProps>> = ({ modes }) => {
  const { slug, config, setConfig } = useDestinationForm();

  const options: OptionField[] = [
    {
      label: "Do nothing",
      value: undefined,
    },
  ];

  if (modes.includes("delete")) {
    options.push({
      label: get(DELETE_MODE_LABELS[slug ?? ""], "delete") || DEFAULT_DELETE_MODE_LABELS["delete"],
      value: "delete",
      description: get(DELETE_MODE_DESCRIPTIONS[slug ?? ""], "delete") || DEFAULT_DELETE_MODE_DESCRIPTION["delete"],
    });
  }

  if (modes.includes("clear")) {
    options.push({
      label: get(DELETE_MODE_LABELS[slug ?? ""], "clear") || DEFAULT_DELETE_MODE_LABELS["clear"],
      value: "clear",
    });
  }
  if (modes.includes("archive")) {
    options.push({
      label: get(DELETE_MODE_LABELS[slug ?? ""], "archive") || DEFAULT_DELETE_MODE_LABELS["archive"],
      value: "archive",
    });
  }

  return (
    <Section>
      <Field
        description="Warning - deleting records or clearing fields may delete data that was not authored by this Hightouch sync."
        label="How would you like to handle records leaving the source?"
        size="large"
      >
        <RadioGroup
          options={options}
          value={config?.deleteMode}
          onChange={(deleteMode) => {
            setConfig({ ...config, deleteMode });
          }}
        />
      </Field>
    </Section>
  );
};
