import { FC, useState, useEffect } from "react";

import { useToasts } from "react-toast-notifications2";

import { AudienceExplore } from "src/components/audiences/audience-explore";
import { ParentModelSelect, ParentModel } from "src/components/models/parent-model-select";
import { FormErrorProvider } from "src/contexts/form-error-context";
import { useUser } from "src/contexts/user-context";
import { useCreateAudienceMutation } from "src/graphql";
import { Column } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { Input } from "src/ui/input";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { QueryType, useModelRun, useModelState, useQueryState } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { useSource } from "src/utils/sources";

export const CreateAudience: FC = () => {
  const { user } = useUser();
  const navigate = useNavigate();
  const { addToast } = useToasts();
  const [parentModel, setParentModel] = useState<ParentModel | undefined>();
  const [step, setStep] = useState<number>(0);

  const { data: source, loading: sourceLoading, error: sourceError } = useSource(parentModel?.connection?.id);

  const { queryState, initQueryState, setVisualQueryFilter } = useQueryState();
  const { modelState, setName } = useModelState();

  const { isLoading: creating, mutateAsync: createAudience } = useCreateAudienceMutation();

  const {
    runQuery,
    getSchema,
    cancelQuery,
    rows,
    numRowsWithoutLimit,
    columns,
    loading: queryLoading,
    error: queryError,
    transformedSql,
  } = useModelRun(QueryType.Visual, parentModel?.columns, {
    variables: { sourceId: source?.id, parentModelId: parentModel?.id, ...queryState },
  });

  const create = async () => {
    await createAudience({
      input: {
        query_type: QueryType.Visual,
        visual_query_parent_id: parentModel?.id,
        visual_query_filter: queryState?.visualQueryFilter,
        name: modelState?.name,
        primary_key: parentModel?.primary_key,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        destination_instances: { data: [] },
      },
    });

    addToast(`${modelState?.name} created!`, {
      appearance: "success",
    });

    navigate("/audiences");
  };

  const steps: Step[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: <Heading>Select a parent model</Heading>,
      render: () => (
        <ParentModelSelect
          onSelect={(parentModel) => {
            setParentModel(parentModel);
            setStep(1);
          }}
        />
      ),
    },
    {
      title: "Define audience",
      contain: true,
      render: () => (
        <FormErrorProvider>
          <AudienceExplore
            cancelQuery={cancelQuery}
            columns={columns}
            error={queryError || sourceError?.message}
            getSchema={getSchema}
            loading={queryLoading || sourceLoading}
            numRowsWithoutLimit={numRowsWithoutLimit}
            parentModel={parentModel}
            rows={rows}
            runQuery={runQuery}
            source={source}
            transformedSql={transformedSql}
            onVisualQueryFilterChange={setVisualQueryFilter}
            {...queryState}
          />
        </FormErrorProvider>
      ),
    },
    {
      title: "Finalize audience",
      disabled: !modelState?.name,
      submitting: creating,
      header: <Heading>Finalize settings for this audience</Heading>,
      render: () => (
        <Column sx={{ gap: 8, maxWidth: "600px" }}>
          <Field label="Audience name">
            <Input value={modelState?.name} onChange={(name) => setName(name)} />
          </Field>
        </Column>
      ),
    },
  ];

  useEffect(() => {
    initQueryState(null);
  }, []);

  return (
    <Wizard
      setStep={setStep}
      step={step}
      steps={steps}
      title="New audience"
      onCancel={() => {
        navigate("/audiences");
      }}
      onSubmit={create}
    />
  );
};
