import { FC, useState } from "react";

import { Box, ButtonGroup, Button, Heading, Link, Paragraph, Text } from "@hightouchio/ui";
import moment from "moment";

import { CreateCredential } from "src/components/credentials";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Settings } from "src/components/settings";
import { useDeleteCloudCredentialsMutation, useCloudCredentialsV2Query } from "src/graphql";
import { AWSIcon } from "src/ui/icons/logo-aws";
import { GCPIcon } from "src/ui/icons/logo-gcp";
import { Table } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

const columns = [
  {
    name: "Name",
    cell: ({ name, provider }) => {
      let Icon;

      switch (provider) {
        case "aws":
          Icon = AWSIcon;
          break;
        case "gcp":
          Icon = GCPIcon;
          break;
      }

      return (
        <Box alignItems="center" display="flex" gap={2}>
          <Icon position="relative" top="0.5" />
          <Text fontWeight="medium">{name}</Text>
        </Box>
      );
    },
  },
  {
    name: "Created at",
    cell: ({ created_at }) => moment(created_at).calendar(),
    max: "240px",
  },
];

export const CloudProviders: FC = () => {
  const [addingCredential, setAddingCredential] = useState<boolean>(false);
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();

  const { data } = useCloudCredentialsV2Query();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteCloudCredentialsMutation();

  return (
    <Settings route="cloud-providers">
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Heading>Cloud providers</Heading>
          <Paragraph mt={1}>
            Authorize access to resources in your AWS or GCP account. These credentials can be used to set up data sources like
            S3 and BigQuery, or to store Hightouch's sync logs in a bucket you control. Learn more in our{" "}
            <Link href="https://hightouch.com/docs/security/storage">docs</Link>.
          </Paragraph>
        </Box>

        <ButtonGroup>
          {selectedRows.length > 0 && (
            <Button variant="warning" onClick={() => setConfirmingDelete(true)}>
              Delete selected
            </Button>
          )}

          <Button variant="primary" onClick={() => setAddingCredential(true)}>
            Add cloud provider
          </Button>
        </ButtonGroup>
      </Box>

      <Box mt={6}>
        <Table
          columns={columns}
          data={data?.getCloudCredentials}
          placeholder={placeholder}
          selectedRows={selectedRows}
          onSelect={onRowSelect}
        />
      </Box>

      {addingCredential && <CreateCredential onClose={() => setAddingCredential(false)} />}

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="cloud provider"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          await bulkDelete({ ids: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />
    </Settings>
  );
};

const placeholder = {
  title: "No cloud credentials.",
  error: "Credentials failed to load, please try again.",
};
