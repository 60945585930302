import { FC, ReactNode } from "react";

import { Box, Image, Text, ThemeUIStyleObject } from "theme-ui";

import ErrorSrc from "./error.svg";
import PlaceholderSrc from "./placeholder.svg";

export type PlaceholderContent = {
  image?: string;
  title?: string;
  body?: string | ReactNode;
  button?: ReactNode;
  error?: string;
  custom?: ReactNode;
  customError?: ReactNode;
};

type PlaceholderProps = {
  error: boolean;
  content: PlaceholderContent | undefined;
  sx?: ThemeUIStyleObject;
};

export const Placeholder: FC<Readonly<PlaceholderProps>> = ({ error, content, sx = {} }) => {
  return (
    <Box
      sx={{
        borderRadius: 1,
        p: 8,
        bg: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        border: "small",
        borderStyle: "dashed",
        overflow: "hidden",
        ...sx,
      }}
    >
      {error ? (
        <>
          <Image src={ErrorSrc} sx={{ mb: 8 }} />
          {content?.customError || (
            <Text
              sx={{
                overflow: "auto",
                whiteSpace: "pre-line",
                p: 4,
                color: "red",
                ":first-letter": {
                  textTransform: "capitalize",
                },
              }}
            >
              {content?.error || defaultError}
            </Text>
          )}
        </>
      ) : content?.title ? (
        <>
          <Image src={content?.image ?? PlaceholderSrc} sx={{ mb: 4 }} />
          <Text sx={{ fontSize: 2, fontWeight: "semi", color: "base.7" }}>{content.title}</Text>
          {content?.body && <Text sx={{ mt: 2, color: "base.4" }}>{content.body}</Text>}
          {content?.button && <Box sx={{ mt: 3 }}>{content.button}</Box>}
        </>
      ) : (
        content?.custom
      )}
    </Box>
  );
};

const defaultError = "Results were unable to load, please try again.";
