import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const AddUserIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <g>
        <path d="M22.92,8.51h-1.44v-1.41c0-.6-.48-1.09-1.08-1.09s-1.08,.49-1.08,1.09v1.41h-1.44c-.6,0-1.08,.49-1.08,1.09s.48,1.09,1.08,1.09h1.44v1.41c0,.6,.48,1.09,1.08,1.09s1.08-.49,1.08-1.09v-1.41h1.44c.59,0,1.08-.49,1.08-1.09s-.48-1.09-1.08-1.09Z" />
        <path d="M9,15.02c-4.85,0-9,.78-9,3.88s4.12,3.9,9,3.9,9-.78,9-3.88-4.12-3.9-9-3.9Z" />
      </g>
      <path d="M9,12.07c3.31,0,5.96-2.68,5.96-6.03S12.31,0,9,0,3.04,2.69,3.04,6.03s2.65,6.03,5.96,6.03Z" opacity={0.4} />
    </Icon>
  );
};
