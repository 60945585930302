import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ModelIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M17.61,0H6.4C2.31,0,0,2.31,0,6.4v11.2c0,4.09,2.31,6.4,6.4,6.4h11.21c4.09,0,6.39-2.31,6.39-6.4V6.4c0-4.09-2.3-6.4-6.39-6.4Z"
        opacity={0.4}
      />
      <g>
        <path d="M6.44,8.84c-.54,0-.99,.45-.99,1v8.25c0,.54,.45,.99,.99,.99s1-.45,1-.99V9.85c0-.55-.45-1-1-1Z" />
        <path d="M12.04,4.91c-.54,0-.99,.45-.99,1v12.18c0,.54,.45,.99,.99,.99s1-.45,1-.99V5.91c0-.55-.45-1-1-1Z" />
        <path d="M17.57,13.19c-.55,0-1,.45-1,1v3.89c0,.54,.45,.99,.99,.99s1-.45,1-.99v-3.89c0-.55-.45-1-.99-1Z" />
      </g>
    </Icon>
  );
};
