import { useEffect, useMemo, FC } from "react";

import { Alert } from "@hightouchio/ui";
import { capitalize, upperCase } from "lodash";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications2";

import { AuthorizeConnection, ReauthorizeConnection } from "src/components/authorize-connection";
import { Form as FormkitForm } from "src/formkit/components/form";
import { Field, FieldError } from "src/ui/field";

import { FormkitDestination, FormkitProvider } from "../../formkit/components/formkit-context";
import { processFormNode } from "../../formkit/formkit";
import {
  DestinationDefinitionFragment,
  useFormkitDestinationDefinitionQuery,
  useFormkitDestinationValidationQuery,
} from "../../graphql";
import { RadioGroup } from "../../ui/radio";
import { SelectCredential } from "../credentials";
import { TunnelSelect } from "../tunnels/tunnel-select";

interface Props {
  config: Record<string, any> | undefined;
  credentialId: string | undefined;
  definition: DestinationDefinitionFragment;
  destination?: FormkitDestination | null;
  setConfig: (config: Record<string, any>) => void;
  setCredentialId: (credentialId: string) => void;
  isSetup: boolean;
  disableAuthMethod?: boolean;
  onSubmit?: (config: any) => Promise<void>;
  error?: any;
  onConnectClick?(defintion: DestinationDefinitionFragment): void;
}

export const SetupForm: FC<Props> = ({
  config,
  setConfig,
  credentialId,
  setCredentialId,
  definition,
  destination = null,
  ...props
}) => {
  const client = useQueryClient();
  const { addToast } = useToasts();
  const { data } = useFormkitDestinationDefinitionQuery({ type: definition?.type }, { enabled: true });
  //Legacy Destination Config is configuration that does NOT use new formkit format with multiple authentication methods
  const destinationDefinitions = data?.formkitDestinationDefinition;
  const isLegacyDestinationConfiguration = destinationDefinitions?.length == 1 && destinationDefinitions[0]?.key === null;

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (destinationDefinitions?.length === 0) {
      if (typeof props.onSubmit === "function") {
        return await props.onSubmit(data);
      }

      return;
    }

    const errors = await validate(config, context);
    if (typeof errors === "object" && Object.keys(errors).length) {
      methods.clearErrors();
      Object.entries(errors).forEach(([key, message]) => {
        methods.setError(key, { message: String(message) });
      });
      addToast("There was an error within the destination configuration.", {
        appearance: "error",
      });
    } else {
      if (typeof props.onSubmit === "function") {
        await props.onSubmit(data);
      }
    }
  };

  const validate = async (config, context) => {
    const response = await client.fetchQuery({
      queryFn: useFormkitDestinationValidationQuery.fetcher({
        type: definition.type,
        config,
        context,
      }),
      queryKey: useFormkitDestinationValidationQuery.getKey({ ...config, ...context }),
    });

    return response.formkitDestinationValidation;
  };

  const context = {
    destinationDefinition: definition,
    isSetup: props.isSetup,
    credentialId,
    destination: destination ?? undefined,
  };

  const methods = useForm();

  useEffect(() => {
    if (config && Object.keys(config).length) {
      methods.reset(config, { keepDefaultValues: true });
    }
  }, [typeof config]);

  useEffect(() => {
    //Autoset authentication method if only one.
    const firstDestinationDefinition = destinationDefinitions?.[0];
    if (!isLegacyDestinationConfiguration && destinationDefinitions?.length === 1 && firstDestinationDefinition) {
      setConfig((prev) => ({ ...prev, methodKey: firstDestinationDefinition.key }));
      methods.setValue("methodKey", firstDestinationDefinition.key);
    }
  }, [definition.type, destinationDefinitions?.length]);

  useEffect(() => {
    //Watches fields and sets config.
    const fieldWatcher = methods.watch((value) => {
      //Have to spread existing config as tunnel is with config, but not part of form.
      setConfig((o) => ({ ...o, ...value }));
    });
    return () => fieldWatcher.unsubscribe();
  }, [methods.watch]);

  const setTunnel = (tunnel) => {
    methods.setValue("tunnel", tunnel);
  };

  if (!destinationDefinitions) {
    return null;
  }

  return (
    <>
      <FormkitProvider {...context} validate={validate}>
        <FormProvider {...methods}>
          {destinationDefinitions.length > 1 && (
            <Field label="Authentication method">
              <Controller
                control={methods.control}
                name={`methodKey`}
                render={({ field }) => (
                  <RadioGroup
                    {...field}
                    disabled={props.disableAuthMethod}
                    options={destinationDefinitions.map((o) => ({ label: o.label || capitalize(o.key || ""), value: o.key }))}
                    value={config?.methodKey}
                  />
                )}
                rules={{ required: true }}
              />
            </Field>
          )}
          <SelectedMethodForm
            config={config || {}}
            credentialId={credentialId}
            definition={definition}
            destinationDefinitions={destinationDefinitions}
            id={destination?.id}
            isLegacyDestinationConfiguration={isLegacyDestinationConfiguration}
            isSetup={props.isSetup}
            setConfig={setConfig}
            setCredentialId={setCredentialId}
            setTunnel={setTunnel}
            onConnectClick={props.onConnectClick}
          />
          <FieldError error={props.error} />
          <form hidden id="destination-form" onSubmit={handleSubmit} />
        </FormProvider>
      </FormkitProvider>
    </>
  );
};

export type MethodProps = {
  definition: DestinationDefinitionFragment;
  config: Record<string, any>;
  setConfig?: (config: Record<string, any>) => void;
  destinationDefinitions: Record<string, any>;
  id?: string;
  onConnectClick?: (defintion: DestinationDefinitionFragment) => void;
  credentialId?: any;
  setCredentialId?: any;
  setTunnel: (config?: Record<string, any>) => void;
  isLegacyDestinationConfiguration: boolean;
  isSetup: boolean;
};

const SelectedMethodForm: FC<Readonly<MethodProps>> = ({
  definition,
  config,
  destinationDefinitions,
  id,
  onConnectClick,
  ...props
}) => {
  const matchingAuthMethod = props.isLegacyDestinationConfiguration
    ? destinationDefinitions[0]
    : destinationDefinitions?.find((o) => o.key === config?.methodKey);

  const Form = useMemo(
    () =>
      matchingAuthMethod?.form && (
        <FormkitForm compact={true} disableBorder={true}>
          {processFormNode(matchingAuthMethod.form)}
        </FormkitForm>
      ),
    [matchingAuthMethod?.key],
  );

  if (matchingAuthMethod?.method === "form") {
    return (
      <>
        {matchingAuthMethod?.tunneling && <TunnelSelect optional value={config.tunnel} onChange={props.setTunnel} />}
        {matchingAuthMethod?.provider && (
          <Field label={`${upperCase(matchingAuthMethod.provider)} Credentials`} sx={{ pb: 4 }}>
            <SelectCredential
              provider={matchingAuthMethod.provider}
              value={props.credentialId}
              onChange={(value) => props.setCredentialId(value)}
            />
          </Field>
        )}
        {Form}
      </>
    );
  } else if (matchingAuthMethod?.method === "oauth") {
    if (!id) {
      return (
        <AuthorizeConnection
          href={`${import.meta.env.VITE_API_BASE_URL}${matchingAuthMethod.url}/${definition.type}`}
          icon={matchingAuthMethod?.authIcon || definition.icon}
          name={matchingAuthMethod?.authLabel || definition.name}
          onAuthorize={() => {
            if (onConnectClick) {
              onConnectClick(definition);
            }
          }}
        />
      );
    } else {
      return (
        <>
          {props.isSetup && (
            <Alert
              message={`Your ${definition.name} account is now connected to Hightouch.`}
              title="Authorization successful."
              variant="success"
            />
          )}
          {matchingAuthMethod?.form && Form}
          {!props.isSetup && (
            <ReauthorizeConnection
              href={`${import.meta.env.VITE_API_BASE_URL}${matchingAuthMethod.url}/${definition.type}/${id}`}
              icon={matchingAuthMethod?.authIcon || definition.icon}
              name={matchingAuthMethod?.authLabel || definition.name}
              onAuthorize={() => {
                if (onConnectClick) {
                  onConnectClick(definition);
                }
              }}
            />
          )}
        </>
      );
    }
  }
  return null;
};
