import { useState } from "react";

import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { Close, Flex, Grid } from "theme-ui";
import validator from "validator";

import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { PlusIcon } from "src/ui/icons";
import { Input } from "src/ui/input";

import { SmsAlert, SmsAlertConfig } from "./types";

export const SmsForm = ({ config, setConfig }: { config: SmsAlertConfig; setConfig: (config: SmsAlertConfig) => void }) => {
  const [newSms, setNewSms] = useState<string | undefined | null>();

  const recipients = config?.recipients || [];

  const setRecipients = (recipients: string[]) => {
    setConfig({ ...config, recipients });
  };

  return (
    <>
      <Field label={"Recipients"}>
        {!!recipients?.length && (
          <Grid gap={2} sx={{ mb: 2 }}>
            {recipients?.map((r, idx) => (
              <Flex key={idx} sx={{ alignItems: "center" }}>
                <Input
                  readOnly
                  placeholder="Enter a phone number..."
                  sx={{ width: "360px", maxWidth: "360px" }}
                  value={r || ""}
                />
                <Close
                  sx={{ ml: 2 }}
                  onClick={() => {
                    const a = [...recipients];
                    a.splice(idx, 1);
                    setRecipients(a);
                  }}
                />
              </Flex>
            ))}
          </Grid>
        )}
        <Flex sx={{ alignItems: "center" }}>
          <Flex sx={{ ml: 1 }}>
            <PhoneInput
              defaultCountry="US"
              inputComponent={Input}
              placeholder="Enter a phone number..."
              sx={{ width: "300px", ml: 1 }}
              value={newSms || ""}
              onChange={(phone) => {
                setNewSms(phone);
              }}
            />
          </Flex>
          <Button
            disabled={!newSms || !validator.isMobilePhone(newSms, "any", { strictMode: true })}
            iconBefore={<PlusIcon size={14} />}
            sx={{ ml: 2 }}
            variant="secondary"
            onClick={() => {
              if (newSms) {
                setRecipients([...recipients, newSms]);
              }

              setNewSms(null);
            }}
          >
            Add
          </Button>
        </Flex>
      </Field>
    </>
  );
};

export const smsValidator = (alert: SmsAlert): boolean => {
  const config = alert?.config;
  return Boolean(config?.recipients?.length);
};
