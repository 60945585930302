import { FC, ReactNode } from "react";

import { Props as HeaderProps, PageHeader } from "./header/page-header";
import { Props as ContainerProps, PageContainer } from "./page-container";

export type Props = { children: ReactNode; header?: ReactNode } & HeaderProps & ContainerProps;

export const Page: FC<Readonly<Props>> = ({ children, crumbs, sync, header, outsideTopbar, ...containerProps }) => {
  return (
    <>
      <PageHeader crumbs={crumbs} outsideTopbar={outsideTopbar} sync={sync}>
        {header}
      </PageHeader>
      <PageContainer {...containerProps}>{children}</PageContainer>
    </>
  );
};
