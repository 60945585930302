import { useEffect, useState, useMemo } from "react";

import { uniqueId } from "lodash";
import { useFormContext } from "react-hook-form";
import { useQueryClient } from "react-query";
import { useToasts } from "react-toast-notifications2";
import { Flex, Grid, Text, Image } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import {
  SyncOp,
  TestSyncConfigurationResult,
  useTestSyncConfigurationQuery,
  useTestSyncConfigurationSupportedQuery,
} from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { Badge } from "src/ui/badge";
import { Button, DropdownButton } from "src/ui/button";
import { Editor } from "src/ui/editor";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { CheckIcon, PenIcon, XIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Modal } from "src/ui/modal";
import { Select } from "src/ui/select";
import { SimplePagination } from "src/ui/table";
import { Placeholder } from "src/ui/table/placeholder";
import { Tooltip } from "src/ui/tooltip";
import { processRequestInfo, RequestInfo } from "src/utils/syncs";

export const TestSync = ({ formkit }) => {
  const [testSyncOpen, setTestSyncOpen] = useState(false);
  const [supported, setSupported] = useState(false);
  const { config: _config, destination, model, setErrors, validate } = useDestinationForm();
  const { getValues, handleSubmit, setError } = useFormContext();

  const { addToast } = useToasts();

  const config = formkit ? getValues() : _config;

  const { data } = useTestSyncConfigurationSupportedQuery(
    {
      // These types are wrong in the GQL (they're numbers instead of strings)
      segmentId: Number(model?.id),
      destinationId: Number(destination?.id),
      syncConfig: config,
    },
    { enabled: Boolean(model && destination && config) },
  );

  useEffect(() => {
    if (data?.testSyncConfigurationSupported === true) {
      setSupported(true);
    } else if (data?.testSyncConfigurationSupported === false) {
      setSupported(false);
    }
  }, [data]);

  if (!supported) return null;

  return (
    <>
      <Button
        sx={{ width: "100%" }}
        variant="secondary"
        onClick={handleSubmit(async () => {
          const errors = await validate(config);
          if (errors && typeof errors === "object" && Object.entries(errors).length) {
            Object.entries(errors).forEach(([key, message]) => {
              setError(key, { message: String(message) });
            });
            addToast("Please complete the sync configuration before testing!", {
              appearance: "error",
            });
            setErrors(errors);
          } else {
            setTestSyncOpen(true);
            setErrors(null);
          }
        })}
      >
        Test
      </Button>
      {testSyncOpen && (
        <TestSyncModal
          formkit={formkit}
          onClose={() => {
            setTestSyncOpen(false);
          }}
        />
      )}
    </>
  );
};

const TestSyncModal = ({ formkit, onClose }) => {
  const client = useQueryClient();
  const [loading, setLoading] = useState<boolean>(false);
  const [result, setResult] = useState<TestSyncConfigurationResult | undefined>();

  const {
    reloadRows,
    loadingRows,
    model,
    destination,
    destinationDefinition,
    sourceDefinition,
    rows: allRows,
    config: _config,
  } = useDestinationForm();

  const { getValues } = useFormContext();

  const config = formkit ? getValues() : _config;

  // Only rows with a primary key value can be synced.
  const primaryKey = model?.primary_key ?? "";
  const rows = allRows?.filter((row) => row[primaryKey] != null);

  useEffect(() => {
    if (!rows?.length) {
      reloadRows();
    }
  }, []);

  useEffect(() => {
    if (rows?.length && !row) {
      setRow(rows[0]);
    }
  }, [rows]);

  const [row, setRow] = useState<{ [key: string]: any }[]>();
  const [viewError, setViewError] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(0);

  const rowOptions = rows?.map((row) => ({
    label: `${primaryKey}: ${row[primaryKey]}`,
    value: String(row[primaryKey]),
  }));

  const runOperation = async (operation: SyncOp) => {
    const segmentId = model?.id;
    const destinationId = destination?.id;

    if (!segmentId || !destinationId) {
      return;
    }

    setLoading(true);
    const variables = {
      segmentId: Number(segmentId),
      destinationId: Number(destinationId),
      syncConfig: config,
      rows: [row],
      operation,
    };
    const { testSyncConfiguration } = await client.fetchQuery(uniqueId(), {
      queryFn: useTestSyncConfigurationQuery.fetcher(variables),
    });
    setLoading(false);

    setResult(testSyncConfiguration);
  };

  const rejectedRow = result?.batches?.[0]?.rejectedRows?.[0];
  const error = result?.error;
  const requests = result?.batches?.[0]?.requestInfoSet;

  const requestInfoSet: RequestInfo[] = useMemo(() => {
    return (requests || [])
      .map((requestInfo) => processRequestInfo(requestInfo, destinationDefinition))
      .filter((requestInfo) => requestInfo.method !== "Contact.bulkload");
  }, [requests, destinationDefinition]);

  const requestInfo = requestInfoSet?.[selectedRequest];

  return (
    <Modal
      bodySx={{ p: 0 }}
      footer={
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
      }
      header={
        <Flex sx={{ flex: 1, alignItems: "center", justifyContent: "space-between" }}>
          <Heading variant="h2">Test a row</Heading>
          <Flex sx={{ alignItems: "center" }}>
            <Flex sx={{ alignItems: "center", mr: 8 }}>
              <Image src={sourceDefinition?.icon} sx={{ width: "24px", mr: 2, flexShrink: 0 }} />
              <Text
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontWeight: "semi",
                  fontSize: "1",
                }}
              >
                {model?.name}
              </Text>
              <Arrow />
              <Image src={destinationDefinition?.icon} sx={{ width: "24px", mr: 2, flexShrink: 0 }} />
              <Text
                sx={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  fontWeight: "semi",
                  fontSize: "1",
                }}
              >
                {destinationDefinition?.name}
              </Text>
            </Flex>
            <DropdownButton
              disabled={!row}
              loading={loading}
              options={[
                {
                  label: "Sync as changed row",
                  onClick: () => {
                    runOperation(SyncOp.Changed);
                  },
                  disabled: loading || !row,
                },
                {
                  label: "Sync as removed row",
                  onClick: () => {
                    runOperation(SyncOp.Removed);
                  },
                  disabled: loading || !row,
                },
              ]}
              onClick={() => {
                runOperation(SyncOp.Added);
              }}
            >
              Sync as added row
            </DropdownButton>
          </Flex>
        </Flex>
      }
      sx={{ maxWidth: "1200px", width: "90%", height: "90%" }}
      onClose={onClose}
    >
      <Flex sx={{ height: "100%", width: "100%" }}>
        <Flex sx={{ flexDirection: "column", bg: "white", flex: 1, borderRight: "small" }}>
          <Flex sx={{ borderBottom: "small", px: 6, py: 3, alignItems: "center", justifyContent: "space-between" }}>
            <Flex sx={{ alignItems: "center" }}>
              <Field inline label="Select a row to sync:" labelSx={{ width: "140px" }}>
                <Select
                  isLoading={loadingRows}
                  options={rowOptions}
                  placeholder="Select a row..."
                  reload={reloadRows}
                  value={rowOptions?.find((o) => o.value === String(row?.[primaryKey])) || null}
                  width="300px"
                  onChange={(selected) => setRow(rows?.find((row) => selected?.value === String(row?.[primaryKey])))}
                />
              </Field>
            </Flex>
          </Flex>
          <Flex sx={{ flexDirection: "column", flex: 1, overflow: "auto" }}>
            {row ? (
              <>
                <RowValue
                  key={primaryKey}
                  readOnly
                  property={primaryKey}
                  tooltip={"Hightouch casts the primary key to a string."}
                  value={String(valueToString(row[primaryKey]))}
                />
                {Object?.entries(row)
                  ?.filter(([k, _]) => k !== primaryKey)
                  ?.map(([k, v]) => (
                    <RowValue
                      key={k}
                      property={k}
                      setValue={(value) => {
                        setRow({ ...row, [k]: value });
                      }}
                      value={v}
                    />
                  ))}
              </>
            ) : (
              <Flex sx={{ mt: "auto", mb: "auto", alignSelf: "center", justifySelf: "center" }}>
                <Placeholder
                  content={{
                    title: "No row selected",
                    body: "Select a row to view details here",
                  }}
                  error={false}
                  sx={{ height: "unset" }}
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex sx={{ flexDirection: "column", flex: 1 }}>
          {result ? (
            <>
              {viewError ? (
                <Flex sx={{ flex: 1, p: 4 }}>
                  <Text as="pre" sx={{ wordBreak: "break-all", whiteSpace: "pre-wrap" }}>
                    {rejectedRow?.reason || error}
                  </Text>
                </Flex>
              ) : !requestInfo ? (
                <>
                  <Flex sx={{ mt: "auto", mb: "auto", alignSelf: "center", justifySelf: "center", px: 10, py: 4 }}>
                    <Placeholder
                      content={{
                        title: "No request sent for this row",
                        body: "An error may have occurred, or Hightouch did not send a request due to the sync mode (i.e. a changed row in an insert only destination) ",
                      }}
                      error={false}
                      sx={{ height: "unset", bg: null }}
                    />
                  </Flex>
                </>
              ) : (
                <>
                  <Flex sx={{ flex: 1, bg: "white", borderBottom: "small", flexDirection: "column" }}>
                    <Flex sx={{ p: 4, flexDirection: "column", borderBottom: "small" }}>
                      <Flex sx={{ alignItems: "center", justifyContent: "space-between" }}>
                        <Flex sx={{ alignItems: "center" }}>
                          <Text
                            sx={{
                              fontWeight: "semi",
                              mr: "2",
                            }}
                          >
                            Request
                          </Text>
                          {!!requestInfo?.meta?.invokedTimestamp && (
                            <Text sx={{ display: "inline", color: "base.4", fontWeight: "semi" }}>
                              ({requestInfo?.meta?.invokedTimestamp})
                            </Text>
                          )}
                        </Flex>
                        {requestInfoSet?.length > 1 && (
                          <SimplePagination
                            page={selectedRequest}
                            pages={requestInfoSet?.length}
                            onNext={() => {
                              setSelectedRequest((selectedRequest) => selectedRequest + 1);
                            }}
                            onPrevious={() => {
                              setSelectedRequest((selectedRequest) => selectedRequest - 1);
                            }}
                          />
                        )}
                      </Flex>
                      <Flex sx={{ mt: 3, alignItems: "center" }}>
                        <Flex sx={{ flex: "0 0 auto" }}>
                          <Badge sx={{ mr: 2 }} variant="base">
                            {requestInfo?.method}
                          </Badge>
                        </Flex>
                        <Text
                          sx={{ fontFamily: "monospace", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                        >
                          {requestInfo?.destination}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex sx={{ flex: 1 }}>
                      <Editor
                        code={requestInfo?.requestBody}
                        language={requestInfo?.requestIsJson ? "json" : requestInfo?.requestIsXml ? "xml" : "text"}
                        sx={{
                          height: "100%",
                          width: "100%",
                        }}
                      />
                    </Flex>
                  </Flex>
                  <Flex sx={{ flex: 1, bg: "white", flexDirection: "column", borderBottom: "small" }}>
                    <Flex sx={{ p: 4, borderBottom: "small", alignItems: "center" }}>
                      <Text
                        sx={{
                          fontWeight: "semi",
                          mr: "2",
                        }}
                      >
                        Response
                      </Text>
                      {requestInfo && (
                        <Badge sx={{ mr: 2 }} variant={requestInfo?.status.match(/E[Rr][Rr]/) ? "red" : "green"}>
                          {requestInfo?.status}
                        </Badge>
                      )}
                      {!!requestInfo?.meta?.finishedTimestamp && (
                        <Text sx={{ display: "inline", color: "base.4", fontWeight: "semi" }}>
                          ({requestInfo?.meta?.finishedTimestamp})
                        </Text>
                      )}
                    </Flex>
                    <Flex sx={{ flex: 1 }}>
                      {requestInfo?.responseBody ? (
                        <Editor
                          code={requestInfo?.responseBody}
                          language={requestInfo?.responseIsJson ? "json" : requestInfo?.responseIsXml ? "xml" : "text"}
                          sx={{
                            height: "100%",
                            width: "100%",
                          }}
                        />
                      ) : (
                        <Text sx={{ p: 5 }}>
                          No response
                          {destinationDefinition?.name ? ` from ${destinationDefinition.name}` : ""}
                        </Text>
                      )}
                    </Flex>
                  </Flex>
                </>
              )}
              <Flex
                sx={{
                  p: 4,
                  alignItems: "center",
                  bg: "white",
                }}
              >
                {!(rejectedRow || error) ? (
                  <>
                    <Badge variant="green">Success</Badge>
                    <Flex sx={{ flex: 1, px: 4 }}>
                      <Text sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        The sync to {destinationDefinition?.name} was successfully completed.
                      </Text>
                    </Flex>
                  </>
                ) : (
                  <>
                    <Flex sx={{ flex: "1", alignItems: "center", justifyContent: "space-between" }}>
                      <Flex sx={{ alignItems: "center" }}>
                        <Badge variant="red">Failed</Badge>
                        <Flex sx={{ flex: 1, px: 4 }}>
                          <Text sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                            {rejectedRow?.reason || error}
                          </Text>
                        </Flex>
                      </Flex>

                      {viewError ? (
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setViewError(false);
                          }}
                        >
                          Close Full Error
                        </Button>
                      ) : (
                        <Button
                          variant="secondary"
                          onClick={() => {
                            setViewError(true);
                          }}
                        >
                          View Full Error
                        </Button>
                      )}
                    </Flex>
                  </>
                )}
              </Flex>
            </>
          ) : (
            <Flex sx={{ mt: "auto", mb: "auto", alignSelf: "center", justifySelf: "center" }}>
              <Placeholder
                content={{
                  title: "Send a row to view the response",
                  body: "This will sync one row to your destination at a time",
                }}
                error={false}
                sx={{ height: "unset", bg: null }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Modal>
  );
};

const getType = (value) => {
  return value === null ? "null" : typeof value === "object" ? (Array.isArray(value) ? "array" : "object") : typeof value;
};

const TYPE_OPTIONS = [
  { label: "String", value: "string" },
  { label: "Number", value: "number" },
  { label: "Object", value: "object" },
  { label: "Array", value: "array" },
  { label: "Null", value: "null" },
  { label: "Boolean", value: "boolean" },
];

const cast = {
  string: (v) => v || "",
  number: (v) => {
    if (isNaN(v)) {
      throw new Error("Value is NaN.");
    }
    return Number(v);
  },
  array: (v) => {
    const value = JSON.parse(v);
    if (!Array.isArray(value)) {
      throw new Error("Value is not a valid array.");
    }
    return value;
  },
  object: (v) => {
    const value = JSON.parse(v);
    if (typeof value !== "object") {
      throw new Error("Value is not a valid object.");
    }
    return value;
  },
  boolean: (v) => {
    const value = JSON.parse(v);
    if (typeof value !== "boolean") {
      throw new Error("Value is not a valid boolean.");
    }
    return value;
  },
  null: () => null,
};

const valueToString = (value) => {
  return typeof value === "object" || typeof value === "boolean" ? JSON.stringify(value) : value;
};

const RowValue = ({
  property,
  value,
  setValue,
  readOnly = false,
  tooltip,
}: {
  property: string;
  value: any;
  setValue?: (value: any) => void;
  readOnly?: boolean;
  tooltip?: string;
}) => {
  const { addToast } = useToasts();

  const [hover, setHover] = useState(false);
  const [editing, _setEditing] = useState(false);

  const [editValue, setEditValue] = useState<string>();
  const [editType, setEditType] = useState<string>();

  const setEditing = (v: boolean) => {
    if (v) {
      setEditValue(valueToString(value));
      setEditType(getType(value));
    } else {
      setEditValue(undefined);
      setEditType(undefined);
    }
    _setEditing(v);
  };

  const save = () => {
    if (setValue) {
      try {
        const value = editType ? cast[editType](editValue) : undefined;

        if (value === undefined) {
          throw new Error("Value cannot be undefined. To set a null value, use the object type with the input null.");
        }

        setValue(value);
        setEditing(false);
      } catch (e) {
        addToast(e?.message, { appearance: "error" });
      }
    } else {
      setEditing(false);
    }
  };

  return (
    <Flex
      sx={{ flex: 0, p: 4, width: "100%", borderBottom: "small" }}
      onPointerEnter={() => {
        setHover(true);
      }}
      onPointerLeave={() => {
        setHover(false);
      }}
    >
      <Grid columns={editing ? "1fr 1fr 1fr 60px" : "1fr 0.75fr 1.25fr 60px"} sx={{ width: "100%", height: "32px" }}>
        <Cell>
          <Text sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{property}</Text>
        </Cell>
        {!editing ? (
          <>
            <Cell>
              <Badge variant="base">{getType(value)}</Badge>
              {tooltip && <Tooltip sx={{ ml: 2 }} text={tooltip} />}
            </Cell>
            <Cell>
              <Text sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>{valueToString(value)}</Text>
            </Cell>
            {!readOnly && (
              <Cell sx={{ justifyContent: "flex-end" }}>
                <Button
                  sx={{ ":hover": { svg: { fill: "base.8" } } }}
                  variant="plain"
                  onClick={() => {
                    setEditing(true);
                  }}
                >
                  <PenIcon
                    color="base.4"
                    size={18}
                    sx={{
                      visibility: hover ? "visible" : "hidden",
                      transition: "all 0.15s",
                    }}
                  />
                </Button>
              </Cell>
            )}
          </>
        ) : (
          <>
            <Cell>
              <Select
                options={TYPE_OPTIONS}
                size="small"
                value={TYPE_OPTIONS?.find((o) => o?.value === editType) || null}
                onChange={(selected) => {
                  if (editType !== selected?.value) {
                    setEditType(selected?.value);
                    setEditValue(undefined);
                  }
                }}
              />
            </Cell>
            <Cell>
              {editType !== "null" ? (
                <Input
                  placeholder={"Enter a value..."}
                  size="small"
                  type={editType === "number" ? "number" : undefined}
                  value={editValue || ""}
                  onChange={(value) => {
                    setEditValue(value);
                  }}
                />
              ) : (
                <Text>null</Text>
              )}
            </Cell>
            <Cell sx={{ justifyContent: "flex-end" }}>
              <Button
                sx={{ ":hover": { svg: { fill: "base.8" } } }}
                variant="plain"
                onClick={() => {
                  save();
                }}
              >
                <CheckIcon
                  color="green"
                  size={18}
                  sx={{
                    transition: "all 0.15s",
                  }}
                />
              </Button>
              <Button
                sx={{ p: 0, ml: 2, ":hover": { svg: { fill: "base.8" } } }}
                variant="plain"
                onClick={() => {
                  setEditing(false);
                }}
              >
                <XIcon
                  color="base.4"
                  size={18}
                  sx={{
                    transition: "all 0.15s",
                  }}
                />
              </Button>
            </Cell>
          </>
        )}
      </Grid>
    </Flex>
  );
};

const Cell = ({ children, sx = {} }) => <Flex sx={{ alignItems: "center", px: 2, ...sx }}>{children}</Flex>;
