import { FC } from "react";

import { ShieldExclamationIcon } from "@heroicons/react/24/solid";
import { LinkButton, Button, Heading, Column, Box, Text } from "@hightouchio/ui";
import { useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";

import { AuthLayout } from "src/components/auth/auth-layout";
import { useUser } from "src/contexts/user-context";
import { PartnerConnectionFragment, useRequestMembershipMutation } from "src/graphql";
import { Card } from "src/ui/card";

export const Unauthorized: FC = () => {
  const { addToast } = useToasts();
  const { user, workspace } = useUser();
  const location = useLocation();
  const state = location?.state as { error: string; partnerConnection: PartnerConnectionFragment } | undefined;

  const { isLoading: requestLoading, mutateAsync: requestMembership, isSuccess } = useRequestMembershipMutation();

  return (
    <AuthLayout>
      <Card sx={{ mt: 24 }}>
        <Column align="center" gap={8} sx={{ color: "gray.900" }}>
          <Box color="grass">
            <ShieldExclamationIcon fill="currentColor" width="64px" />
          </Box>

          <Heading size="2xl">Access restricted</Heading>

          <Text>{state?.error || `You aren’t authorized to access this resource`}</Text>

          <Column gap={3} width="100%">
            {state?.partnerConnection && (
              <Button
                isDisabled={isSuccess}
                isLoading={requestLoading}
                onClick={async () => {
                  await requestMembership({
                    workspaceId: String(state.partnerConnection.workspaceId),
                    partnerConnectionId: state.partnerConnection.uuid,
                  });
                  addToast(`Access requested`, { appearance: "success" });
                }}
              >
                Request access
              </Button>
            )}
            {workspace ? (
              <LinkButton href="/">Back to workspace</LinkButton>
            ) : user ? (
              <LinkButton href="/workspaces">Back to workspaces</LinkButton>
            ) : (
              <>
                <LinkButton href="/login">Login</LinkButton>
                <LinkButton href="/signup">Signup</LinkButton>
              </>
            )}
          </Column>
        </Column>
      </Card>
    </AuthLayout>
  );
};
