import { FC, useState, useMemo, useCallback, useEffect } from "react";

import * as Sentry from "@sentry/browser";
import { capitalize, isEmpty } from "lodash";
import pluralize from "pluralize";
import { useToasts } from "react-toast-notifications2";
import { Image, Text } from "theme-ui";

import { DraftBadge } from "src/components/drafts/draft-badge";
import { DraftIcon } from "src/components/drafts/draft-icon";
import { Filters, getHasuaExpFromFilters, modelFilterDefinitions } from "src/components/filter";
import { CreateViewModal } from "src/components/filter/create-view";
import { Views } from "src/components/filter/views";
import { EditLabels } from "src/components/labels/edit-labels";
import { LabelsCell } from "src/components/labels/labels-cell";
import { Page } from "src/components/layout";
import { BulkDeleteConfirmationModal } from "src/components/modals/bulk-delete-confirmation-modal";
import { Permission } from "src/components/permission";
import placeholderSource from "src/components/permission/source.svg";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  MinimalModelsQuery,
  ModelsQuery,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAddLabelsToModelsMutation,
  useDeleteModelsMutation,
  useDraftsQuery,
  useMinimalModelsQuery,
  useModelFiltersQuery,
  useModelsQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import useQueryState from "src/hooks/use-query-state";
import * as analytics from "src/lib/analytics";
import { Fade } from "src/ui/animations/fade";
import { Row, Column, Box } from "src/ui/box";
import { Button, DropdownButton } from "src/ui/button";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon } from "src/ui/icons";
import { SearchInput } from "src/ui/input";
import { Menu, MenuOption } from "src/ui/menu";
import { Table, Pagination, useTableConfig, TableColumn } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useFiltering } from "src/ui/table/use-filtering";
import { useRowSelect } from "src/ui/table/use-row-select";
import { TextWithTooltip } from "src/ui/text";
import { Tooltip } from "src/ui/tooltip";
import { useDestinations } from "src/utils/destinations";
import { useIncrementalQuery } from "src/utils/incremental-query";
import { QueryType } from "src/utils/models";
import { useNavigate } from "src/utils/navigate";
import { abbreviateNumber } from "src/utils/numbers";
import { useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

import { useLabels } from "../../components/labels/use-labels";

enum SortKeys {
  Name = "name",
  NumSyncs = "syncs_aggregate.count",
  QueryType = "query_type",
  UpdatedAt = "updated_at",
}

export const Models: FC = () => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const [search, setSearch] = useQueryState("search");
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const { mutateAsync: bulkDelete, isLoading: loadingBulkDelete } = useDeleteModelsMutation();
  const [loading, setLoading] = useState<boolean>(true);
  const [createViewModalOpen, setCreateViewModalOpen] = useState(false);
  const [addingLabels, setAddingLabels] = useState(false);

  const { hasPermission: userCanDelete } = useHasPermission([{ resource: "model", grants: [ResourcePermissionGrant.Delete] }]);

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    sortOptions: Object.values(SortKeys),
  });

  const { labels } = useLabels();

  const { mutateAsync: addLabels, isLoading: loadingAddLabels } = useAddLabelsToModelsMutation();

  const {
    state: { creatingView, filters, selectedView, viewNotSaved, views, updatingView },
    actions: { createView, deleteView, resetViewFilters, selectView, updateCurrentView, updateFilters },
  } = useFiltering({ viewKey: "model" });

  const hasuraFilters = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      _and: [
        { is_schema: { _eq: false } },
        { query_type: { _neq: "visual" } },
        getHasuaExpFromFilters(modelFilterDefinitions, filters),
      ].filter((object) => !isEmpty(object)),
    };

    if (search) {
      hasuraFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return hasuraFilters;
  }, [filters, search]);

  // used for filters
  const { data: modelFilterData } = useModelFiltersQuery();

  const fullModelsQuery = useModelsQuery({
    offset,
    limit,
    filters: hasuraFilters,
    orderBy,
  });

  const incrementalModels = useIncrementalQuery<MinimalModelsQuery, ModelsQuery>(
    useMinimalModelsQuery(
      {
        offset,
        limit,
        filters: hasuraFilters,
        orderBy,
      },
      {
        refetchInterval: 3000,
        notifyOnChangeProps: "tracked",
        keepPreviousData: true,
      },
    ),
    fullModelsQuery,
  );

  const { data: drafts } = useDraftsQuery({
    resourceType: "model",
    status: "pending",
  });

  const models = incrementalModels?.data?.segments;
  const modelsCount = incrementalModels?.data?.segments_aggregate?.aggregate?.count ?? 0;

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const { overageLockout, destinationOverageText } = entitlementsData.overage;
  const overageText = destinationOverageText + " To create a model, upgrade your plan.";

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const { data: sources } = useSources();

  const bulkAddLabels = async (labels: Record<string, string>) => {
    const labelCount = Object.keys(labels).length;

    try {
      await addLabels({ ids: selectedRows.map(String), labels });
      setAddingLabels(false);
      addToast(
        `Added ${labelCount} ${pluralize("label", labelCount)} to ${selectedRows.length} ${pluralize(
          "model",
          selectedRows.length,
        )}`,
        {
          appearance: "success",
        },
      );

      onRowSelect([]);
      setLoading(true);
    } catch (error) {
      addToast("Failed to update labels.", {
        appearance: "error",
      });
      Sentry.captureException(error);
    }
  };

  const bulkDeleteModels = async () => {
    if (userCanDelete) {
      await bulkDelete({ ids: selectedRows.map(String) });
      onRowSelect([]);
    }
  };

  const actions: MenuOption[] = [{ label: "Add labels", onClick: () => setAddingLabels(true) }];

  if (userCanDelete) {
    actions.push({
      label: "Delete",
      onClick: () => setConfirmingDelete(true),
      sx: { color: "red", ":hover::not(:disabled)": { backgroundColor: "reds.0" } },
    });
  }

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ id, name, connection, tags, draft: isInitialDraft }) => {
          const source = sources?.find((source) => source.id === connection?.id);
          const draft = drafts?.drafts.find((d) => String(d.resource_id) === String(id));

          return (
            <Tooltip disabled={Boolean(source)} text="This destination is only visible to some users">
              <Row sx={{ alignItems: "center", mt: 1 }}>
                <Image
                  alt={source?.definition?.name ?? "Private source"}
                  src={source?.definition?.icon ?? placeholderSource}
                  sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
                />
                <TextWithTooltip disabled={!name} sx={{ fontWeight: "semi", maxWidth: "350px" }} text={name}>
                  {name ?? "Private source"}
                </TextWithTooltip>
                {isInitialDraft && <DraftBadge sx={{ ml: 2 }} />}
                {draft && <DraftIcon draft={draft} sx={{ ml: 2 }} />}

                <LabelsCell labels={tags} />
              </Row>
            </Tooltip>
          );
        },
      },
      {
        name: "Size",
        key: "query_runs.[0].size",
        max: "max-content",
        cell: (size) => (size ? <Text>{abbreviateNumber(size)}</Text> : <Text sx={{ color: "base.4" }}>--</Text>),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.NumSyncs),
        max: "max-content",
        min: "232px",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell definitions={destinationDefinitions ?? []} syncs={syncs} />;
        },
      },
      {
        name: "Type",
        sortDirection: orderBy?.query_type,
        onClick: () => onSort(SortKeys.QueryType),
        max: "max-content",
        cell: ({ query_type, custom_query }) => (
          <Text sx={{ fontWeight: "semi", color: "base.6" }}>
            {query_type === QueryType.Table
              ? "Table"
              : query_type === QueryType.Dbt
              ? "dbt"
              : query_type === QueryType.DbtModel
              ? "dbt Model"
              : query_type === QueryType.Custom
              ? custom_query?.["type"]
                ? capitalize(custom_query["type"])
                : "Custom"
              : "SQL"}
          </Text>
        ),
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
      },
    ],
    [sources, destinationDefinitions, orderBy, drafts],
  );

  const onRowClick = useCallback(({ id }, event) => openUrl(`/models/${id}`, navigate, event), [navigate]);

  const placeholder = useMemo(
    () => ({
      title: "No models",
      body: search ? "" : "Add a model to get started",
      error: "Models failed to load, please try again.",
    }),
    [search],
  );

  useEffect(() => {
    setPage(0);
  }, [hasuraFilters]);

  useEffect(() => {
    onRowSelect([]);
  }, [page]);

  useEffect(() => {
    setLoading(true);
  }, [limit, offset, search, orderBy, hasuraFilters]);

  useEffect(() => {
    if (!incrementalModels.minimalQueryRefetching) {
      setLoading(false);
    }
  }, [incrementalModels.minimalQueryRefetching, models]);

  return (
    <>
      <PermissionProvider permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Update] }]}>
        <Page crumbs={[{ label: "Models" }]} size="full">
          <Column sx={{ mb: 3, width: "100%" }}>
            <Row sx={{ alignItems: "center", justifyContent: "space-between", mb: 8 }}>
              <Row sx={{ alignItems: "center " }}>
                <Heading sx={{ mr: 2 }}>Models</Heading>
                <Views
                  deletePermissionResource="model"
                  value={selectedView}
                  views={views}
                  onChange={selectView}
                  onDelete={deleteView}
                />
                {viewNotSaved &&
                  (selectedView === "Default view" ? (
                    <Button
                      sx={{ ml: 2 }}
                      variant="purple"
                      onClick={() => {
                        setCreateViewModalOpen(true);
                      }}
                    >
                      Save as
                    </Button>
                  ) : (
                    <DropdownButton
                      loading={updatingView}
                      options={[
                        {
                          label: "Save as",
                          onClick: () => {
                            setCreateViewModalOpen(true);
                          },
                        },
                        {
                          label: "Reset changes",
                          onClick: () => {
                            resetViewFilters();
                          },
                        },
                      ]}
                      sx={{ ml: 2 }}
                      onClick={updateCurrentView}
                    >
                      Save
                    </DropdownButton>
                  ))}
              </Row>
              <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}>
                <Button
                  disabled={overageLockout}
                  tooltip={overageLockout ? overageText : undefined}
                  onClick={() => {
                    analytics.track("Add Model Clicked");
                    navigate(`/models/new`);
                  }}
                >
                  Add model
                </Button>
              </Permission>
            </Row>
            <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
              <Box sx={{ display: "flex", flexWrap: "nowrap" }}>
                <SearchInput placeholder="Search models by name..." value={search ?? ""} onChange={setSearch} />
                <Filters
                  data={modelFilterData?.segments ?? []}
                  filterDefinitions={modelFilterDefinitions}
                  filters={filters}
                  resourceType="segment"
                  sx={{ ml: 2 }}
                  onChange={updateFilters}
                />
              </Box>

              <Fade hidden={selectedRows.length === 0} sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Text as="label" sx={{ display: "flex", alignItems: "center" }}>
                    <Text as="span" sx={{ color: "base.5" }}>{`${pluralize(
                      "model",
                      selectedRows.length,
                      true,
                    )} selected`}</Text>

                    <Menu options={actions}>
                      <Button
                        propagate
                        iconAfter={<ChevronDownIcon size={16} />}
                        loading={loadingBulkDelete}
                        sx={{ ml: 3 }}
                        variant="secondary"
                      >
                        Select action
                      </Button>
                    </Menu>
                  </Text>
                </Box>
              </Fade>
            </Row>
          </Column>

          <Table
            columns={columns}
            data={models}
            error={Boolean(incrementalModels.fullQueryError || incrementalModels.minimalQueryError)}
            loading={incrementalModels.minimalQueryLoading || (loading && incrementalModels.minimalQueryRefetching)}
            placeholder={placeholder}
            selectedRows={selectedRows}
            onRowClick={onRowClick}
            onSelect={onRowSelect}
          />

          <Pagination count={modelsCount} label="models" page={page} rowsPerPage={limit} setPage={setPage} />
        </Page>
      </PermissionProvider>

      <BulkDeleteConfirmationModal
        count={selectedRows.length}
        isOpen={confirmingDelete}
        label="model"
        loading={loadingBulkDelete}
        onClose={() => setConfirmingDelete(false)}
        onDelete={bulkDeleteModels}
      />

      <CreateViewModal
        isOpen={createViewModalOpen}
        loading={creatingView}
        onClose={() => setCreateViewModalOpen(false)}
        onSave={createView}
      />

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={addingLabels}
        loading={loadingAddLabels}
        saveLabel={`Apply to ${selectedRows.length} ${pluralize("model", selectedRows.length)}`}
        title="Add labels"
        onClose={() => setAddingLabels(false)}
        onSave={bulkAddLabels}
      />
    </>
  );
};
