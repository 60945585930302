import { FC, useState, useMemo, useEffect } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { useToasts } from "react-toast-notifications2";
import { Text, Flex, Grid } from "theme-ui";
import { object, array, string } from "yup";

import {
  useAddRelationshipMutation,
  useDeleteRelationshipMutation,
  useDirectRelationshipModelsQuery,
  useRelationshipDependenciesQuery,
  useRelationshipsQuery,
  useUpdateDirectRelationshipMutation,
  useUpdateThroughRelationshipMutation,
  ObjectQuery,
} from "src/graphql";
import { Arrow } from "src/ui/arrow";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { XCircleIcon, PenIcon, XIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Select } from "src/ui/select";
import { Toggle } from "src/ui/toggle";

import { Permission } from "../permission";

type Model = NonNullable<ObjectQuery["segments_by_pk"]>;
type ModelRelationship = Model["relationships"][0];

type Props = {
  model: Model;
};

export const Relationships: FC<Readonly<Props>> = ({ model }) => {
  const [addingThrough, setAddingThrough] = useState<boolean>(false);
  const [addingDirect, setAddingDirect] = useState<boolean>(false);
  const [editDirect, setEditDirect] = useState<boolean | ModelRelationship | undefined>(false);
  const [editThrough, setEditThrough] = useState<boolean | ModelRelationship | undefined>(false);
  const [deleteRelationship, setDeleteRelationship] = useState<ModelRelationship | undefined>();

  const relationships = model?.relationships;

  const directRelationships = relationships?.filter(({ direct_relationships }) => direct_relationships?.length);
  const throughRelationships = relationships?.filter(({ through_relationships }) => through_relationships?.length);

  return (
    <>
      <Flex sx={{ alignItems: "center", justifyContent: "space-between", borderBottom: "small", pb: 2 }}>
        <Heading>Direct Relationships</Heading>
        <Permission>
          <Button size="small" variant="secondary" onClick={() => setAddingDirect(true)}>
            Add direct relationship
          </Button>
        </Permission>
      </Flex>

      <Grid gap={4}>
        {directRelationships?.length ? (
          directRelationships.map((rel, index) => {
            const { id, name, direct_relationships } = rel;

            return (
              <Column key={id} sx={{ py: 4, borderTop: index > 0 ? "small" : undefined }}>
                <Flex sx={{ alignItems: "center", mb: 2, justifyContent: "space-between" }}>
                  <Text sx={{ mr: 4, fontWeight: "semi" }}>{name}</Text>
                  <Row sx={{ alignItems: "center" }}>
                    <Button sx={{ mr: 2 }} variant="plain" onClick={() => setEditDirect(rel)}>
                      <PenIcon size={14} />
                    </Button>
                    <Button variant="plain" onClick={() => setDeleteRelationship(rel)}>
                      <XIcon size={16} />
                    </Button>
                  </Row>
                </Flex>
                <Grid gap={2}>
                  {direct_relationships.map(({ from_column, to_column, to_model }, index) => (
                    <DirectRelationship
                      key={index}
                      fromColumn={from_column}
                      fromModel={model}
                      toColumn={to_column}
                      toModel={to_model}
                    />
                  ))}
                </Grid>
              </Column>
            );
          })
        ) : (
          <Flex sx={{ py: 4 }}>
            <Text sx={{ color: "dark.1" }}>This object has no direct relationships.</Text>
          </Flex>
        )}
      </Grid>

      <Flex sx={{ alignItems: "center", justifyContent: "space-between", pb: 2, borderBottom: "small", mt: 12 }}>
        <Heading>Through Relationships</Heading>
        <Permission>
          <Button size="small" variant="secondary" onClick={() => setAddingThrough(true)}>
            Add through relationship
          </Button>
        </Permission>
      </Flex>

      <Grid gap={4}>
        {throughRelationships?.length ? (
          throughRelationships.map((rel, index) => {
            const { id, name, to_model, through_relationships } = rel;
            const throughModel = through_relationships.find(
              ({
                relationship: {
                  to_model: { id },
                },
              }) => id !== to_model.id,
            )!.relationship.to_model;
            return (
              <Column key={id} sx={{ py: 4, borderTop: index > 0 ? "small" : undefined }}>
                <Flex sx={{ alignItems: "center", mb: 2, justifyContent: "space-between" }}>
                  <Text sx={{ mr: 4, fontWeight: "semi" }}>{name}</Text>
                  <Row sx={{ alignItems: "center" }}>
                    <Button sx={{ mr: 2 }} variant="plain" onClick={() => setEditThrough(rel)}>
                      <PenIcon size={14} />
                    </Button>
                    <Button variant="plain" onClick={() => setDeleteRelationship(rel)}>
                      <XIcon size={16} />
                    </Button>
                  </Row>
                </Flex>
                <ThroughRelationship fromModel={model} throughModel={throughModel} toModel={to_model} />
              </Column>
            );
          })
        ) : (
          <Flex sx={{ py: 4 }}>
            <Text sx={{ color: "dark.1" }}>This object has no through relationships.</Text>
          </Flex>
        )}
      </Grid>

      {addingDirect && <DirectRelationshipForm model={model} onClose={() => setAddingDirect(false)} />}
      {editDirect && (
        <DirectRelationshipForm
          model={model}
          relationship={typeof editDirect === "object" ? editDirect : undefined}
          onClose={() => setEditDirect(undefined)}
        />
      )}
      {addingThrough && (
        <ThroughRelationshipForm
          directRelationships={directRelationships}
          model={model}
          onClose={() => setAddingThrough(false)}
        />
      )}
      {editThrough && (
        <ThroughRelationshipForm
          directRelationships={directRelationships}
          model={model}
          relationship={typeof editThrough === "object" ? editThrough : undefined}
          onClose={() => setEditThrough(undefined)}
        />
      )}
      {deleteRelationship && (
        <DeleteRelationship relationship={deleteRelationship} onClose={() => setDeleteRelationship(undefined)} />
      )}
    </>
  );
};

const DirectRelationship: FC<Readonly<{ toColumn: string; fromColumn: string; fromModel: any; toModel: any }>> = ({
  fromColumn,
  toColumn,
  fromModel,
  toModel,
}) => {
  return (
    <Flex sx={{ alignItems: "center" }}>
      <Input disabled readOnly value={`${fromModel?.name}.${fromColumn}`} />
      <Arrow />
      <Input disabled readOnly value={`${toModel?.name}.${toColumn}`} />
    </Flex>
  );
};

interface ThroughRelationshipProps {
  fromModel: Model | undefined;
  throughModel: ModelRelationship["through_relationships"][0]["relationship"]["to_model"] | undefined;
  toModel: ModelRelationship["to_model"] | undefined;
}

const ThroughRelationship: FC<Readonly<ThroughRelationshipProps>> = ({ fromModel, throughModel, toModel }) => {
  return (
    <Flex sx={{ alignItems: "center" }}>
      <Input disabled readOnly value={fromModel?.name} />
      <Arrow />
      <Input disabled readOnly value={throughModel?.name} />
      <Arrow />
      <Input disabled readOnly value={toModel?.name} />
    </Flex>
  );
};

interface DirectRelationshipFormValues {
  name: string;
  toModel: {
    label: string;
    value: string;
    columns: ModelRelationship["to_model"]["columns"];
  } | null;
  mappings: Array<{
    from_column:
      | string
      | {
          label: string;
          value: string;
        }
      | null;
    to_column:
      | string
      | {
          label: string;
          value: string;
        }
      | null;
  }>;
  merge_columns: NonNullable<ModelRelationship["merge_columns"]>;
}

interface DirectRelationshipFormProps {
  relationship?: ModelRelationship | null;
  model: Model | undefined;
  onClose: () => void;
}

const DirectRelationshipForm: FC<DirectRelationshipFormProps> = ({ relationship = null, model, onClose }) => {
  const { data: modelsData } = useDirectRelationshipModelsQuery(
    { sourceId: model?.connection?.id },
    { enabled: Boolean(model) },
  );
  const relatedModels = modelsData?.segments;

  const validationSchema = object().shape({
    name: string().required("A relationship name is required"),
    toModel: object().required("A to model is required"),
    mappings: array()
      .of(
        object().shape({
          to_column: object().shape({ value: string(), label: string() }).required("A to column is required"),
          from_column: object().shape({ value: string(), label: string() }).required("A to column is required"),
        }),
      )
      .required(),
  });

  const modelOptions = relatedModels
    ?.filter(({ id }) => id !== model?.id)
    ?.map(({ name, id, columns }) => ({ value: id, label: name, columns }));

  const defaultValues = relationship
    ? {
        name: relationship.name,
        toModel: { value: relationship.to_model.id, label: relationship.to_model.name, columns: relationship.to_model.columns },
        mappings: relationship.direct_relationships.map(({ from_column, to_column }) => ({
          from_column: { value: from_column, label: from_column },
          to_column: { value: to_column, label: to_column },
        })),
        merge_columns: Boolean(relationship.merge_columns),
      }
    : {
        name: "",
        toModel: null,
        mappings: [
          {
            to_column: null,
            from_column: null,
          },
        ],
        merge_columns: false,
      };

  const { control, register, handleSubmit, watch } = useForm<DirectRelationshipFormValues>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "mappings",
  });
  const toModel = watch("toModel");

  const { addToast } = useToasts();

  const fromColumnOptions = model?.columns?.map(({ name }) => ({ value: name, label: name }));
  const toColumnOptions = toModel?.columns?.map(({ name }) => ({
    label: name,
    value: name,
  }));

  const { isLoading: adding, mutateAsync: addRelationship } = useAddRelationshipMutation();
  const { isLoading: updating, mutateAsync: updateRelationship } = useUpdateDirectRelationshipMutation();

  const loading = updating || adding;

  const onSubmit = async ({ name, mappings, merge_columns }) => {
    const to_model_id = toModel?.value;

    const directRelationships = mappings.map(({ from_column, to_column }) => ({
      relationship_id: relationship?.id,
      from_column: from_column.value,
      to_column: to_column.value,
      from_model_id: model?.id,
      to_model_id,
    }));

    if (relationship) {
      await updateRelationship({
        id: relationship.id,
        object: {
          name,
          to_model_id,
          merge_columns,
        },
        directRelationships,
      });
      addToast(`Direct relationship updated!`, {
        appearance: "success",
      });
      onClose();
    } else {
      await addRelationship({
        object: {
          name,
          merge_columns,
          is_direct_relationship: true,
          from_model_id: model?.id,
          to_model_id,
          direct_relationships: {
            data: directRelationships,
          },
        },
      });

      addToast(`Direct relationship added!`, {
        appearance: "success",
      });
      onClose();
    }
  };

  return (
    <Modal
      footer={
        <>
          <Controller
            control={control}
            name="merge_columns"
            render={({ field }) => <Toggle label="Merge columns" {...field} />}
          />
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleSubmit(onSubmit)}>
            {relationship ? "Save" : "Add relationship"}
          </Button>
        </>
      }
      sx={{ maxWidth: "700px", width: "100%" }}
      title={`${relationship ? "Edit" : "Add a"} direct relationship`}
      onClose={onClose}
    >
      <Grid gap={8}>
        <Field label="Relationship name">
          <Input {...register(`name` as const)} sx={{ width: "281px" }} />
        </Field>
        <Field label="Related model">
          <Controller
            control={control}
            name={`toModel`}
            render={({ field }) => (
              <Select
                options={modelOptions}
                placeholder="Model..."
                sx={{ mr: 4, width: "281px" }}
                {...field}
                value={field.value?.value}
              />
            )}
            rules={{ required: true }}
          />
        </Field>
        <Field label="Mappings">
          <Grid gap={2}>
            {(fields as any).map((field, index) => (
              <Flex key={field.id} sx={{ alignItems: "center" }}>
                <Controller
                  control={control}
                  defaultValue={field?.from_column}
                  name={`mappings.${index}.from_column` as any}
                  render={({ field }) => (
                    <Select disabled={!toModel} options={fromColumnOptions} placeholder="From column..." {...field} />
                  )}
                  rules={{ required: true }}
                />
                <Arrow />
                <Controller
                  control={control}
                  defaultValue={field?.to_column}
                  name={`mappings.${index}.to_column` as any}
                  render={({ field }) => (
                    <Select
                      disabled={!toModel}
                      options={toColumnOptions}
                      placeholder="To column..."
                      sx={{ mr: 2 }}
                      {...field}
                    />
                  )}
                  rules={{ required: true }}
                />
                <Button sx={{ visibility: index === 0 ? "hidden" : undefined }} variant="plain" onClick={() => remove(index)}>
                  <XCircleIcon />
                </Button>
              </Flex>
            ))}
            <Button disabled={!toModel} variant="white" onClick={() => append({ from_column: "", to_column: "" })}>
              Add mapping
            </Button>
          </Grid>
        </Field>
      </Grid>
    </Modal>
  );
};

interface ThroughRelationshipFormProps {
  relationship?: ModelRelationship | null;
  model: Model;
  directRelationships: ModelRelationship[];
  onClose: () => void;
}

interface Option {
  value: string;
  label: string;
  relationshipId: string;
}

const ThroughRelationshipForm: FC<ThroughRelationshipFormProps> = ({
  relationship = null,
  model,
  directRelationships,
  onClose,
}) => {
  const validationSchema = object().shape({
    name: string().required("A relationship name is required"),
    throughModel: object()
      .shape({ label: string(), value: string(), modelId: string() })
      .required("A through model is required"),
    toModel: object().shape({ label: string(), value: string(), modelId: string() }).required("A to model is required"),
  });

  const { addToast } = useToasts();

  const getOption = ({ id, to_model: { name, id: modelId } }): Option => ({
    value: modelId,
    label: name,
    relationshipId: id,
  });

  const defaultValues = useMemo(() => {
    const currentThroughRelationship = relationship?.through_relationships?.find(
      ({ relationship: throughRelationship }) => throughRelationship.to_model.id !== relationship.to_model.id,
    )?.relationship;

    const currentToRelationship = relationship?.through_relationships?.find(
      ({ relationship: throughRelationship }) => throughRelationship.to_model.id === relationship.to_model.id,
    )?.relationship;

    return currentThroughRelationship && currentToRelationship
      ? {
          name: relationship.name,
          toModel: getOption(currentToRelationship),
          throughModel: getOption(currentThroughRelationship),
        }
      : {
          name: "",
          toModel: null,
          throughModel: null,
        };
  }, [relationship]);

  const { control, register, handleSubmit, watch, setValue } = useForm<{
    name: string;
    toModel: Option | null;
    throughModel: Option | null;
  }>({
    resolver: yupResolver(validationSchema),
    defaultValues,
  });

  const throughModel = watch("throughModel");

  const {
    data: throughModelRelationshipsData,
    isFetching: throughModelRelationshipsLoading,
    refetch: refetchThroughModelRelationships,
  } = useRelationshipsQuery(
    {
      modelId: throughModel?.value ?? "",
    },
    { enabled: Boolean(throughModel?.value) },
  );

  const throughModelRelationships = throughModelRelationshipsData?.segments_by_pk?.relationships;

  const { isLoading: saveLoading, mutateAsync: addRelationship } = useAddRelationshipMutation();
  const { isLoading: updateLoading, mutateAsync: updateRelationship } = useUpdateThroughRelationshipMutation();

  const loading = updateLoading || saveLoading;

  const onSubmit = async ({ name, toModel, throughModel }) => {
    const throughRelationships = [
      {
        relationship_id: relationship?.id,
        path_segment_id: throughModel?.relationshipId,
      },
      {
        relationship_id: relationship?.id,
        path_segment_id: toModel?.relationshipId,
      },
    ];

    if (relationship) {
      await updateRelationship({
        id: relationship.id,
        object: {
          name,
          to_model_id: toModel?.value,
        },
        throughRelationships,
      });
      addToast(`Through relationship edited!`, {
        appearance: "success",
      });
    } else {
      await addRelationship({
        object: {
          name,
          is_direct_relationship: false,
          from_model_id: model?.id,
          to_model_id: toModel?.value,
          through_relationships: {
            data: throughRelationships,
          },
        },
      });

      addToast(`Through relationship added!`, {
        appearance: "success",
      });
    }
    onClose();
  };

  const throughModelOptions = directRelationships?.map(getOption);
  const toModelOptions = throughModelRelationships?.map(getOption);

  useEffect(() => {
    if (throughModel?.value !== defaultValues.throughModel?.value) {
      refetchThroughModelRelationships();
      setValue("toModel", null);
    }
  }, [defaultValues, throughModel]);

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={loading} onClick={handleSubmit(onSubmit)}>
            {relationship ? "Save" : "Add relationship"}
          </Button>
        </>
      }
      sx={{ maxWidth: "500px", width: "100%" }}
      title={`${relationship ? "Edit" : "Add a"} through relationship`}
      onClose={onClose}
    >
      <Grid gap={8}>
        <Field label="Relationship name">
          <Input {...register("name")} />
        </Field>
        <Flex sx={{ alignItems: "center" }}>
          <Controller
            control={control}
            name={`throughModel`}
            render={({ field }) => (
              <Select options={throughModelOptions} placeholder="Through model..." {...field} value={field.value?.value} />
            )}
            rules={{ required: true }}
          />
          <Arrow />
          <Controller
            control={control}
            name={`toModel`}
            render={({ field }) => {
              return (
                <Select
                  disabled={!throughModel}
                  empty={`${throughModel?.label} has no direct relationships`}
                  isLoading={throughModelRelationshipsLoading}
                  options={toModelOptions}
                  placeholder="To model..."
                  {...field}
                  value={field.value?.value}
                />
              );
            }}
            rules={{ required: true }}
          />
        </Flex>
      </Grid>
    </Modal>
  );
};

const DeleteRelationship = ({ relationship, onClose }) => {
  const { addToast } = useToasts();

  const { isLoading: loading, mutateAsync: deleteRelationship } = useDeleteRelationshipMutation();

  const { data: dependenciesData, isLoading: loadingDependencies } = useRelationshipDependenciesQuery({
    id: String(relationship.id),
  });

  const dependencies = dependenciesData?.listRelationshipDependencies;
  const dependentModels = dependencies?.models;
  const dependentRelationships = dependencies?.relationships;

  const isDirect = relationship.direct_relationships?.length > 0;

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            loading={loading}
            variant="red"
            onClick={async () => {
              await deleteRelationship({ id: relationship.id });

              addToast("Relationship deleted", { appearance: "success" });

              onClose();
            }}
          >
            Delete
          </Button>
        </>
      }
      title={`Delete ${isDirect ? "direct" : "through"} relationship`}
      onClose={onClose}
    >
      {loadingDependencies ? (
        <Row sx={{ alignItems: "center", justifyContent: "center", width: "100%", height: "100%" }}>
          <Spinner size={64} />
        </Row>
      ) : (
        <>
          <Text>
            Are you sure you want to delete the <strong>{relationship.name}</strong> relationship?{" "}
            {(dependentRelationships?.length > 0 || dependentModels?.length > 0) &&
              "The following dependencies will be affected:"}
          </Text>

          {dependentModels?.length > 0 && (
            <>
              <Text sx={{ mt: 8, fontSize: 2, fontWeight: "semi", mb: 1 }}>Dependent audiences</Text>
              <Grid gap={1}>
                {dependentModels.map(({ name, id }) => (
                  <Link key={id} sx={{ width: "max-content" }} to={`/audiences/${id}`}>
                    {name}
                  </Link>
                ))}
              </Grid>
            </>
          )}

          {dependentRelationships?.length > 0 && (
            <>
              <Text sx={{ mt: 8, fontSize: 2, fontWeight: "semi", mb: 1 }}>Dependent relationships</Text>
              <Grid gap={1}>
                {dependentRelationships.map(({ name, id }) => (
                  <Text key={id}>{name}</Text>
                ))}
              </Grid>
            </>
          )}
        </>
      )}
    </Modal>
  );
};
