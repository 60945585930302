import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { MappingsField } from "src/components/destinations/mappings-field";
import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

export const validation = Yup.object().shape({
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  uploadTag: Yup.string().required(),
  eventSetId: Yup.string().required(),
  namespaceId: Yup.string().notRequired(),
  disablePIIHashing: Yup.boolean().notRequired(),
});

const OPTIONS = [
  { label: "event_name", value: "event_name", type: StandardFieldType.STRING, required: true },
  { label: "event_time", value: "event_time", type: StandardFieldType.DATETIME, required: true },
  { label: "currency", value: "currency", type: StandardFieldType.STRING },
  { label: "value", value: "value", type: StandardFieldType.NUMBER },
  { label: "order_id", value: "order_id", type: StandardFieldType.STRING },
  { label: "content_type", value: "content_type", type: StandardFieldType.STRING },
  {
    label: "contents",
    value: "contents",
    type: StandardFieldType.ARRAY,
  },
  {
    label: "data_processing_options",
    value: "data_processing_options",
    type: StandardFieldType.ARRAY,
  },
  {
    label: "data_processing_options_country",
    value: "data_processing_options_country",
    type: StandardFieldType.NUMBER,
  },
  {
    label: "data_processing_options_state",
    value: "data_processing_options_state",
    type: StandardFieldType.NUMBER,
  },
  {
    label: "item_number",
    value: "item_number",
    type: StandardFieldType.STRING,
  },
  { label: "match_keys.email", value: "match_keys.email", type: StandardFieldType.STRING },
  { label: "match_keys.phone", value: "match_keys.phone", type: StandardFieldType.STRING },
  { label: "match_keys.gen", value: "match_keys.gen", type: StandardFieldType.STRING },
  { label: "match_keys.doby", value: "match_keys.doby", type: StandardFieldType.STRING },
  { label: "match_keys.dobm", value: "match_keys.dobm", type: StandardFieldType.STRING },
  { label: "match_keys.dobd", value: "match_keys.dobd", type: StandardFieldType.STRING },
  { label: "match_keys.ln", value: "match_keys.ln", type: StandardFieldType.STRING },
  { label: "match_keys.fn", value: "match_keys.fn", type: StandardFieldType.STRING },
  { label: "match_keys.fi", value: "match_keys.fi", type: StandardFieldType.STRING },
  { label: "match_keys.ct", value: "match_keys.ct", type: StandardFieldType.STRING },
  { label: "match_keys.st", value: "match_keys.st", type: StandardFieldType.STRING },
  { label: "match_keys.zip", value: "match_keys.zip", type: StandardFieldType.STRING },
  { label: "match_keys.country", value: "match_keys.country", type: StandardFieldType.STRING },
  { label: "match_keys.extern_id", value: "match_keys.extern_id", type: StandardFieldType.STRING },
  { label: "match_keys.lead_id", value: "match_keys.lead_id", type: StandardFieldType.STRING },
];

export const FacebookForm: FC = () => {
  const { config, setConfig, errors } = useDestinationForm();

  return (
    <>
      <Section>
        <Grid gap={8}>
          <Field
            description={"Make sure your access token has access to this offline event set."}
            error={errors?.eventSetId}
            label={`Which offline event set should we send data to?`}
            size="large"
          >
            <Input
              defaultValue={config?.eventSetId}
              placeholder={"Enter an offline event set ID..."}
              sx={{ width: "240px" }}
              onChange={(eventSetId) => setConfig({ ...config, eventSetId })}
            />
          </Field>
          <Field
            description={"Send your event sets with this upload tag to track and debug event uploads."}
            error={errors?.uploadTag}
            label={`What is the upload tag to track your event uploads?`}
            size="large"
          >
            <Input
              defaultValue={config?.uploadTag}
              placeholder={"Enter an upload tag..."}
              sx={{ width: "240px" }}
              onChange={(uploadTag) => setConfig({ ...config, uploadTag })}
            />
          </Field>
          <Field
            optional
            description={"Scope used to resolve extern_id or tpid. Can be another data set or data partner ID."}
            error={errors?.namespaceId}
            label={`What is the namespace identifier?`}
            size="large"
          >
            <Input
              defaultValue={config?.namespaceId}
              placeholder={"Enter a namespace ID..."}
              sx={{ width: "240px" }}
              onChange={(namespaceId) => setConfig({ ...config, namespaceId })}
            />
          </Field>
        </Grid>
      </Section>
      <Section>
        <MappingsField options={OPTIONS} />
      </Section>

      <Section>
        <MappingsField isCustom />
      </Section>

      <Section>
        <Field
          description="By default, Hightouch automatically hashes values mapped to certain user data fields. For more information, check out the documentation."
          label={"Would you like Hightouch to automatically hash your PII data?"}
          size="large"
        >
          <RadioGroup
            options={[
              { label: "Yes, automatically hash PII for me", value: undefined },
              { label: "No, I will hash it myself", value: true },
            ]}
            value={config?.disablePIIHashing}
            onChange={(disablePIIHashing) => {
              setConfig({ ...config, disablePIIHashing });
            }}
          />
        </Field>
      </Section>
    </>
  );
};

export default {
  form: FacebookForm,
  validation,
};
