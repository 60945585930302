import { useEffect, useState, useRef, useMemo } from "react";

import { Heading } from "@hightouchio/ui";
import { useInfiniteHits } from "react-instantsearch-hooks-web";
import { useToasts } from "react-toast-notifications2";
import { Image } from "theme-ui";

import { DestinationsCatalog } from "src/components/destinations/catalog/destinations-catalog";
import { SetupForm } from "src/components/destinations/destination-form";
import {
  TestDestinationBadge,
  TestNewDestinationButton,
  TestResult,
  TestUpdatedDestinationButton,
} from "src/components/destinations/test-destination";
import { SidebarForm } from "src/components/page";
import { useUser } from "src/contexts/user-context";
import {
  DestinationDefinitionFragment as DestinationDefinition,
  useCreateDestinationV2Mutation,
  useFormkitDestinationDefinitionQuery,
  useUpdateDestinationV2Mutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Column, Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { Step } from "src/ui/wizard/wizard";
import { useDestination, useDestinations } from "src/utils/destinations";
import { ResourceType, useResourceSlug } from "src/utils/slug";
import { useQueryString } from "src/utils/use-query-string";

type CreateDestinationWizardArgs = {
  destinationDefinition?: DestinationDefinition;
  step: number;
  onConnectClick?(defintion: DestinationDefinition): void;
  onSubmit({ id, definition }: { id?: string; definition: DestinationDefinition }): void;
  setStep(step: number): void;
};

type Config = Record<string, unknown>;

export const useCreateDestinationWizard = ({
  destinationDefinition: externalDestinationDefinition,
  onConnectClick,
  onSubmit,
  step,
  setStep,
}: CreateDestinationWizardArgs) => {
  const { user } = useUser();
  const { addToast } = useToasts();
  const {
    data: { id: _destinationId, onboardingDestinationId },
    loading: loadingParams,
  } = useQueryString();

  const destinationId = onboardingDestinationId || _destinationId;

  const [name, setName] = useState("");
  const { getSlug } = useResourceSlug(ResourceType.Destination);
  const [config, setConfig] = useState<Config | undefined>();
  const [oAuthSuccess, setOAuthSuccess] = useState<boolean>(false);
  const [credentialId, setCredentialId] = useState<string | undefined>();
  const [testResult, setTestResult] = useState<TestResult>(TestResult.Unknown);
  const [testing, setTesting] = useState<boolean>(false);
  const [testError, setTestError] = useState<Error | null>(null);
  // Definition selected when creating the destination (Step 1)
  const [definition, setDefinition] = useState<DestinationDefinition | null>(null);

  const { mutateAsync: createDestination, isLoading: creating } = useCreateDestinationV2Mutation();
  const { mutateAsync: updateDestination, isLoading: updating } = useUpdateDestinationV2Mutation();

  const { data: formkitMethods } = useFormkitDestinationDefinitionQuery(
    { type: definition?.type || "" },
    { enabled: Boolean(definition), select: (data) => data.formkitDestinationDefinition },
  );

  const isOAuth = useMemo(() => {
    if (Array.isArray(formkitMethods)) {
      if (formkitMethods.length === 1) {
        return formkitMethods[0]?.method === "oauth";
      }
      return formkitMethods.find((s) => config?.methodKey === s.key)?.method === "oauth";
    }
    return false;
  }, [formkitMethods, config?.methodKey]);

  // Retrieve the full catalog of destinations for Step 1, not needed
  // if the destination already exists
  const {
    data: { definitions },
    loading: loadingCatalog,
    error: catalogError,
  } = useDestinations();

  // Retrieve the destination if already exists (Steps 2 and 3)
  const {
    data: { destination, definition: destinationDefinition },
    loading: loadingDestination,
  } = useDestination(destinationId ?? "", {
    pause: !destinationId,
  });

  // Algolia hit selected when creating the destination (Step 1)
  const hit = useRef<any>(null);
  const { sendEvent } = useInfiniteHits();

  // Or if the destination is already created
  useEffect(() => {
    if (destination && destinationDefinition) {
      setDefinition(destinationDefinition);
      if (destination.config) {
        setConfig((prev) => ({ ...prev, methodKey: destination.config.methodKey }));
      }
      setOAuthSuccess(true);
      setStep(1);
    }
  }, [destination, destinationDefinition]);

  // Onboarding: set definition if it changes externally
  useEffect(() => {
    if (externalDestinationDefinition) {
      setDefinition(externalDestinationDefinition);
    }
  }, [externalDestinationDefinition]);

  useEffect(() => {
    if (testResult !== TestResult.Unknown) {
      analytics.track("Destination Config Tested", {
        test_successful: testResult === TestResult.Success,
        error_message: `${testError}`,
      });
    }
  }, [testResult]);

  useEffect(() => {
    if (definition?.name) {
      setName(definition.name);
    }
  }, [definition?.name]);

  const create = async () => {
    let id;
    const slug = await getSlug(name);

    if (oAuthSuccess && destinationId) {
      const { updateDestinationWithSecrets } = await updateDestination({
        id: destinationId,
        object: {
          slug,
          name,
          config: {
            ...destination?.config,
            ...config,
          },
          setup_complete: true,
          created_by: user?.id != null ? String(user?.id) : undefined,
        },
      });
      id = updateDestinationWithSecrets?.id;
    } else {
      const object = {
        slug,
        name,
        config,
        setup_complete: true,
        created_by: user?.id != null ? String(user?.id) : undefined,
        type: definition?.type,
      };

      if (credentialId) {
        object["credential_id"] = credentialId;
        if (!config) {
          object["config"] = {};
        }
      }

      const { createDestinationWithSecrets } = await createDestination({
        object: object,
      });

      id = createDestinationWithSecrets?.id;
    }

    addToast(`Destination ${name || definition?.name} created!`, {
      appearance: "success",
    });

    analytics.track("Destination Created", {
      destination_id: id,
      destination_name: name,
      destination_type: definition?.name,
    });

    const definitionToSubmit = definition || destinationDefinition;

    if (definitionToSubmit) {
      onSubmit?.({ id, definition: definitionToSubmit });
    }
  };

  const steps: Step[] = [
    {
      title: "Select destination",
      disabled: !definition || definition?.status === "alpha" || definition?.status === "shadow",
      header: <Heading>Select a destination</Heading>,
      render: () => (
        <DestinationsCatalog
          destinationDefinitions={definitions ?? []}
          error={catalogError}
          selection={definition}
          onSelect={(selectedDefinition, selectedHit) => {
            setDefinition(selectedDefinition);
            hit.current = selectedHit;
          }}
        />
      ),
      onContinue: () => {
        if (hit.current) {
          sendEvent("conversion", hit.current, "Destination Catalog Continue Clicked");
        }
        if (definition) {
          analytics.track("Destination Catalog Continue Clicked", {
            destination_name: definition.name,
            destination_slug: definition.type,
            destination_status: definition.status,
          });
        }
        setStep(1);
      },
    },
    {
      title: "Connect destination",
      disabled: isOAuth ? !oAuthSuccess : !config && !credentialId,
      submitting: testing,
      //Use original validation and continue button for special forms on frontend, EX: (Google Sheets SA)
      //Will delete as soon as frontend forms get converted to backend destination configuration.
      onContinue: () => {
        if (oAuthSuccess) {
          setStep(2);
        }
      },
      continue: isOAuth && !oAuthSuccess ? "Authorize connection to continue" : undefined,
      continueProps: !oAuthSuccess ? { form: "destination-form", type: "submit" } : undefined,
      actions:
        definition?.testConnection &&
        !isOAuth &&
        (!destination?.id ? (
          <TestNewDestinationButton
            configuration={{ ...(config || {}) }}
            credentialId={credentialId}
            definition={definition}
            result={testResult}
            size="lg"
            onError={setTestError}
            onResult={setTestResult}
          />
        ) : (
          <TestUpdatedDestinationButton
            credentialId={credentialId}
            destinationId={destination.id.toString()}
            newConfiguration={{ ...destination.config, ...(config || {}) }}
            size="lg"
            onError={setTestError}
            onLoading={setTesting}
            onResult={setTestResult}
          />
        )),
      header: (
        <Row sx={{ alignItems: "center", gap: 4 }}>
          <Image src={definition?.icon} sx={{ width: "32px", objectFit: "contain" }} />
          <Heading>{`Connect to ${definition?.name}`}</Heading>
          <TestDestinationBadge key={0} result={testResult} testing={testing} />
        </Row>
      ),
      render: () => (
        <>
          {definition && (
            <Row sx={{ alignItems: "flex-start", gap: 8 }}>
              <Column sx={{ width: "100%", gap: 8 }}>
                <SetupForm
                  config={config}
                  credentialId={credentialId}
                  definition={definition}
                  destination={destination}
                  disableAuthMethod={Boolean(destinationId)}
                  error={testError}
                  isSetup={true}
                  setConfig={setConfig}
                  setCredentialId={setCredentialId}
                  onConnectClick={onConnectClick}
                  onSubmit={() => {
                    setStep(step + 1);
                    return Promise.resolve();
                  }}
                />
              </Column>
              <SidebarForm docsUrl={definition.docs ?? ""} name={definition.name} />
            </Row>
          )}
        </>
      ),
    },
    {
      title: "Finalize destination",
      disabled: !name,
      submitting: creating || updating,
      header: <Heading>Finalize settings for this destination</Heading>,
      render: () => (
        <Column sx={{ gap: 8, maxWidth: "600px" }}>
          <Field description="Including details about the destination's business purpose and owners" label="Destination name">
            <Input value={name} onChange={(value) => setName(value)} />
          </Field>
        </Column>
      ),
    },
  ];

  return {
    createDestination: create,
    definition,
    hit: hit.current,
    loading: loadingParams || loadingCatalog || loadingDestination,
    oAuthSuccess,
    steps,
  };
};
