import { useEffect, useRef, useState, FC } from "react";

import { Text } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { CheckCircleIcon, PenIcon, XCircleIcon } from "src/ui/icons";

import { Input, InputProps } from "./input";

export const InlineInput: FC<Readonly<Pick<InputProps, "value" | "onChange" | "disabled">>> = ({
  value,
  onChange,
  disabled,
}) => {
  const permission = usePermission();
  const [editing, setEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | undefined | null>(value || "");
  const inputRef = useRef<HTMLInputElement | null>(null);
  const widthRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (editing) {
      inputRef?.current?.focus();
    }
  }, [editing]);

  useEffect(() => {
    if (widthRef?.current && inputRef?.current) {
      inputRef.current.style.width = `${widthRef.current.offsetWidth}px`;
    }
  }, [currentValue, inputRef, widthRef]);

  useEffect(() => {
    setCurrentValue(value);
  }, [value]);

  if (disabled || permission?.unauthorized) {
    return <Text sx={{ fontSize: 4, fontWeight: "bold" }}>{value}</Text>;
  }

  return (
    <Row sx={{ alignItems: "center", position: "relative", flex: 1 }}>
      <Input
        ref={inputRef}
        readOnly={!editing}
        sx={{
          flex: "unset",
          p: 0,
          border: "none",
          fontSize: 4,
          fontWeight: "bold",
          cursor: editing ? undefined : "default",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
        value={currentValue}
        onChange={setCurrentValue}
      />
      {editing ? (
        <>
          <Button
            sx={{ mr: 2 }}
            variant="plain"
            onClick={() => {
              setEditing(false);
              setCurrentValue(value);
            }}
          >
            <XCircleIcon color="base.5" size={18} />
          </Button>
          <Button
            variant="plain"
            onClick={() => {
              setEditing(false);

              if (typeof onChange === "function") {
                onChange(currentValue);
              }
            }}
          >
            <CheckCircleIcon color="green" size={18} />
          </Button>
        </>
      ) : (
        <Button variant="plain" onClick={() => setEditing(true)}>
          <PenIcon color="base.6" size={16} />
        </Button>
      )}
      <Text ref={widthRef} sx={{ visibility: "hidden", position: "absolute", fontSize: 4, fontWeight: "bold", pr: 4 }}>
        {currentValue}
      </Text>
    </Row>
  );
};
