import { FC, useState } from "react";

import * as Sentry from "@sentry/react";
import { capitalize } from "lodash";
import { useToasts } from "react-toast-notifications2";

import { useDraft } from "src/contexts/draft-context";
import { ModelColumnsOrderBy, useUpdateModelColumnMutation } from "src/graphql";
import { ColumnType } from "src/types/visual";
import { Badge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Heading } from "src/ui/heading";
import { SearchInput } from "src/ui/input";
import { Select } from "src/ui/select";
import { Table, TableColumn } from "src/ui/table";

type Props = {
  columns: any;
  loading: boolean;
  modelId: string | undefined;
  orderBy: ModelColumnsOrderBy | undefined;
  onSort(sortKey: string): void;
  isDraft: boolean;
};

const typeOptions = [
  {
    value: ColumnType.Boolean,
    label: "Boolean",
  },
  {
    value: ColumnType.Number,
    label: "Number",
  },
  {
    value: ColumnType.String,
    label: "String",
  },
  {
    value: ColumnType.Timestamp,
    label: "Timestamp",
  },
  {
    value: ColumnType.Date,
    label: "Date",
  },
  {
    value: ColumnType.Json,
    label: "Object / Array",
  },
];

export const ColumnSettings: FC<Readonly<Props>> = ({ columns, loading, modelId, orderBy, onSort, isDraft }) => {
  const [search, setSearch] = useState<string>("");
  const { updateResourceOrDraft, draft, setSubmitDraftModalOpen } = useDraft();
  const { addToast } = useToasts();

  const { mutateAsync: updateColumn } = useUpdateModelColumnMutation();

  const onChange = async (option, name: string) => {
    if (!modelId) {
      return;
    }

    const column = columns.find((column) => column.name === name);
    const newColumn = {
      ...column,
      custom_type: option.value,
    };
    const newColumns = columns.map((column) => {
      if (column.name === name) {
        return newColumn;
      }
      return column;
    });

    try {
      updateResourceOrDraft &&
        (await updateResourceOrDraft(
          {
            _set: draft?.new_resource?._set || {},
            modelColumns: newColumns,
          },
          () => undefined,
          async () => {
            await updateColumn({ id: modelId, name, input: { custom_type: option?.value } });
          },
          isDraft,
        ));
    } catch (err) {
      Sentry.captureException(err);
      addToast("Failed to update column", { appearance: "error" });
    }
    setSubmitDraftModalOpen(true);
  };

  const tableColumns: TableColumn[] = [
    {
      key: "name",
      name: "Name",
      sortDirection: orderBy?.name,
      onClick: () => onSort("name"),
    },
    {
      name: "Type",
      sortDirection: orderBy?.type,
      onClick: () => onSort("type"),
      max: "400px",
      cell: ({ name, type, custom_type }) => {
        return (
          <Select
            options={typeOptions}
            tip={custom_type ? `The warehouse type is ${capitalize(type)}.` : undefined}
            value={custom_type || type}
            onChange={(option) => onChange(option, name)}
          />
        );
      },
    },
  ];

  const filteredColumns = columns.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  return (
    <Column sx={{ overflow: "hidden" }}>
      <Row sx={{ alignItems: "center", mb: 4, justifyContent: "space-between" }}>
        <Row sx={{ alignItems: "center" }}>
          <Heading sx={{ mr: 2 }}>Columns</Heading>
          <Badge sx={{ mr: 4 }} variant="base">
            {columns?.length}
          </Badge>
        </Row>
        <SearchInput placeholder="Search columns..." value={search} onChange={setSearch} />
      </Row>
      <Table
        columns={tableColumns}
        data={filteredColumns}
        disabled={({ disable }: { disable: boolean }) => disable}
        loading={loading}
        rowHeight={50}
      />
    </Column>
  );
};
