import { useEffect, useState, FC } from "react";

import { isEqual, isMatch } from "lodash";
import pluralize from "pluralize";
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text } from "theme-ui";

import { DestinationForm } from "src/components/destinations/sync-form";
import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { GitActivity } from "src/components/git/git-activity";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { OverageContentAlert } from "src/components/overage/overage-content-alert";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { Schedule, ScheduleManager } from "src/components/schedule";
import { ScheduleType } from "src/components/schedule/types";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Runs } from "src/components/syncs/runs";
import { SyncAlerts } from "src/components/syncs/sync-alerts";
import { SyncName } from "src/components/syncs/sync-name";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  SyncRunsQuery,
  useDeleteSyncMutation,
  useExternalSegmentsQuery,
  useStartSyncRunMutation,
  useSyncQuery,
  useUpdateSyncMutation,
  useUpdateSyncRequestMutation,
  useSequencesForSyncQuery,
  ResourceToPermission,
  SyncDraft,
  SyncQuery,
} from "src/graphql";
import { useEntitlements } from "src/hooks/use-entitlement";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { SquareBadge } from "src/ui/badge";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { ChevronDownIcon, DotsIcon, ExternalLinkIcon, InfoIcon, PlayIcon, PlusIcon } from "src/ui/icons";
import { Link } from "src/ui/link";
import { PageSpinner, Spinner } from "src/ui/loading";
import { Menu, MenuOption } from "src/ui/menu";
import { Message } from "src/ui/message";
import { Popout } from "src/ui/popout";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { Toggle } from "src/ui/toggle";
import { useDestination } from "src/utils/destinations";
import { QueryType } from "src/utils/models";
import { useSource } from "src/utils/sources";
import { SyncStatus, syncStatusIsTerminal } from "src/utils/syncs";
import { useQueryString } from "src/utils/use-query-string";

import { useLabels } from "../../components/labels/use-labels";
import { WarehouseSyncLogs } from "../../components/syncs/warehouse-sync-logs";

enum Tab {
  RUNS = "Runs",
  CONFIGURATION = "Configuration",
  SCHEDULE = "Schedule",
  ALERTS = "Alerts",
  GIT_ACTIVITY = "Git activity",
  WAREHOUSE_SYNC_LOGS = "Sync logs",
}

const getDeleteSyncErrorMessage = (error: Error): string => {
  return error.message.startsWith("Foreign key violation") && error.message.includes("sync_sequence")
    ? "This sync cannot be deleted because it is used in one or more sequences"
    : error.message;
};

export const SyncWrapper: FC = () => {
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const {
    data: syncData,
    isLoading: syncLoading,
    refetch: refetchSync,
  } = useSyncQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
    },
  );

  if (!id) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={syncData?.syncs?.[0]?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Sync}
    >
      <Sync refetchSync={refetchSync} syncData={syncData} syncLoading={syncLoading} />
    </DraftProvider>
  );
};

interface SyncProps {
  syncData: SyncQuery | undefined;
  syncLoading: boolean;
  refetchSync: () => void;
}

const Sync: FC<SyncProps> = ({ syncData, syncLoading, refetchSync }: SyncProps) => {
  const { user, workspace } = useUser();
  const navigate = useNavigate();
  const { sync_id: id } = useParams<{ sync_id: string }>();
  const { addToast } = useToasts();
  const [schedule, setSchedule] = useState<any>();
  const [cancelling, setCancelling] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.RUNS);
  const [deleting, setDeleting] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(true);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [cancelledSyncId, setCancelledSyncId] = useState<string>();
  const [showRun, setShowRun] = useState(true);
  const [runs, setRuns] = useState<SyncRunsQuery["sync_requests"] | undefined>();

  const { labels } = useLabels();

  const { mutateAsync: updateSync, isLoading: isSyncUpdating } = useUpdateSyncMutation();
  const { mutateAsync: forceRun } = useStartSyncRunMutation();
  const { mutateAsync: cancelSyncRequest } = useUpdateSyncRequestMutation();
  const { mutateAsync: deleteSync } = useDeleteSyncMutation();

  const sync = syncData?.syncs?.[0];
  const latestRun = runs?.filter((run) => run.sync_attempts?.length > 0)[0];
  const syncAttempt = latestRun?.sync_attempts?.[0];
  const syncAlerts = sync?.sync_alerts;
  const model = sync?.segment;
  const usesSyncTemplate = sync?.sync_template_id;
  const currentLabels = sync?.tags ?? {};
  const labelKeys = Object.keys(currentLabels);

  const {
    data: { destination, definition: destinationDefinition },
    loading: destinationLoading,
  } = useDestination(sync?.destination?.id, { pause: !sync });

  const {
    draft,
    editingDraft,
    editingDraftChanges,
    updateResourceOrDraft,
    setSubmitDraftModalOpen,
    setEditingDraft,
    onViewDraft,
  } = useDraft();

  const newResource = draft?.new_resource as SyncDraft;
  const draftSchedule = newResource?._set?.schedule;

  const { data: source, loading: sourceLoading } = useSource(model?.connection?.id, { pause: !sync });

  const { data: externalSegmentsData } = useExternalSegmentsQuery({ syncId: sync?.id }, { enabled: !!sync });
  // We used to support multiple external segments per sync, where only one
  // would be valid at a time. When there is more than one, we only care about
  // the most recent, active one.
  const externalSegment = externalSegmentsData?.external_segments?.sort((a, b) => {
    return a.created_at > b.created_at ? -1 : 1;
  })?.[0];

  const { data: entitlementsData, isLoading: _loadingEntitlements } = useEntitlements(true);
  const overageLockout: boolean = entitlementsData.overage?.overageLockout;
  const overageText = entitlementsData.overage?.destinationOverageText; // @TODO: hookup once there are more than one overage.

  const { data: sequences, isLoading: sequencesLoading } = useSequencesForSyncQuery(
    { syncId: String(id) },
    { enabled: Boolean(id), select: (data) => data.sync_sequences },
  );

  const toggleSyncPause = async (enabled: boolean) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id,
        object: {
          schedule_paused: !enabled,
        },
      });

      addToast(`Sync ${enabled ? "enabled" : "disabled"}!`, {
        appearance: "success",
      });
    } catch (error) {
      addToast(`Sync could not be ${enabled ? "enabled" : "disabled"}. ${error.message}`, { appearance: "error" });
    }

    refetchSync();
  };

  const startRun = async (resync = false) => {
    const { startSyncRun } = await forceRun({
      id: Number(id),
      full_resync: resync,
    });

    // Only render a popup message if we actually scheduled a sync.
    const scheduled = startSyncRun?.scheduled;
    analytics.track("Sync Manually Started", {
      sync_id: id,
      destination_type: destinationDefinition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    if (scheduled) {
      if (resync) {
        addToast("Resync will begin shortly.", {
          appearance: "success",
        });
      } else {
        addToast("Manual run will begin shortly.", {
          appearance: "success",
        });
      }
    }

    refetchSync();
  };

  const cancelRun = async () => {
    setCancelling(true);
    setCancelledSyncId(latestRun?.id);
    cancelSyncRequest({
      id: latestRun?.id,
      object: {
        trigger_cancel: true,
      },
    });

    refetchSync();
  };

  const TABS = [
    Tab.RUNS,
    newResource?._set?.config && editingDraft
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Configuration <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.CONFIGURATION,
        }
      : Tab.CONFIGURATION,
    !usesSyncTemplate &&
      (draftSchedule !== undefined && editingDraft
        ? {
            render: () => (
              <Row sx={{ alignItems: "center" }}>
                Schedule <DraftCircle sx={{ ml: 2 }} />
              </Row>
            ),
            value: Tab.SCHEDULE,
          }
        : Tab.SCHEDULE),
    !usesSyncTemplate && !editingDraftChanges && Tab.ALERTS,
    model?.git_sync_metadata && Tab.GIT_ACTIVITY,
    source?.definition?.supportsInWarehouseDiffing && !editingDraftChanges && Tab.WAREHOUSE_SYNC_LOGS,
  ].filter(Boolean);

  const running = syncAttempt?.status === SyncStatus.ACTIVE;

  const {
    data: { autorun },
  } = useQueryString();

  useEffect(() => {
    let autoRunTimeout: number | undefined | null;
    if (sync && autorun) {
      autoRunTimeout = window.setTimeout(() => {
        startRun();
      }, 400);
    }
    return () => {
      if (autoRunTimeout) {
        clearTimeout(autoRunTimeout);
        autoRunTimeout = null;
      }
    };
  }, [autorun, sync?.id]);

  useEffect(() => {
    if (sync?.schedule || sync?.schedule === null) {
      setSchedule(sync?.schedule ? sync.schedule : { type: ScheduleType.MANUAL });
    }
  }, [sync?.schedule, editingDraft]);

  useEffect(() => {
    setEnabled(!sync?.schedule_paused);
  }, [sync?.schedule_paused]);

  useEffect(() => {
    // if the latest run id no longer match the cancelled id then it means cancel is successful.
    if (syncAttempt?.status === SyncStatus.CANCELLED || cancelledSyncId !== latestRun?.id) {
      setCancelling(false);
    }
  }, [syncAttempt?.status, latestRun?.id, cancelledSyncId]);

  useEffect(() => {
    if (syncStatusIsTerminal(syncAttempt?.status as SyncStatus)) {
      setShowRun(true);
    }
  }, [syncAttempt?.status]);

  useEffect(() => {
    if (draft && editingDraft && draftSchedule !== undefined) {
      setSchedule(draftSchedule ? draftSchedule : { type: ScheduleType.MANUAL });
    }
  }, [editingDraft]);

  const onUpdate = () => {
    analytics.track("Sync Edited", {
      sync_id: id,
      destination_type: destinationDefinition?.name,
      schedule_type: schedule?.type,
      source_type: model?.connection?.type,
    });

    if (!workspace?.approvals_required) {
      addToast("Sync updated successfully!", {
        appearance: "success",
      });
    }

    refetchSync();
  };

  const updateConfig = async (config) => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      config: { ...config, configVersion: sync?.config?.configVersion },
      updated_by: user?.id != null ? String(user?.id) : undefined,
    };

    updateResourceOrDraft &&
      (await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        async () => {
          await updateSync({
            id,
            object: updatePayload,
          });
        },
        sync.draft || false,
      ));
    setSubmitDraftModalOpen(true);
  };

  const updateSchedule = async () => {
    if (!id || !sync) {
      return;
    }

    const updatePayload = {
      updated_by: user?.id != null ? String(user?.id) : undefined,
      schedule: schedule?.type === "manual" ? null : schedule,
    };

    updateResourceOrDraft &&
      (await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        async () => {
          await updateSync({
            id,
            object: updatePayload,
          });
        },
        sync.draft || false,
      ));
    setSubmitDraftModalOpen(true);
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateSync({
        id: id,
        object: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      addToast(`Label update failure. ${error.message}`, { appearance: "error", autoDismiss: false });
    }
  };

  const hasPrimaryKeyIssue =
    model?.query_type === QueryType.Visual
      ? Boolean(model?.parent?.columns?.length && !model?.parent?.columns.some((c) => c.name === model?.parent?.primary_key))
      : Boolean(model?.columns?.length && !model?.columns.some((c) => c.name === model?.primary_key));

  const { hasPermission: userCanStart } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: id },
  ]);
  const { hasPermission: userCanDelete } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Delete], resource_id: id },
  ]);
  const { hasPermission: userCanUpdate } = useHasPermission([
    { resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id },
  ]);

  const loading = syncLoading || destinationLoading || sourceLoading || sequencesLoading;

  if (!loading && !sync) {
    return <Warning subtitle="It may have been deleted" title="Sync not found" />;
  }

  if (loading || !destination || !destinationDefinition) {
    return <PageSpinner />;
  }

  const menuOptions: MenuOption[] = [];

  if (userCanStart) {
    menuOptions.push({
      label: "Resync full query",
      disabled: running,
      onClick: () => {
        setShowRun(false);
        startRun(true);
      },
    });
  }
  if (userCanDelete) {
    menuOptions.push({
      label: "Delete sync",
      disabled: running,
      variant: "danger",
      onClick: () => setDeleting(true),
    });
  }

  // Since when we edit the draft, we set the schedule on the draft instead of the
  // actual resource, we need to check if there is a draft when we are setting the schedule
  // to the manual schedule type.
  const scheduleSaveDisabled =
    isEqual(schedule, sync?.schedule) ||
    (sync?.schedule === null && isMatch(schedule, { type: ScheduleType.MANUAL }) && !draft) ||
    (editingDraft && isEqual(draftSchedule, schedule));

  return (
    <>
      <PermissionProvider permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={[
            { label: "Syncs", link: "/syncs" },
            {
              label: "Sync",
            },
          ]}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Sync}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          sync={sync}
        >
          <Row sx={{ justifyContent: "space-between", mb: 5, width: "100%", borderBottom: "small", pb: 2 }}>
            <SyncName
              destination={destination}
              destinationDefinition={destinationDefinition}
              model={model}
              source={source}
              sync={sync}
            />
            <Row gap={4}>
              <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Enable], resource_id: id }]}>
                <Toggle
                  label={enabled ? "Enabled" : "Disabled"}
                  value={enabled}
                  onChange={(value) => {
                    setEnabled(value);
                    toggleSyncPause(value);
                  }}
                />
              </Permission>
              {menuOptions.length ? (
                <Menu options={menuOptions}>
                  <DotsIcon />
                </Menu>
              ) : null}
              <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Start], resource_id: id }]}>
                {running || cancelling ? (
                  <Button
                    disabled={cancelling}
                    iconBefore={<Spinner color={cancelling ? "primary" : "red"} size={14} />}
                    variant={cancelling ? "secondary" : "redOutline"}
                    onClick={cancelRun}
                  >
                    {cancelling ? "Canceling..." : "Cancel run"}
                  </Button>
                ) : (
                  <Button
                    disabled={!showRun || overageLockout || !!sync?.draft}
                    iconBefore={<PlayIcon size={14} />}
                    tooltip={overageLockout ? overageText : undefined}
                    variant="secondary"
                    onClick={() => {
                      setShowRun(false);
                      startRun(false);
                    }}
                  >
                    Run
                  </Button>
                )}
              </Permission>
            </Row>
          </Row>

          <>
            <Row gap={8} sx={{ alignSelf: "flex-start", alignItems: "flex-start", mb: 6 }}>
              <Column sx={{ pr: 10 }}>
                <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>
                  Sync ID
                </Text>
                <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{sync?.id}</Text>
              </Column>
              <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
                <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>
                  Schedule
                </Text>
                <Schedule schedule={sync?.schedule} />
              </Column>
              {model?.query_type !== QueryType.Visual && (
                <Column sx={{ pl: 3, pr: 10, borderLeft: "small" }}>
                  <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Slug</Text>
                  <DisplaySlug currentSlug={sync?.slug} />
                </Column>
              )}
              {sync?.config?.mode && (
                <Column sx={{ pl: 3, borderLeft: "small" }}>
                  <Text sx={{ fontSize: 0, textTransform: "uppercase", color: "base.4", fontWeight: "bold", mb: 1 }}>Mode</Text>
                  <Text sx={{ textTransform: "capitalize", fontWeight: "semi" }}>{sync?.config?.mode}</Text>
                </Column>
              )}
              {!editingDraftChanges &&
                (labelKeys.length > 0 ? (
                  <Row sx={{ alignItems: "center", pl: 4, ml: 4, height: "100%", borderLeft: "small" }}>
                    <Popout
                      content={({ close }) => (
                        <>
                          <Labels labels={sync?.tags} />
                          <Permission
                            permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                          >
                            <Button
                              sx={{ mt: 4 }}
                              variant="secondary"
                              onClick={() => {
                                setIsEditLabelModalOpen(true);
                                close();
                              }}
                            >
                              Edit labels
                            </Button>
                          </Permission>
                        </>
                      )}
                      contentSx={{ p: 3, minWidth: "90px" }}
                    >
                      <Text sx={{ mr: 1 }}>Labels</Text>
                      <SquareBadge>{Object.keys(sync?.tags || {}).length}</SquareBadge>
                      <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                    </Popout>
                  </Row>
                ) : (
                  <Row sx={{ pl: 4, ml: 4, borderLeft: "small" }}>
                    <Button
                      iconBefore={<PlusIcon color="base.5" size={14} />}
                      size="small"
                      variant="secondary"
                      onClick={() => {
                        setIsEditLabelModalOpen(true);
                      }}
                    >
                      Add labels
                    </Button>
                  </Row>
                ))}
            </Row>

            <Grid gap={8} sx={{ width: "100%" }}>
              <Tabs setTab={(tab) => setTab(tab as Tab)} tab={tab} tabs={TABS} />
              <>
                {tab === Tab.RUNS && (
                  <>
                    {hasPrimaryKeyIssue && (
                      <Message sx={{ width: "100%", maxWidth: "100%" }} variant="warning">
                        <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                          <Field label="Looks like your primary key is set to an undefined column.">
                            As a result, your syncs may fail or undefined behavior may occur. Go to your model and make sure you
                            have your primary key set to a valid column in your model.
                          </Field>
                          <Column sx={{ flexShrink: 0, px: 2 }}>
                            <Link
                              to={
                                model?.query_type === QueryType.Visual
                                  ? `/audiences/setup/parent-models/${model?.parent?.id}`
                                  : `/models/${model?.id}`
                              }
                            >
                              <Button variant="dark">Go to your model</Button>
                            </Link>
                          </Column>
                        </Row>
                      </Message>
                    )}

                    {id && <Runs setRuns={setRuns} syncId={id} />}
                  </>
                )}

                {tab === Tab.CONFIGURATION && overageLockout && <OverageContentAlert />}
                {tab === Tab.CONFIGURATION && !overageLockout && !usesSyncTemplate && model && source?.definition && (
                  <PermissionProvider
                    permissions={
                      workspace?.approvals_required
                        ? [] // when the workspace enables approvals, anyone can submit a draft.
                        : [{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]
                    }
                  >
                    <DestinationForm
                      destination={destination}
                      destinationDefinition={destinationDefinition}
                      externalSegment={externalSegment}
                      model={model}
                      slug={destinationDefinition.type}
                      sourceDefinition={source.definition}
                      sync={sync}
                      onSubmit={updateConfig}
                    />
                  </PermissionProvider>
                )}
                {tab === Tab.CONFIGURATION && !overageLockout && usesSyncTemplate && (
                  <Row
                    sx={{
                      mt: -4,
                      justifyContent: "space-between",
                      alignItems: "center",
                      maxWidth: "800px",
                      p: 4,
                      borderRadius: 1,
                      bg: "base.1",
                      color: "base.7",
                      fontWeight: "semi",
                    }}
                  >
                    <Row sx={{ alignItems: "center" }}>
                      <InfoIcon />
                      <Text sx={{ ml: 3 }}>This sync uses a shared configuration template</Text>
                    </Row>
                    <Row sx={{ alignItems: "center" }}>
                      <Link
                        sx={{ display: "flex", color: "inherit", alignItems: "center" }}
                        to={`/audiences/setup/sync-templates/${sync?.sync_template_id}`}
                      >
                        <Text sx={{ mr: 4 }}>Edit the template</Text>
                        <ExternalLinkIcon color="base.4" size={14} />
                      </Link>
                    </Row>
                  </Row>
                )}

                {tab === Tab.SCHEDULE && overageLockout && <OverageContentAlert />}
                {tab === Tab.SCHEDULE && !overageLockout && (
                  <PermissionProvider
                    permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Update], resource_id: id }]}
                  >
                    <Row sx={{ width: "100%", justifyContent: "space-between" }}>
                      <Column mr={8} sx={{ width: "100%" }}>
                        {sequences && sequences.length > 0 && (
                          <Column>
                            <Field
                              description={`This sync is currently being run by ${sequences.length} ${pluralize(
                                "sequence",
                                sequences.length > 1,
                              )}`}
                              label="Sequences"
                              size="large"
                            >
                              <Table
                                columns={[
                                  { name: "Name", key: "name" },
                                  {
                                    name: "Syncs",
                                    cell: ({ members }) => `${members.length} ${pluralize("sync", members.length > 1)}`,
                                  },
                                  {
                                    cell: () => (
                                      <ExternalLinkIcon color="base.4" sx={{ display: "flex", justifyContent: "flex-end" }} />
                                    ),
                                  },
                                ]}
                                data={sequences}
                                showHeaders={false}
                                onRowClick={({ id }) => navigate(`/sequences/${id}`)}
                              />
                            </Field>
                          </Column>
                        )}
                        <ScheduleManager schedule={schedule} setSchedule={setSchedule} />
                        <Message sx={{ mt: 12, maxWidth: "100%" }}>
                          <Text>
                            You can also trigger this sync via Airflow. The ID for this sync is: <strong>{id}</strong>.
                          </Text>
                          <Text sx={{ mt: 3 }}>
                            For more information view our{" "}
                            <Link newTab to={`${import.meta.env.VITE_DOCS_URL}/integrations/airflow`}>
                              Airflow Operator docs
                            </Link>
                            . If you need an API key you can create one <Link to="/settings/api-keys">here.</Link>
                          </Text>
                        </Message>
                      </Column>
                      <SidebarForm
                        hideInviteTeammate
                        hideSendMessage
                        buttons={[
                          userCanUpdate ? (
                            <Button disabled={scheduleSaveDisabled} sx={{ width: "100%" }} onClick={() => updateSchedule()}>
                              {workspace?.approvals_required ? "Save draft" : "Save"}
                            </Button>
                          ) : null,
                        ]}
                        docsUrl={`${import.meta.env.VITE_DOCS_URL}/syncs/schedule-sync-ui/`}
                        invite="If you need help setting up this sync"
                        name="scheduling syncs"
                      />
                    </Row>
                  </PermissionProvider>
                )}

                {tab === Tab.ALERTS && (
                  <SyncAlerts
                    alerts={syncAlerts}
                    rowThresholdAttempted={sync?.row_threshold_attempted}
                    rowThresholdTotal={sync?.row_threshold_total}
                    syncId={id}
                  />
                )}

                {tab === Tab.GIT_ACTIVITY && <GitActivity id={id} />}

                {tab === Tab.WAREHOUSE_SYNC_LOGS && (
                  <WarehouseSyncLogs
                    config={sync?.warehouse_history_config}
                    id={id}
                    source={source ?? undefined}
                    userId={user?.id != null ? String(user?.id) : undefined}
                  />
                )}
              </>
            </Grid>
          </>
        </Page>
      </PermissionProvider>

      <EditLabels
        description="You can label syncs that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels}
        loading={isSyncUpdating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <DeleteConfirmationModal
        isOpen={deleting}
        label="sync"
        onClose={() => {
          setDeleting(false);
        }}
        onDelete={async () => {
          if (!id) {
            return;
          }

          await deleteSync(
            {
              id,
            },
            {
              onSuccess: () => {
                analytics.track("Sync Deleted", {
                  sync_id: id,
                  destination_type: destinationDefinition?.name,
                  schedule_type: schedule?.type,
                  source_type: model?.connection?.type,
                });
              },
              onError: (error) => {
                addToast(getDeleteSyncErrorMessage(error), { appearance: "error", autoDismiss: false });
              },
            },
          );

          navigate("/syncs");
        }}
      />
    </>
  );
};
