export interface IntervalValue {
  interval: IntervalUnit;
  quantity: number;
}

export enum IntervalUnit {
  Minute = "minute",
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
  Year = "year",
}

export function isIntervalType(interval: unknown): interval is IntervalValue {
  return (
    typeof interval === "object" &&
    interval != null &&
    (interval as IntervalValue).interval != null &&
    (interval as IntervalValue).quantity != null
  );
}
