import { FC } from "react";

import { Grid } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { DeleteField } from "../delete-field";
import { MappingsField } from "../mappings-field";
import { ModeField } from "../mode-field";
import { ObjectField } from "../object-field";
import { TypeField } from "../type-field";

export const validation = Yup.object().shape({
  type: Yup.string().required().default("event"),
  object: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("user"),
    otherwise: Yup.string().notRequired(),
  }),
  mode: Yup.string().when("type", {
    is: "object",
    then: Yup.string().required().default("upsert"),
    otherwise: Yup.string().notRequired(),
  }),

  eventType: Yup.string().when("type", {
    is: "event",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  eventName: Yup.string().when("eventType", {
    is: "custom_event",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  customEventType: Yup.string().when("eventType", {
    is: "custom_event",
    then: Yup.string().required(),
    otherwise: Yup.string().notRequired(),
  }),
  timestampFrom: Yup.mixed().notRequired(),
  isDev: Yup.boolean().notRequired(),

  externalIdMappings: Yup.array().of(COMMON_SCHEMAS.externalIdMapping).min(1),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  deleteMode: Yup.string().notRequired(),
});

export const MParticleForm: FC = () => {
  const { hightouchColumns, config, setConfig, errors } = useDestinationForm();

  const TYPES = [
    { label: "Event", value: "event" },
    { label: "Object", value: "object" },
  ];
  const EXTERNAL_FIELDS = [
    { label: "user_identities.customer_id", value: "user_identities.customer_id" },
    { label: "user_identities.email", value: "user_identities.email" },
    { label: "user_identities.other", value: "user_identities.other" },
    { label: "user_identities.other_id_2", value: "user_identities.other_id_2" },
    { label: "user_identities.other_id_3", value: "user_identities.other_id_3" },
    { label: "user_identities.other_id_4", value: "user_identities.other_id_4" },
    { label: "user_identities.other_id_5", value: "user_identities.other_id_5" },
    { label: "user_identities.other_id_6", value: "user_identities.other_id_6" },
    { label: "user_identities.other_id_7", value: "user_identities.other_id_7" },
    { label: "user_identities.other_id_8", value: "user_identities.other_id_8" },
    { label: "user_identities.other_id_9", value: "user_identities.other_id_9" },
    { label: "user_identities.other_id_10", value: "user_identities.other_id_10" },
    { label: "user_identities.mobile_number", value: "user_identities.mobile_number" },
    { label: "user_identities.phone_number_2", value: "user_identities.phone_number_2" },
    { label: "user_identities.phone_number_3", value: "user_identities.phone_number_3" },
    { label: "user_identities.facebook", value: "user_identities.facebook" },
    { label: "user_identities.google", value: "user_identities.google" },
    { label: "user_identities.twitter", value: "user_identities.twitter" },
    { label: "user_identities.microsoft", value: "user_identities.microsoft" },
    { label: "user_identities.yahoo", value: "user_identities.yahoo" },
    { label: "device_info.ios_advertising_id", value: "device_info.ios_advertising_id" },
    { label: "device_info.ios_idfv", value: "device_info.ios_idfv" },
    { label: "device_info.android_advertising_id", value: "device_info.android_advertising_id" },
    { label: "device_info.roku_advertising_id", value: "device_info.roku_advertising_id" },
    { label: "device_info.roku_publisher_id", value: "device_info.roku_publisher_id" },
    { label: "device_info.microsoft_advertising_id", value: "device_info.microsoft_advertising_id" },
    { label: "device_info.microsoft_publisher_id", value: "device_info.microsoft_publisher_id" },
    { label: "device_info.fire_advertising_id", value: "device_info.fire_advertising_id" },
    { label: "mpid", value: "mpid" },
  ];

  const COMMON_FIELDS = [
    { label: "application_info", value: "__application_info", type: StandardFieldType.OBJECT },
    { label: "custom_flags", value: "__custom_flags", type: StandardFieldType.OBJECT },
    { label: "ip", value: "__ip", type: StandardFieldType.STRING },
    { label: "context", value: "__context", type: StandardFieldType.OBJECT },
  ];

  const EVENT_BASE_FIELDS = [
    { label: "source_message_id", value: "source_message_id", type: StandardFieldType.STRING },
    { label: "session_uuid", value: "session_uuid", type: StandardFieldType.STRING },
    { label: "timestamp_unixtime_ms", value: "timestamp_unixtime_ms", type: StandardFieldType.NUMBER },
    { label: "location", value: "location", type: StandardFieldType.OBJECT },
    { label: "device_current_state", value: "device_current_state", type: StandardFieldType.OBJECT },
    ...COMMON_FIELDS,
  ];

  const EVENT_FIELDS = {
    custom_event: [
      ...EVENT_BASE_FIELDS,
      { label: "custom_event_type", value: "custom_event_type", type: StandardFieldType.STRING },
      { label: "custom_flags", value: "custom_flags", type: StandardFieldType.OBJECT },
      { label: "event_name", value: "event_name", type: StandardFieldType.STRING },
    ],
    commerce_event: [
      ...EVENT_BASE_FIELDS,
      { label: "product_action", value: "product_action", type: StandardFieldType.OBJECT },
      { label: "promotion_action", value: "promotion_action", type: StandardFieldType.OBJECT },
      { label: "product_impressions", value: "product_impressions", type: StandardFieldType.ARRAY },
      { label: "shopping_cart", value: "shopping_cart", type: StandardFieldType.OBJECT },
      { label: "currency_code", value: "currency_code", type: StandardFieldType.STRING },
      { label: "screen_name", value: "screen_name", type: StandardFieldType.STRING },
      { label: "is_non_interactive", value: "is_non_interactive", type: StandardFieldType.BOOLEAN },
    ],
    // session_start: [...EVENT_BASE_FIELDS],
    // session_end: [
    //   ...EVENT_BASE_FIELDS,
    //   { label: "session_duration_ms", value: "session_duration_ms", type: StandardFieldType.NUMBER },
    // ],
    // uninstall: [...EVENT_BASE_FIELDS],
    // opt_out: [...EVENT_BASE_FIELDS, { label: "is_opted_out", value: "is_opted_out", type: StandardFieldType.BOOLEAN }],
  };

  const EVENTS = Object.keys(EVENT_FIELDS)?.map((f) => ({ label: f, value: f }));

  const CUSTOM_EVENT_TYPES = [
    { label: "attribution", value: "attribution" },
    { label: "location", value: "location" },
    { label: "media", value: "media" },
    { label: "navigation", value: "navigation" },
    { label: "search", value: "search" },
    { label: "social", value: "social" },
    { label: "transaction", value: "transaction" },
    { label: "user_content", value: "user_content" },
    { label: "user_preference", value: "user_preference" },
    { label: "other", value: "other" },
  ];

  const USER_FIELDS = [
    { label: "age", value: "$age" },
    { label: "gender", value: "$gender" },
    { label: "country", value: "$country" },
    { label: "zip", value: "$zip" },
    { label: "city", value: "$city" },
    { label: "state", value: "$state" },
    { label: "address", value: "$address" },
    { label: "firstname", value: "$firstname" },
    { label: "lastname", value: "$lastname" },
    { label: "mobile", value: "$mobile" },
    ...(config?.type === "object" ? COMMON_FIELDS : []),
  ];

  const OBJECTS = [{ label: "User", value: "user" }];

  const OBJECT_MODES = [{ label: "Upsert", value: "upsert" }];

  const EVENT_MODES = [{ label: "Insert", value: "insert" }];

  return (
    <>
      <TypeField options={TYPES} />

      {config?.type === "object" && (
        <ObjectField options={OBJECTS} onChange={(object) => setConfig({ type: config?.type, object })} />
      )}

      {config?.object && <ModeField options={OBJECT_MODES} value={"upsert"} />}

      {config?.type === "event" && <ModeField options={EVENT_MODES} value={"insert"} />}

      {config?.type === "event" && (
        <Section>
          <Field error={errors?.eventType} label="Which event type would you like to send?" size="large">
            <Select
              isError={errors?.eventType}
              options={EVENTS}
              placeholder="Select event type..."
              value={EVENTS?.find((o) => o.value === config?.eventType) || null}
              width="240px"
              onChange={(selected) => {
                const val = selected.value;
                setConfig({ type: config?.type, object: config?.object, mode: config?.mode, eventType: val });
              }}
            />
          </Field>
        </Section>
      )}

      {((config?.type === "event" && config?.eventType) || config?.object) && (
        <Section>
          <Grid gap={8}>
            {config?.type === "event" && (
              <>
                {config?.eventType === "custom_event" && (
                  <>
                    <Field
                      description={"Mapping an event_name in the field mappings will overwrite this value."}
                      error={errors?.eventName}
                      label="What is the event name?"
                      size="large"
                    >
                      <Input
                        defaultValue={config?.eventName}
                        error={errors?.eventName}
                        placeholder="Enter event name..."
                        sx={{ width: "240px" }}
                        onChange={(value) => {
                          setConfig({
                            ...config,
                            eventName: value,
                          });
                        }}
                      />
                    </Field>
                    <Field
                      description={"Mapping an custom_event_type in the field mappings will overwrite this value."}
                      error={errors?.customEventType}
                      label="Which type of custom event would you like to send?"
                      size="large"
                    >
                      <Select
                        isError={errors?.customEventType}
                        options={CUSTOM_EVENT_TYPES}
                        placeholder="Select event type..."
                        value={CUSTOM_EVENT_TYPES?.find((o) => o.value === config?.customEventType) || null}
                        width="240px"
                        onChange={(selected) => {
                          const val = selected.value;
                          setConfig({ ...config, customEventType: val });
                        }}
                      />
                    </Field>
                  </>
                )}
                <Field
                  optional
                  description="If this is empty, mParticle will use the time the event arrives at the server. Mapping a timestamp_unixtime_ms in the field mappings will overwrite this field."
                  error={errors?.timestampFrom}
                  label="Which column contains the event timestamp?"
                  size="large"
                >
                  <Select
                    isClearable
                    isError={errors?.timestampFrom}
                    options={hightouchColumns}
                    placeholder="Select a column..."
                    value={config?.timestampFrom}
                    width="240px"
                    onChange={(selected) => {
                      const val = selected?.value;
                      setConfig({ ...config, timestampFrom: val });
                    }}
                  />
                </Field>
              </>
            )}
            <MappingsField
              hideIdMappings
              required
              options={EXTERNAL_FIELDS}
              path={[config?.type]}
              property="externalIdMappings"
            />
          </Grid>
        </Section>
      )}

      {(config?.object || (config?.type === "event" && config?.eventType)) && (
        <>
          <Section>
            <MappingsField
              hideIdMappings
              options={config?.object ? USER_FIELDS : EVENT_FIELDS[config?.eventType] || []}
              path={[config?.type]}
            />
          </Section>

          <Section>
            <MappingsField hideIdMappings isCustom path={[config?.type]} />
          </Section>
          {config?.type === "event" && (
            <>
              <Section>
                <MappingsField options={USER_FIELDS || []} path={[config?.type]} property="userMappings" />
              </Section>

              <Section>
                <MappingsField isCustom path={[config?.type]} property="customUserMappings" />
              </Section>
            </>
          )}

          <Section>
            <Field error={errors?.isDev} label="Which environment would you like to send the data to?" size="large">
              <RadioGroup
                options={[
                  { label: "Production", value: undefined },
                  {
                    label: "Development",
                    value: true,
                  },
                ]}
                value={config?.isDev}
                onChange={(isDev) => setConfig({ ...config, isDev })}
              />
            </Field>
          </Section>
        </>
      )}

      {config?.type === "object" && <DeleteField modes={["clear"]} />}
    </>
  );
};

export default { form: MParticleForm, validation };
