import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ExtensionsIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <g>
        <path d="M11.1,16.07v1.55c0,.5,.4,.9,.9,.9s.9-.4,.9-.9v-1.55c0-.5-.4-.9-.9-.9s-.9,.4-.9,.9Z" />
        <path d="M9.86,15.07c-.12,.44-.54,.71-.99,.65-3.06-.43-5.99-1.51-8.46-3.15-.25-.17-.4-.45-.4-.75V7.67C0,5.15,2.06,3.1,4.58,3.1h2.36c.23-1.74,1.7-3.1,3.51-3.1h3.1c1.8,0,3.28,1.36,3.51,3.1h2.37c2.52,0,4.57,2.05,4.57,4.57v4.16c0,.3-.15,.58-.4,.75-2.48,1.64-5.41,2.73-8.5,3.16-.04,0-.08,0-.12,0-.41,0-.77-.27-.87-.68-.24-.96-1.11-1.62-2.11-1.62s-1.87,.65-2.14,1.63ZM13.55,1.8h-3.1c-.81,0-1.48,.55-1.68,1.3h6.47c-.2-.75-.88-1.3-1.68-1.3Z" />
      </g>
      <path
        d="M23.6,12.58c-2.48,1.64-5.41,2.73-8.5,3.16-.04,0-.08,0-.12,0-.41,0-.77-.27-.87-.68-.24-.96-1.11-1.62-2.11-1.62s-1.87,.65-2.14,1.63c-.12,.44-.54,.71-.99,.65-3.06-.43-5.99-1.51-8.46-3.15-.22-.15-.35-.39-.38-.65,.07,2.82,.21,6.64,.23,7.11,.09,1.13,.52,2.28,1.19,3.08,.94,1.13,2.1,1.64,3.71,1.64,2.23,.01,4.68,.01,7.07,.01s4.72,0,6.68-.01c1.59,0,2.78-.52,3.71-1.64,.67-.81,1.11-1.96,1.17-3.08,.02-.38,.11-4.31,.18-6.97-.06,.21-.19,.4-.37,.52Zm-10.7,5.04c0,.5-.4,.9-.9,.9s-.9-.4-.9-.9v-1.55c0-.5,.4-.9,.9-.9s.9,.4,.9,.9v1.55Z"
        opacity={0.4}
      />
    </Icon>
  );
};
