import { FC, useEffect, useState } from "react";

import { capitalize } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Text, Grid, Flex } from "theme-ui";

import { ColumnSettings } from "src/components/audiences/column-settings";
import { ParentTraits } from "src/components/audiences/parent-traits";
import { Relationships } from "src/components/audiences/relationships";
import { useObject } from "src/components/audiences/use-object";
import { Page } from "src/components/layout";
import { SaveWarning } from "src/components/modals/save-warning";
import { ColumnSelect } from "src/components/models/column-select";
import { Query } from "src/components/models/query";
import { Permission } from "src/components/permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useDeleteObjectMutation,
  useObjectQuery,
  useUpdateEventMutation,
  useUpdateObjectMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { LookerIcon } from "src/ui/icons";
import { InlineInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import Logo from "src/ui/Logo";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { Tabs } from "src/ui/tabs";
import { QueryType, useModelState } from "src/utils/models";
import { useSource } from "src/utils/sources";
import { formatDate } from "src/utils/time";

enum Tab {
  QUERY = "Query",
  CONFIGURATION = "Configuration",
  COLUMNS = "Columns",
  RELATIONSHIPS = "Relationships",
  TRAITS = "Traits",
}

export const AudienceObject: FC = () => {
  const { audience_id: id } = useParams<{ audience_id?: string }>();
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { user } = useUser();
  const { objectNameCapitalized, objectNameSingular, objectPath, isEvent, isParentModel } = useObject();
  const { modelState, setPrimaryKey, setTimestampColumn, initModelState } = useModelState();

  const [updating, setUpdating] = useState<boolean>(false);
  const [deleteQuery, setDeleteQuery] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.QUERY);
  const [primaryLabel, setPrimaryLabel] = useState<string | undefined | null>("");
  const [secondaryLabel, setSecondaryLabel] = useState<string | undefined | null>("");

  const { data: objectData, isLoading: objectLoading } = useObjectQuery(
    {
      id: id ?? "",
    },
    { enabled: Boolean(id), refetchInterval: tab === Tab.COLUMNS ? 10000 : undefined, notifyOnChangeProps: "tracked" },
  );

  const object = objectData?.segments_by_pk;
  const type = object?.query_type;
  const columns = object?.columns;

  const { data: source, loading: sourceLoading } = useSource(object?.connection?.id);

  const loading = sourceLoading || objectLoading;

  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: updateEvent } = useUpdateEventMutation();
  const { isLoading: deleting, mutateAsync: deleteObject } = useDeleteObjectMutation();

  const onUpdate = () => {
    analytics.track("Model Updated", {
      model_id: object?.id,
      model_type: type,
      model_name: object?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    addToast(`${objectNameCapitalized} updated!`, {
      appearance: "success",
    });
  };

  const saveName = async (name: string) => {
    if (!id) {
      return;
    }

    await updateObject({
      id,
      input: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const updateConfiguration = async () => {
    if (!id) {
      return;
    }

    setUpdating(true);

    await updateObject({
      id,
      input: {
        primary_key: modelState?.primaryKey,
        updated_by: user?.id != null ? String(user?.id) : undefined,
        visual_query_primary_label: primaryLabel,
        visual_query_secondary_label: secondaryLabel,
      },
    });

    if (isEvent) {
      await updateEvent({
        id,
        set: {
          timestamp_column: modelState?.timestampColumn,
        },
      });
    }

    setUpdating(false);

    onUpdate();
  };

  const dirty =
    modelState?.name !== object?.name ||
    modelState?.primaryKey !== object?.primary_key ||
    modelState?.timestampColumn !== object?.event?.timestamp_column ||
    primaryLabel !== object?.visual_query_primary_label ||
    secondaryLabel !== object?.visual_query_secondary_label;

  useEffect(() => {
    initModelState(object);
    setPrimaryLabel(object?.visual_query_primary_label);
    setSecondaryLabel(object?.visual_query_secondary_label);
  }, [object]);

  const hasPrimaryKeyIssue = Boolean(
    isParentModel && object?.columns?.length && !object?.columns.some((c) => c.name === object.primary_key),
  );

  if (loading || !source || !object) {
    return <PageSpinner />;
  }

  const TABS = isParentModel
    ? [Tab.QUERY, Tab.CONFIGURATION, Tab.RELATIONSHIPS, Tab.TRAITS, Tab.COLUMNS]
    : isEvent
    ? [Tab.QUERY, Tab.CONFIGURATION, Tab.RELATIONSHIPS, Tab.COLUMNS]
    : [Tab.QUERY, Tab.RELATIONSHIPS, Tab.COLUMNS];

  const objectCrumb = (
    <Flex>
      {isEvent ? objectNameCapitalized : capitalize(objectNameSingular)}:&nbsp;
      <Text style={{ color: "#4B5563" }}>{modelState.name}</Text>
    </Flex>
  );

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]}>
        <Page
          crumbs={[
            {
              label: "Audiences",
              link: "/audiences",
            },
            {
              label: "Setup",
              link: `/audiences/setup/${objectPath}`,
            },
            {
              label: objectCrumb,
            },
          ]}
        >
          <Row sx={{ width: "100%", flex: 1 }}>
            <Column sx={{ mr: 12, flex: 1 }}>
              <Column sx={{ mb: 6 }}>
                <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                  <InlineInput value={modelState.name} onChange={saveName} />
                  <Row gap={3} sx={{ ml: 8 }}>
                    <Permission permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Delete] }]}>
                      <Button
                        label="Delete"
                        variant="secondary"
                        onClick={() => {
                          setDeleteQuery(true);
                        }}
                      />
                    </Permission>
                  </Row>
                </Row>
                <Row sx={{ alignItems: "center", mt: 2 }}>
                  <Row sx={{ alignItems: "center", pr: 4, mr: 4, borderRight: "small" }}>
                    <Logo logoUrl={source.definition?.icon} name={source.definition?.name ?? ""} />
                    <Text sx={{ ml: 2, fontWeight: "bold", color: "base.8" }}>{source.name}</Text>
                  </Row>
                  <Row sx={{ alignItems: "center" }}>
                    <Field inline label="Last updated:" labelSx={{ width: "max-content" }}>
                      <Text>
                        {formatDate(object.updated_at || object.created_at)} by{" "}
                        <Text sx={{ fontWeight: "semi", display: "inline-block" }}>
                          {object.updated_by_user?.name || object.created_by_user?.name}
                        </Text>
                      </Text>
                    </Field>
                  </Row>
                  <Row sx={{ borderLeft: "small", ml: 4, pl: 4, alignItems: "center" }}>
                    <Text sx={{ mr: 1, color: "base.7" }}>Slug:</Text>
                    <DisplaySlug currentSlug={object.slug} />
                  </Row>
                </Row>
              </Column>

              <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

              {hasPrimaryKeyIssue && (
                <Message sx={{ width: "100%", maxWidth: "100%", mb: 8 }} variant="warning">
                  <Field label="Looks like your primary key is set to an undefined column.">
                    As a result, your syncs may fail or undefined behavior may occur, go to the Configuration tab to select a
                    new primary key.
                  </Field>
                </Message>
              )}

              {tab === Tab.QUERY && (
                <Column sx={{ gap: 6 }}>
                  <Row sx={{ alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                    <Heading>
                      <Row>
                        {type === QueryType.Dbt || type === QueryType.DbtModel
                          ? "dbt Model"
                          : type === QueryType.LookerLook
                          ? "This model is based on a Look"
                          : "Query"}
                        {type === QueryType.LookerLook && <LookerIcon size={24} />}
                      </Row>
                    </Heading>
                    <Permission>
                      <Button variant="secondary" onClick={() => navigate(`/audiences/setup/${objectPath}/${id}/query`)}>
                        Edit
                      </Button>
                    </Permission>
                  </Row>
                  <Query model={object} />
                </Column>
              )}

              {tab === Tab.CONFIGURATION && (
                <Grid gap={6} sx={{ maxWidth: "500px" }}>
                  <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                    <Heading>Configuration</Heading>
                    <Permission>
                      <Button disabled={!dirty} label="Save" loading={updating} onClick={updateConfiguration} />
                    </Permission>
                  </Row>

                  {isEvent && (
                    <Field label="Timestamp">
                      <ColumnSelect model={object} value={modelState.timestampColumn} onChange={setTimestampColumn} />
                    </Field>
                  )}

                  {isParentModel && (
                    <>
                      <Field label="Primary key">
                        <ColumnSelect model={object} value={modelState.primaryKey} onChange={setPrimaryKey} />
                      </Field>
                      <Field label="Primary label">
                        <ColumnSelect model={object} value={primaryLabel ?? ""} onChange={setPrimaryLabel} />
                      </Field>
                      <Field label="Secondary label">
                        <ColumnSelect model={object} value={secondaryLabel ?? ""} onChange={setSecondaryLabel} />
                      </Field>
                    </>
                  )}
                </Grid>
              )}

              {tab === Tab.RELATIONSHIPS && <Relationships model={object} />}

              {tab === Tab.TRAITS && <ParentTraits model={object} />}

              {tab === Tab.COLUMNS && <ColumnSettings columns={columns} modelId={object?.id} source={source} />}
            </Column>
          </Row>
        </Page>
      </PermissionProvider>

      <Modal
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteQuery(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={deleting}
              variant="red"
              onClick={async () => {
                if (!id) {
                  return;
                }

                await deleteObject({
                  id,
                });

                analytics.track("Model Deleted", {
                  model_id: object?.id,
                  model_type: type,
                  model_name: object?.name,
                  source_id: source?.id,
                  source_type: source?.type,
                });

                addToast(`${capitalize(objectNameSingular)} deleted successfully!`, {
                  appearance: "success",
                });

                navigate(`/audiences/setup/${objectPath}`);
              }}
            >
              Delete
            </Button>
          </>
        }
        isOpen={deleteQuery}
        sx={{ width: "500px" }}
        title={`Delete ${objectNameSingular}`}
        onClose={() => {
          setDeleteQuery(false);
        }}
      >
        <Text>Are you sure you want to delete this {objectNameSingular}? You won't be able to undo this.</Text>
      </Modal>
      <SaveWarning dirty={dirty && !deleteQuery} />
    </>
  );
};
