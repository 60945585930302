import { MultiSelect, Spinner, Select, Text, Link, FormField } from "@hightouchio/ui";

import {
  useGetFivetranExtensionQuery,
  useFivetranSyncResourcesQuery,
  useFivetranGroupsQuery,
  FivetranSyncTrigger,
} from "src/graphql";

import { ScheduleManagerProps } from "./schedule-manager";
import { FivetranSchedule } from "./types";

interface FivetranScheduleProps extends ScheduleManagerProps {
  schedule: FivetranSchedule;
}

export const ConfigureFivetranSchedule = ({ schedule, setSchedule }: FivetranScheduleProps) => {
  const { data: fivetranCredentials, isLoading: loadingCredentials } = useGetFivetranExtensionQuery(undefined, {
    select: (data) => data.fivetran_credentials[0],
  });

  const { isLoading: loadingGroups, data: fivetranGroups } = useFivetranGroupsQuery(undefined, {
    enabled: Boolean(fivetranCredentials),
    select: (data) => data.getFivetranGroups,
  });

  const { isLoading: loadingGroupResources, data: groupResources } = useFivetranSyncResourcesQuery(
    {
      groupId: schedule.schedule?.groupId || "",
    },
    { enabled: Boolean(schedule.schedule?.groupId), select: (data) => data.getFivetranResources || [] },
  );

  if (loadingCredentials) {
    return <Spinner />;
  }
  if (!fivetranCredentials) {
    return (
      <Text>
        Please <Link href="/extensions/fivetran">Connect your Fivetran account </Link> before continuing.
      </Text>
    );
  }
  return (
    <>
      <FormField label="Fivetran workspace">
        <Select
          isLoading={loadingGroups}
          optionLabel={(group) => group.name}
          optionValue={(group) => group.id}
          options={fivetranGroups || []}
          placeholder="Select a workspace…"
          value={schedule.schedule?.groupId ?? undefined}
          onChange={(selected) =>
            selected &&
            setSchedule({
              ...schedule,
              schedule: {
                groupId: selected,
                groupName: fivetranGroups?.find((group) => group.id === selected)?.name || "",
                triggers: [],
              },
            })
          }
        />
        <Text size="sm">
          Manage and test your credentials on the{" "}
          <Link href="/extensions/fivetran/configuration">Fivetran Extension page.</Link>
        </Text>
      </FormField>

      {schedule.schedule?.groupId && (
        <FormField
          description="Select the dbt transformations and Fivetran connectors that will trigger this sync when completed successfully"
          label="Fivetran jobs"
        >
          <MultiSelect
            isLoading={loadingGroupResources}
            optionLabel={(option) => option.name}
            optionValue={(option) => option.id}
            options={groupResources || []}
            placeholder="Fivetran jobs"
            value={schedule.schedule.triggers.map((trigger) => trigger.id)}
            onChange={(selected) => {
              setSchedule({
                ...schedule,
                schedule: {
                  ...schedule?.schedule,
                  groupId: schedule.schedule?.groupId || "",
                  groupName: schedule.schedule?.groupName || "",
                  triggers: selected.map((id) => {
                    const resource = groupResources?.find((resource) => resource.id === id);
                    return {
                      id,
                      name: resource?.name || "",
                      type: resource?.type || "",
                      fivetran_id: resource?.fivetran_id || undefined,
                    } as FivetranSyncTrigger;
                  }),
                },
              });
            }}
          />
        </FormField>
      )}
    </>
  );
};
