import { FC, useEffect } from "react";

import { Button } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useForm } from "react-hook-form";
import { Outlet, Route, Routes, useOutletContext, useLocation } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Grid } from "theme-ui";

import dbtCloudImage from "src/components/extensions/assets/dbt-cloud.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  useDbtCredentialsQuery,
  DbtCredentialsQuery,
  useCreateDbtCredentialMutation,
  ResourcePermissionGrant,
  useDeleteDbtCloudCredentialMutation,
} from "src/graphql";
import { Container, Row } from "src/ui/box";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { DBTIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const DbtCloud: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="When your Hightouch sync depends on a table or materialized view generated by dbt, you can schedule it to run after completion of the associated dbt job."
              icon={DBTIcon}
              image={dbtCloudImage}
              subtitle="Trigger syncs upon completion of dbt jobs"
              title="dbt Cloud"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const {
    data: credentials,
    isLoading: loading,
    isRefetching: refetching,
    refetch: refetchCredentials,
  } = useDbtCredentialsQuery(undefined, {
    select: (data) => data.dbt_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "dbt Cloud" }]} size="medium">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/dbt-cloud");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading: loading || refetching, refetchCredentials }} />
    </Page>
  );
};

interface OutletContext {
  credentials: DbtCredentialsQuery["dbt_credentials"][0];
  loading: boolean;
  refetchCredentials: () => void;
}

const Configuration: FC = () => {
  const { credentials, loading, refetchCredentials } = useOutletContext<OutletContext>();
  const { addToast } = useToasts();
  const {
    setError,
    reset,
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm();

  const { mutateAsync: create } = useCreateDbtCredentialMutation();
  const { mutateAsync: deleteCredentials, isLoading: isDeleting } = useDeleteDbtCloudCredentialMutation();

  const submit = async (data) => {
    try {
      if (credentials?.id) {
        // We don't allow updates right now
        return;
      } else {
        await create({ apiKey: data.apiKey, subdomain: data.subdomain });
      }
    } catch (e) {
      addToast("There was an error saving your configuration.", {
        appearance: "error",
      });
      setError("api_key", { type: "custom", message: "Invalid API Key" });
      Sentry.captureException(e);
    }
  };

  useEffect(() => {
    reset({
      api_key: "",
      subdomain: null,
    });
  }, [credentials]);

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Row sx={{ justifyContent: "space-between" }}>
        <Container center={false} size="small">
          <Grid gap={8}>
            <Heading>dbt Cloud Configuration</Heading>

            <Field error={errors?.api_key?.message ? String(errors.api_key.message) : undefined} label="dbt Cloud API key">
              <Input
                disabled={Boolean(credentials?.id)}
                placeholder={credentials?.id ? "******************" : undefined}
                type="password"
                {...register("apiKey")}
              />
            </Field>

            <Field
              optional
              description='If left empty, Hightouch will default to "cloud" as the subdomain.'
              label="Custom subdomain"
            >
              <Input disabled={Boolean(credentials?.id)} {...register("subdomain")} />
            </Field>
          </Grid>
        </Container>
        <SidebarForm
          buttons={
            credentials?.id ? (
              <Permission>
                <Button
                  isLoading={isDeleting}
                  variant="danger"
                  onClick={async () => {
                    try {
                      await deleteCredentials({});
                      refetchCredentials();
                    } catch (e) {
                      addToast("There was an error deleting your credentials.", {
                        appearance: "error",
                      });
                      Sentry.captureException(e);
                    }
                  }}
                >
                  Disconnect
                </Button>
              </Permission>
            ) : (
              <Permission>
                <Button isLoading={isSubmitting} variant="primary" onClick={handleSubmit(submit)}>
                  Connect
                </Button>
              </Permission>
            )
          }
          docsUrl="extensions/dbt-cloud"
          name="dbt Cloud"
        />
      </Row>
    </PermissionProvider>
  );
};
