import { FC } from "react";

import { Badge, Button } from "@hightouchio/ui";
import { Box, Flex, Paragraph, Image, Text, ThemeUIStyleObject } from "theme-ui";

import { CheckList, CheckListItem } from "src/ui/checklist";

import { Heading } from "../../../ui/heading";

// Disabled CTA buttons show a tooltip and should have a tooltipText field
export type PricingCardProps = {
  plan?: string;
  sku: string;
  isRecommended?: boolean;
  ctaText: string;
  ctaDisabled?: boolean;
  ctaUrl?: string;
  ctaLoading?: boolean;
  onCtaClick: (sku: string) => void;
  price?: string;
  priceUnit?: string;
  priceHint?: string;
  checkListHeading?: string;
  checkList?: string[];
  partnerLogos: { img: string; alt: string }[];
  sx?: ThemeUIStyleObject;
};

export const PricingCard: FC<Readonly<PricingCardProps>> = ({
  plan,
  sku,
  isRecommended,
  ctaText,
  ctaDisabled,
  onCtaClick,
  ctaLoading,
  partnerLogos,
  price,
  priceHint,
  priceUnit,
  checkList,
  checkListHeading,
  sx = {},
}) => {
  function ctaClick() {
    onCtaClick(sku);
  }

  return (
    <Box
      sx={{
        py: 6,
        border: "1px solid",
        borderColor: ctaDisabled ? "base.2" : "primaries.2",
        borderRadius: 2,
        minHeight: "50vh",
        position: "relative",
        ...sx,
      }}
    >
      <Flex sx={{ flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
        <Box sx={{ mx: 4, position: "relative" }}>
          {/* heading */}
          <Flex sx={{ gap: 2, alignItems: "center", mb: 6 }}>
            <Heading sx={{ fontSize: 5, weight: "semi" }} variant="h2">
              {plan}
            </Heading>
            {isRecommended && <Badge color="blue">Recommended</Badge>}
          </Flex>

          <Button
            isJustified
            isDisabled={ctaDisabled}
            isLoading={ctaLoading}
            type="button"
            variant="primary"
            onClick={ctaClick}
          >
            {ctaText}
          </Button>

          {/* pricing */}
          <Paragraph sx={{ mt: 4, display: "flex", alignItems: "center", gap: 1, fontSize: 3, fontWeight: "bold" }}>
            <Text>{price}</Text>
            {priceUnit && <Text sx={{ color: "base.4" }}>{priceUnit}</Text>}
          </Paragraph>
          {/* checklist */}
          <CheckList>
            <Text sx={{ fontWeight: "bold", mb: 4 }}>{checkListHeading}</Text>
            {checkList?.map((listItem) => {
              return <CheckListItem key={listItem}>{listItem}</CheckListItem>;
            })}
          </CheckList>
          {
            /* pricing hint */
            priceHint && <Text sx={{ color: "base.4" }}>{priceHint}</Text>
          }
        </Box>
        {/* partner logos */}
        <Box sx={{ p: 4, pb: 0, textAlign: "center" }}>
          <Text sx={{ color: "base.5" }}>Powering Companies Like</Text>
          {/* TODO: insert image slideshow here */}
          <Flex sx={{ alignItems: "center", justifyContent: "center", mt: 2 }}>
            {partnerLogos.map((logo) => {
              return <Image key={logo.alt} alt={logo.alt} src={logo.img} width="200px" />;
            })}
            <Image />
          </Flex>
        </Box>
      </Flex>
    </Box>
  );
};

PricingCard.displayName = "Pricing card";
