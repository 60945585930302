import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SettingIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="m22.08 13.9c.43.23.76.59.99.95.45.74.42 1.66-.02 2.46l-.86 1.44c-.45.77-1.3 1.25-2.17 1.25-.43 0-.91-.12-1.3-.36-.32-.2-.69-.28-1.08-.28-1.21 0-2.23 1-2.27 2.18 0 1.38-1.13 2.46-2.54 2.46h-1.67c-1.42 0-2.55-1.08-2.55-2.46-.02-1.19-1.04-2.18-2.26-2.18-.4 0-.77.07-1.08.28-.39.24-.88.36-1.3.36-.88 0-1.73-.48-2.18-1.25l-.85-1.44c-.45-.78-.48-1.72-.02-2.46.2-.36.56-.72.98-.95.34-.17.56-.44.77-.77.61-1.03.25-2.39-.8-3-1.21-.68-1.61-2.21-.91-3.4l.82-1.42c.71-1.19 2.23-1.61 3.46-.91 1.07.58 2.45.19 3.08-.83.2-.34.31-.7.28-1.06-.02-.47.11-.91.34-1.27.45-.74 1.28-1.22 2.17-1.25h1.73c.91 0 1.73.5 2.18 1.25.22.36.37.8.33 1.27-.02.36.09.72.28 1.06.63 1.02 2.01 1.4 3.09.83 1.21-.7 2.75-.28 3.45.91l.82 1.42c.71 1.19.32 2.71-.91 3.4-1.04.61-1.41 1.97-.78 3 .2.32.42.6.76.77zm-10.08 1.7c1.99 0 3.6-1.61 3.6-3.6s-1.61-3.6-3.6-3.6-3.6 1.61-3.6 3.6 1.61 3.6 3.6 3.6z"
        opacity={0.4}
      />
      <path d="m12 15.6c1.99 0 3.6-1.61 3.6-3.6s-1.61-3.6-3.6-3.6-3.6 1.61-3.6 3.6 1.61 3.6 3.6 3.6z" />
    </Icon>
  );
};
