import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const DocsIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M19.2,0h-12C5.9,0,4.7,0.5,3.8,1.4C2.9,2.3,2.4,3.5,2.4,4.8v14.4c0,1.3,0.5,2.5,1.4,3.4C4.7,23.5,5.9,24,7.2,24h12
        c0.6,0,1.2-0.3,1.7-0.7c0.5-0.5,0.7-1.1,0.7-1.7V2.4c0-0.6-0.3-1.2-0.7-1.7C20.4,0.3,19.8,0,19.2,0z M7.2,21.6
        c-0.6,0-1.2-0.3-1.7-0.7c-0.5-0.5-0.7-1.1-0.7-1.7c0-0.6,0.3-1.2,0.7-1.7c0.5-0.5,1.1-0.7,1.7-0.7h12v4.8H7.2z M8.3,4.8
        C7.6,4.8,7.1,5.3,7.1,6s0.5,1.2,1.2,1.2h7.8c0.7,0,1.2-0.5,1.2-1.2s-0.5-1.2-1.2-1.2H8.3z"
        opacity={0.4}
      />
      <path d="M8.3,4.8h7.8c0.7,0,1.2,0.5,1.2,1.2l0,0c0,0.7-0.5,1.2-1.2,1.2H8.3C7.6,7.2,7.1,6.7,7.1,6l0,0C7.1,5.3,7.6,4.8,8.3,4.8z" />
    </Icon>
  );
};
