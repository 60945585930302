import { FC } from "react";

import { Box } from "@hightouchio/ui";

import { AuthLayout } from "src/components/auth/auth-layout";
import { Warning } from "src/components/warning";

const NotFoundPage: FC = () => {
  return (
    <AuthLayout>
      <Box mt={24}>
        <Warning subtitle="Sorry, we could not find what you were looking for!" title="Not found" />
      </Box>
    </AuthLayout>
  );
};

export default NotFoundPage;
