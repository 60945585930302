import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const SourceIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M21.89,3.76c0,2.61,0,4.8-.05,6.65,0,10.7,1.63,13.59-9.81,13.59S2.16,21.69,2.16,9.54c0-1.26,0-2.75,0-5.78C2.16,1.68,6.57,0,12.02,0s9.86,1.68,9.86,3.76Z"
        opacity={0.4}
      />
      <g>
        <path d="M17.53,5.29c.08-.44,.49-.74,.94-.69,.47,.06,.8,.48,.75,.95h0v.04s-.01,.04-.02,.06c0,.04-.02,.09-.04,.15-.04,.11-.09,.25-.18,.41-.18,.31-.49,.67-.98,1-.98,.65-2.68,1.19-5.66,1.19s-4.78-.54-5.86-1.16c-.54-.31-.9-.64-1.13-.92-.11-.14-.19-.26-.25-.36-.03-.05-.05-.09-.07-.13,0-.02-.02-.03-.02-.05v-.02h-.01v-.02c-.16-.44,.07-.93,.51-1.09,.43-.16,.9,.06,1.08,.48h0s.03,.06,.08,.11c.09,.11,.29,.31,.66,.52,.74,.43,2.22,.93,5.01,.93s4.12-.51,4.71-.9c.29-.19,.41-.36,.45-.44,.02-.03,.03-.05,.03-.06Z" />
        <path d="M17.53,11.57c.08-.44,.49-.74,.94-.69,.47,.06,.8,.48,.75,.95h0v.04s-.01,.04-.02,.06c0,.04-.02,.09-.04,.15-.04,.11-.09,.25-.18,.41-.18,.31-.49,.67-.98,1-.98,.65-2.68,1.19-5.66,1.19s-4.78-.54-5.86-1.16c-.54-.31-.9-.64-1.13-.92-.11-.14-.19-.26-.25-.36-.03-.05-.05-.09-.07-.13l-.02-.05v-.02h-.01v-.02h0c-.16-.45,.07-.93,.51-1.09,.43-.15,.9,.06,1.08,.48h0s.03,.06,.08,.11c.09,.11,.29,.31,.66,.52,.74,.43,2.22,.93,5.01,.93s4.12-.51,4.71-.9c.29-.19,.41-.36,.45-.44,.02-.03,.03-.05,.03-.06Z" />
        <path d="M17.53,17.86c.08-.44,.49-.74,.94-.69,.47,.06,.8,.48,.75,.95h0v.04s-.01,.04-.02,.06c0,.04-.02,.09-.04,.15-.04,.11-.09,.25-.18,.41-.18,.31-.49,.67-.98,1-.98,.65-2.68,1.19-5.66,1.19s-4.78-.54-5.86-1.16c-.54-.31-.9-.64-1.13-.92-.11-.14-.19-.26-.25-.36-.03-.05-.05-.09-.07-.13,0-.02-.02-.03-.02-.05v-.02h-.01v-.02h0c-.16-.45,.07-.93,.51-1.09,.43-.16,.9,.06,1.08,.48h0s.03,.06,.08,.11c.09,.11,.29,.31,.66,.52,.74,.43,2.22,.93,5.01,.93s4.12-.51,4.71-.9c.29-.19,.41-.36,.45-.44,.02-.03,.03-.05,.03-.06Z" />
      </g>
    </Icon>
  );
};
