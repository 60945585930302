import { FC, useState } from "react";

import { Alert, Box, Link } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import store from "store2";

import { useUser } from "src/contexts/user-context";
import * as analytics from "src/lib/analytics";

const permissionsMessageStorageKey = "permissions-message-dismissed";

export const PermissionsMessage: FC = () => {
  const { appEnableAdvancedPermissionsBanner } = useFlags();

  const { user, workspace } = useUser();
  const [displayPermissionsMessage, setDisplayPermissionsMessage] = useState(() => {
    const dismissed = Boolean(store.get(permissionsMessageStorageKey));

    return !dismissed;
  });

  const contact = () => {
    const intercom = window["Intercom"];

    if (typeof intercom === "function") {
      intercom("showNewMessage", "Hi, I'm interested in trying out advanced access management features.");
    }

    analytics.track("Permissions Message CTA Clicked", {
      user_id: user?.id,
      workspace_id: workspace?.id,
    });
  };

  const dismiss = () => {
    setDisplayPermissionsMessage(false);
    store.set(permissionsMessageStorageKey, true);
  };

  if (!appEnableAdvancedPermissionsBanner || !displayPermissionsMessage) {
    return null;
  }

  return (
    <Box mb={8}>
      <Alert
        isDismissible
        actionText="Upgrade plan"
        message={
          <>
            Upgrade to Business Tier to access this feature. RBAC allows you to choose from 10+ different roles (like &quot;Sync
            editor&quot; and &quot;Audience editor&quot;) to limit which resources a user can access or modify. Create your own
            custom roles to fit the needs of your enterprise. Read our{" "}
            <Link href="https://hightouch.com/docs/workspace-management/rbac">docs</Link> to learn more.
          </>
        }
        title="Role-based access control (RBAC) is not available in your workspace."
        variant="warning"
        onAction={contact}
        onDismiss={dismiss}
      />
    </Box>
  );
};
