import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const DestinationIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M16.9,0H21c1.7,0,3,1.4,3,3.1v4.1c0,1.7-1.4,3.1-3,3.1h-4.1c-1.7,0-3-1.4-3-3.1V3.1C13.8,1.4,15.2,0,16.9,0z"
        opacity={0.4}
      />
      <path
        d="M3,0h4.1c1.7,0,3,1.4,3,3.1v4.1c0,1.7-1.4,3.1-3,3.1H3c-1.7,0-3-1.4-3-3.1V3.1C0,1.4,1.4,0,3,0z M3,13.8h4.1
        c1.7,0,3,1.4,3,3.1v4.1c0,1.7-1.4,3.1-3,3.1H3c-1.7,0-3-1.4-3-3.1v-4.1C0,15.1,1.4,13.8,3,13.8z M21,13.8h-4.1c-1.7,0-3,1.4-3,3.1
        v4.1c0,1.7,1.4,3.1,3,3.1H21c1.7,0,3-1.4,3-3.1v-4.1C24,15.1,22.6,13.8,21,13.8z"
      />
    </Icon>
  );
};
