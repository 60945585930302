import { FC, useEffect, useState } from "react";

import { ArrowPathIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  Box,
  Button,
  Column,
  FormField,
  Heading,
  Link,
  Row,
  Spinner,
  StatusIndicator,
  Text,
  TextInput,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { formatRelative } from "date-fns";
import { parseISO } from "date-fns/esm";
import { Outlet, Route, Routes, useLocation, useOutletContext } from "react-router-dom";

import fivetranExtensionImage from "src/components/extensions/assets/fivetran-extension.png";
import { Overview } from "src/components/extensions/overview";
import { Page } from "src/components/layout";
import { SidebarForm } from "src/components/page";
import { ScheduleType } from "src/components/schedule/types";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  FivetranExtensionError,
  GetFivetranExtensionQuery,
  ResourcePermissionGrant,
  useCreateFivetranCredentialsMutation,
  useDeleteFivetranCredentialsMutation,
  useFivetranDependentSyncsQuery,
  useGetFivetranExtensionQuery,
  useTestFivetranExtensionQuery,
} from "src/graphql";
import { FivetranIcon } from "src/ui/icons";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { useNavigate } from "src/utils/navigate";

enum Tab {
  Overview = "Overview",
  Configuration = "Configuration",
}

const TABS = [Tab.Overview, Tab.Configuration];

export const Fivetran: FC = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route
          element={
            <Overview
              description="Schedule your Hightouch syncs to run when your Fivetran connectors and dbt transformations complete."
              icon={FivetranIcon}
              image={fivetranExtensionImage}
              subtitle="Trigger syncs upon completion of Fivetran jobs"
              title="Fivetran"
            />
          }
          path="/"
        />
        <Route element={<Configuration />} path="configuration" />
      </Route>
    </Routes>
  );
};

interface DependentSyncsModalProps {
  open: boolean;
  onClose: () => void;
}
const DependentSyncsModal = ({ open, onClose }: DependentSyncsModalProps): JSX.Element => {
  const {
    data: dependentSyncs,
    isLoading: loadingDependencies,
    error: dependentSyncError,
  } = useFivetranDependentSyncsQuery({ schedule: { type: ScheduleType.FIVETRAN } }, { select: (data) => data.syncs });
  const { mutateAsync: deleteExtension, isLoading: isDeleting } = useDeleteFivetranCredentialsMutation();

  const { toast } = useToast();

  const syncColumns = [
    {
      name: "sync",
      key: "",
      cell: ({ slug, id }) => <Link href={`/syncs/${id}`}>{slug}</Link>,
    },
    {
      name: "Model",
      key: "segment",
      cell: ({ name, id }: { name: string; id: string }) => <Link href={`/models${id}`}>{name}</Link>,
    },
    {
      name: "Destination",
      key: "destination",
      cell: ({ name, id }) => <Link href={`/destinations/${id}`}>{name}</Link>,
    },
  ];

  let content = (
    <Box pb={4}>
      <Text>You currently have no syncs triggered by Fivetran.</Text>
    </Box>
  );
  if (dependentSyncError || (dependentSyncs && dependentSyncs.length > 0)) {
    content = (
      <Column>
        <Text fontWeight="semibold">
          Disconnecting Fivetran will cause the following syncs to no longer be triggered. They can still be triggered manually.
        </Text>
        <Box pb={4} pt={4}>
          <Table
            columns={syncColumns}
            data={dependentSyncs}
            error={!!dependentSyncError}
            placeholder={{ error: "Fivetran-dependent Syncs failed to load, please refresh this page." }}
            sx={{ maxHeight: "500px" }}
          />
        </Box>
      </Column>
    );
  }

  return (
    <Modal
      footer={
        <Row gap={2}>
          <Button isDisabled={isDeleting || loadingDependencies} onClick={onClose}>
            Cancel
          </Button>
          <Button
            isDisabled={isDeleting || loadingDependencies}
            isLoading={isDeleting}
            variant="danger"
            onClick={async () => {
              try {
                await deleteExtension({});
                toast({
                  id: "fivetran-disconnect",
                  title: "Successfully disconnected",
                  message: "Fivetran has been disconnected from your Hightouch workspace.",
                  variant: "success",
                });
                onClose();
              } catch (error) {
                Sentry.captureException(error);
                toast({
                  id: "fivetran-disconnect",
                  title: "Failed to disconnect",
                  message: "Fivetran could not be disconnected from your Hightouch workspace. Please try again.",
                  variant: "error",
                });
              }
            }}
          >
            {isDeleting ? "Disconnecting..." : "Disconnect"}
          </Button>
        </Row>
      }
      footerSx={{ justifyContent: "right" }}
      isOpen={open}
      sx={{ maxWidth: "700px" }}
      title="Disconnect Fivetran"
      onClose={onClose}
    >
      {loadingDependencies ? (
        <Spinner />
      ) : (
        <>
          <Row pb={4}>
            <Text>
              Disconnecting Fivetran from your Hightouch workspace will remove the ability to trigger syncs when Fivetran jobs
              complete. You can re-connect Fivetran at any time by entering valid API credentials.
            </Text>
          </Row>
          {content}
        </>
      )}
    </Modal>
  );
};

type FivetranTestStatus = "failed" | "loading" | "success" | "error";

interface ExtensionProps {
  credentials: GetFivetranExtensionQuery["fivetran_credentials"][0];
  testStatus: FivetranTestStatus;
  isDeleting: boolean;
  setIsDeleting: (isDeleting: boolean) => void;
}

const Extension: FC<ExtensionProps> = ({ credentials, testStatus, isDeleting, setIsDeleting }) => {
  if (!credentials) {
    return null;
  }
  return (
    <>
      <Box
        alignItems="center"
        border="1px"
        borderColor="gray.300"
        borderRadius="md"
        display="flex"
        justifyContent="space-between"
        px={3}
        py={4}
        width="100%"
      >
        <Box alignItems="center" flexDirection="column">
          <Column gap={1}>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">API key: </Text>
              {credentials?.api_key}
            </Text>
            <Text fontWeight="medium">
              <Text fontWeight="semibold">Fivetran webhook ID: </Text>
              {credentials?.fivetran_webhook_id}
            </Text>
          </Column>
        </Box>
        <Column>
          <StatusIndicator variant={testStatus === "loading" ? "processing" : testStatus === "failed" ? "error" : "success"}>
            {testStatus === "loading" ? "Testing..." : testStatus === "failed" ? "Connection Failed" : "Connected"}
          </StatusIndicator>
        </Column>

        <Column>
          <Text>Created {formatRelative(parseISO(credentials?.created_at), new Date())}</Text>
        </Column>
      </Box>
      <DependentSyncsModal open={isDeleting} onClose={() => setIsDeleting(false)} />
    </>
  );
};

const Layout: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const path = location.pathname.split("/").pop();
  const tab = path === "configuration" ? Tab.Configuration : Tab.Overview;

  const {
    data: credentials,
    isLoading: loading,
    refetch,
  } = useGetFivetranExtensionQuery(undefined, {
    select: (data) => data.fivetran_credentials?.[0],
  });

  return (
    <Page crumbs={[{ label: "Extensions", link: "/extensions" }, { label: "Fivetran" }]} size="medium">
      <Tabs
        setTab={(tab) => {
          if (tab === Tab.Overview) {
            navigate("/extensions/fivetran");
          } else {
            navigate("configuration");
          }
        }}
        sx={{ mb: 10 }}
        tab={tab}
        tabs={TABS}
      />
      <Outlet context={{ credentials, loading, reload: refetch }} />
    </Page>
  );
};

interface OutletContext {
  credentials: GetFivetranExtensionQuery["fivetran_credentials"][0] | undefined;
  loading: boolean;
  reload: () => void;
}

const Configuration: FC = () => {
  const { credentials, loading, reload } = useOutletContext<OutletContext>();
  const [apiKey, setApiKey] = useState("");
  const [apiSecret, setApiSecret] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);

  const [error, setError] = useState<FivetranExtensionError | null>(null);

  const [testStatus, setTestStatus] = useState<FivetranTestStatus>("loading");
  const { toast } = useToast();
  const { mutateAsync: create, isLoading: isCreating } = useCreateFivetranCredentialsMutation();
  const {
    data: testResult,
    isLoading: isTesting,
    refetch: testExtension,
    isRefetching: isReTesting,
  } = useTestFivetranExtensionQuery({}, { select: (data) => data.testFivetranWebhook, enabled: !!credentials });

  useEffect(() => {
    if (credentials) {
      if (isTesting || isReTesting) {
        setTestStatus("loading");
        return;
      }
      if (!testResult?.succeed) {
        setTestStatus("failed");
        toast({
          id: "failed-fivetran-test",
          variant: "error",
          title: "Fivetran webhook test failed.",
          message: testResult?.message ?? "Please check your API credentials.",
        });
      } else {
        setTestStatus("success");
      }
    }
  }, [credentials, isTesting, isReTesting]);

  const submit = async () => {
    try {
      if (credentials?.id) {
        // We don't allow an update
        return;
      } else {
        setError(null);
        const resp = await create({ apiKey, apiSecret });
        if (resp.createFivetranExtension.__typename === "FivetranExtensionError") {
          setError(resp.createFivetranExtension);
          throw Error(resp.createFivetranExtension.fivetranError ?? resp.createFivetranExtension.message);
        }
        toast({
          id: "fivetran-credentials",
          variant: "success",
          title: "Fivetran connected",
          message: "You can now use Fivetran to trigger your syncs.",
        });
        reload();
        setApiKey("");
        setApiSecret("");
      }
    } catch (e) {
      toast({
        id: "fivetran-credentials",
        variant: "error",
        title: "Fivetran connection failed",
        message: e.message ?? "There was an error saving your Fivetran configuration.",
      });
      // TODO: Handle Fivetran Errors here
      Sentry.captureException(e);
    }
  };

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Row justifyContent="space-between">
        <Column flex={1} gap={5}>
          <Heading>Fivetran configuration</Heading>
          {credentials?.id ? (
            <Extension
              credentials={credentials}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              testStatus={testStatus}
            />
          ) : (
            <Column gap={5}>
              <FormField error={error?.message ? String(error.message) : undefined} label="API key">
                <TextInput isDisabled={isCreating} value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
              </FormField>

              <FormField label="API secret">
                <TextInput
                  isDisabled={isCreating}
                  type="password"
                  value={apiSecret}
                  onChange={(e) => setApiSecret(e.target.value)}
                />
              </FormField>
            </Column>
          )}
        </Column>
        <SidebarForm
          buttons={
            !credentials?.id ? (
              <Button isDisabled={!apiKey || !apiSecret} isLoading={isCreating} variant="primary" onClick={() => submit()}>
                Connect
              </Button>
            ) : (
              <>
                <Button
                  icon={ArrowPathIcon}
                  isDisabled={isTesting || isReTesting || isDeleting}
                  onClick={() => {
                    setTestStatus("loading");
                    testExtension({});
                  }}
                >
                  {isTesting || isReTesting ? "Testing..." : "Test connection"}
                </Button>
                <Button
                  icon={TrashIcon}
                  isDisabled={isDeleting || isTesting}
                  variant="danger"
                  onClick={() => {
                    setIsDeleting(true);
                  }}
                >
                  Disconnect
                </Button>
              </>
            )
          }
          docsUrl="extensions/fivetran"
          name="Fivetran"
        />
      </Row>
    </PermissionProvider>
  );
};
