import { FC, useEffect, useState } from "react";

import { Box, Paragraph, Text } from "@hightouchio/ui";
import CronParser, { CronExpression } from "cron-parser";
import CronGenerator from "cron-time-generator2";
import { useFlags } from "launchdarkly-react-client-sdk";
import moment from "moment";
import { Grid } from "theme-ui";

import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { DateTimeSelect } from "src/ui/datetime-select";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { NewSelect } from "src/ui/new-select";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { validCronExpression } from "src/utils/schedule";

import { Permission } from "../permission";
import { ConfigureDbtCloudSchedule } from "./dbt-cloud-schedule";
import { ConfigureFivetranSchedule } from "./fivetran-schedule";
import { defaultTime, Schedule, ScheduleIntervalUnit, ScheduleType } from "./types";
import { VisualCronExpression } from "./visual-cron-expression";

interface ScheduleOption {
  label: string;
  value: ScheduleType;
  description: string;
}

const getScheduleOptions = (resource: "sync" | "sequence"): ScheduleOption[] => {
  const { fivetranExtension } = useFlags();
  const options = [
    {
      label: "Manual",
      value: ScheduleType.MANUAL,
      description: `Trigger your ${resource} manually in the Hightouch app or using our API`,
    },
    {
      label: "Interval",
      value: ScheduleType.INTERVAL,
      description: `Schedule your ${resource} to run on a set interval (e.g., once per hour)`,
    },
    {
      label: "Custom recurrence",
      value: ScheduleType.CUSTOM,
      description: `Schedule your ${resource} to run on specific days (e.g., Mondays at 9am)`,
    },
    {
      label: "Cron expression",
      value: ScheduleType.CRON,
      description: `Schedule your ${resource} using a cron expression`,
    },
    {
      label: "dbt Cloud",
      value: ScheduleType.DBT_CLOUD,
      description: `Automatically trigger your ${resource} upon completion of a dbt Cloud job`,
    },
  ];
  if (fivetranExtension) {
    options.push({
      label: "Fivetran",
      value: ScheduleType.FIVETRAN,
      description: `Automatically trigger your ${resource} upon completion of a Fivetran job`,
    });
  }
  return options;
};

interface IntervalUnitOption {
  label: string;
  value: ScheduleIntervalUnit;
}

const intervalUnitOptions: IntervalUnitOption[] = [
  { label: "Minute(s)", value: ScheduleIntervalUnit.MINUTE },
  { label: "Hour(s)", value: ScheduleIntervalUnit.HOUR },
  { label: "Day(s)", value: ScheduleIntervalUnit.DAY },
  { label: "Week(s)", value: ScheduleIntervalUnit.WEEK },
];

export interface ScheduleManagerProps {
  schedule: Schedule | null;
  setSchedule: (schedule: Schedule) => void;
  resource?: "sync" | "sequence";
}

/**
 * Return a Date for the next whole [unit], e.g. the next hour
 * @param unit
 */
function nextInterval(unit: "second" | "minute" | "hour" | "day" | "week"): Date {
  const date = moment().add(1, unit);

  if (unit === "week") {
    date.days(0).hours(0).minutes(0).seconds(0).milliseconds();
  } else if (unit === "day") {
    date.hours(0).minutes(0).seconds(0).milliseconds();
  } else if (unit === "hour") {
    date.minutes(0).seconds(0).milliseconds();
  } else if (unit === "minute") {
    date.seconds(0).milliseconds();
  } else if (unit === "second") {
    date.milliseconds(0);
  } else {
    throw new Error("Unexpected time unit");
  }

  return date.toDate();
}

const CronExpressionTable = ({ expression }: { expression: CronExpression }) => {
  // We copy the passed cron expression to avoid mutating it
  const cron = CronParser.parseExpression(expression.stringify(), { utc: true });

  const next5: Date[] = [];
  for (let i = 0; i < 5; i++) {
    next5.push(cron.next().toDate());
  }

  return (
    <Box pt={4}>
      <Paragraph>
        <Text fontWeight="medium">The next five schedules for this Cron expression will be</Text>
        <ul style={{ listStyleType: "none" }}>
          {next5.map((date) => (
            <li key={date.toString()} style={{ margin: 1 }}>
              <Text key={date.toString()} size="sm">
                {date.toString()}
              </Text>
            </li>
          ))}
        </ul>
      </Paragraph>
    </Box>
  );
};

export const ScheduleManager: FC<ScheduleManagerProps> = ({ schedule, setSchedule, resource = "sync" }) => {
  const [cronExpressionErrorMessage, setCronExpressionErrorMessage] = useState<undefined | string>();
  const [cronExpression, setCronExpression] = useState<CronExpression>();

  // Check for cron expression validity
  useEffect(() => {
    if (schedule?.type === ScheduleType.CRON) {
      if (!schedule?.schedule?.expression) {
        const now = new Date();

        const expression = CronGenerator.onSpecificDaysAt([now.getDay()], now.getHours());

        setSchedule({
          ...schedule,
          schedule: {
            expression,
          },
        });
        return;
      } else {
        const exp = validCronExpression(schedule?.schedule?.expression);
        if (exp) {
          setCronExpression(exp);
          setCronExpressionErrorMessage(undefined);
        } else {
          setCronExpression(undefined);
          setCronExpressionErrorMessage("Invalid cron expression.");
        }
      }
    }
    if (schedule?.type === ScheduleType.CUSTOM && !schedule?.schedule?.expressions) {
      setSchedule({
        ...schedule,
        schedule: {
          expressions: [
            {
              days: {},
              time: defaultTime,
            },
          ],
        },
      });
    }
  }, [schedule]);

  return (
    <Grid gap={12} sx={{ width: "100%", "& > div:not(:last-child)": { pb: 12, borderBottom: "small" } }}>
      <Section title="Schedule type">
        <RadioGroup
          options={getScheduleOptions(resource)}
          value={schedule?.type}
          onChange={(type) => (type === schedule?.type ? undefined : setSchedule({ schedule: undefined, type }))}
        />
      </Section>
      {schedule?.type && schedule?.type !== ScheduleType.MANUAL && (
        <Section title="Schedule configuration">
          <Grid gap={8}>
            {schedule?.type === ScheduleType.INTERVAL && (
              <Grid gap={2} sx={{ gridAutoFlow: "column", gridAutoColumns: "max-content", alignItems: "center" }}>
                <Text fontWeight="semibold">Every</Text>
                <Input
                  min="1"
                  sx={{ width: "120px" }}
                  type="number"
                  value={schedule?.schedule?.interval?.quantity ? String(schedule?.schedule?.interval?.quantity) : ""}
                  onChange={(value) =>
                    setSchedule({
                      ...schedule,
                      schedule: {
                        interval: {
                          ...schedule?.schedule?.interval,
                          quantity: value ? Number(value) : null,
                        },
                      },
                    })
                  }
                />
                <Select
                  options={intervalUnitOptions}
                  placeholder="Interval..."
                  value={
                    schedule?.schedule?.interval?.unit
                      ? intervalUnitOptions.find((s) => schedule?.schedule?.interval?.unit === s.value)
                      : null
                  }
                  width={120}
                  onChange={(selected) =>
                    setSchedule({
                      ...schedule,
                      schedule: {
                        interval: {
                          ...schedule?.schedule?.interval,
                          unit: selected.value,
                        },
                      },
                    })
                  }
                />
              </Grid>
            )}

            {schedule?.type === ScheduleType.DBT_CLOUD && (
              <ConfigureDbtCloudSchedule schedule={schedule} setSchedule={setSchedule} />
            )}

            {schedule?.type === ScheduleType.FIVETRAN && (
              <ConfigureFivetranSchedule schedule={schedule} setSchedule={setSchedule} />
            )}

            {schedule?.type === ScheduleType.CUSTOM && (
              <>
                <Grid gap={8}>
                  {schedule?.schedule?.expressions?.length &&
                    schedule.schedule.expressions.map((_expression, index) => (
                      <VisualCronExpression key={index} index={index} schedule={schedule} setSchedule={setSchedule} />
                    ))}
                </Grid>

                <Permission>
                  <Button
                    label="Add recurrence"
                    size="small"
                    variant="secondary"
                    onClick={() => {
                      setSchedule({
                        ...schedule,
                        schedule: {
                          ...schedule?.schedule,
                          expressions: [...(schedule?.schedule?.expressions ?? []), { days: {}, time: defaultTime }],
                        },
                      });
                    }}
                  />
                </Permission>
              </>
            )}

            {schedule?.type === ScheduleType.CRON && (
              <Field error={cronExpressionErrorMessage} label="Cron expression">
                <Input
                  sx={{ width: "300px" }}
                  value={schedule?.schedule?.expression}
                  onChange={(expression) => {
                    setSchedule({
                      ...schedule,
                      schedule: {
                        expression,
                      },
                    });
                  }}
                />
                {!cronExpressionErrorMessage && cronExpression && <CronExpressionTable expression={cronExpression} />}
              </Field>
            )}

            <Grid
              columns={schedule?.startDate || schedule?.endDate ? "repeat(2, max-content)" : "repeat(4, max-content)"}
              gap={2}
              sx={{ gridAutoFlow: "row", alignItems: "center" }}
            >
              <Row sx={{ justifyContent: "end" }}>
                <Text>This schedule will be applied effective</Text>
              </Row>
              <Row sx={{ flex: "0 1 0", justifyContent: "start" }}>
                <NewSelect
                  options={[
                    { key: "immediate", value: "immediate", label: "immediately" },
                    { key: "deferred", value: "deferred", label: "from" },
                  ]}
                  value={schedule?.startDate ? "deferred" : "immediate"}
                  onChange={(value) =>
                    setSchedule({
                      ...schedule,
                      startDate: value === "immediate" ? null : schedule?.startDate ?? nextInterval("day").toISOString(),
                    })
                  }
                />
              </Row>
              {schedule?.startDate && (
                <DateTimeSelect
                  sx={{ gridColumn: 2, mb: 4 }}
                  value={schedule?.startDate ? new Date(schedule?.startDate) : nextInterval("day")}
                  onChange={(value) => {
                    setSchedule({
                      ...schedule,
                      startDate: value.toISOString(),
                    });
                  }}
                />
              )}
              <Row sx={{ justifyContent: "end" }}>
                <Text>and will remain effective</Text>
              </Row>
              <Row sx={{ width: "min-content" }}>
                <NewSelect
                  options={[
                    { key: "indefinite", value: "indefinite", label: "indefinitely" },
                    { key: "finite", value: "finite", label: "until" },
                  ]}
                  value={schedule?.endDate ? "finite" : "indefinite"}
                  onChange={(value) =>
                    setSchedule({
                      ...schedule,
                      endDate: value === "indefinite" ? null : schedule?.endDate ?? nextInterval("week").toISOString(),
                    })
                  }
                />
              </Row>
              {schedule?.endDate && (
                <DateTimeSelect
                  sx={{ gridColumn: 2 }}
                  value={schedule?.endDate ? new Date(schedule?.endDate) : nextInterval("week")}
                  onChange={(value) => {
                    setSchedule({
                      ...schedule,
                      endDate: value.toISOString(),
                    });
                  }}
                />
              )}
            </Grid>
          </Grid>
        </Section>
      )}
    </Grid>
  );
};
