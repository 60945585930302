import { FC } from "react";

import { Flex } from "theme-ui";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";
import { Toggle } from "src/ui/toggle";

type ColumnOrConstantFieldProps = {
  property: string;
  error?: string;
  columnLabel: string;
  columnDescription?: string;
  constantLabel: string;
  constantDescription?: string;
  constantInput: any;
  fieldProps?: any;
  disabled?: boolean;
};

export const ColumnOrConstantField: FC<Readonly<ColumnOrConstantFieldProps>> = ({
  property,
  error,
  columnLabel,
  columnDescription,
  constantLabel,
  constantDescription,
  constantInput,
  fieldProps = {},
  disabled = false,
}) => {
  const { config, setConfig, errors, hightouchColumns, loadingModel, reloadModel } = useDestinationForm();

  const value = config[property];
  const setValue = (value) => {
    setConfig({ ...config, [property]: value });
  };

  const valueIsColumn = value && typeof value !== "string";

  return (
    <Field
      description={valueIsColumn ? columnDescription : constantDescription}
      error={errors?.[property] || errors?.[`${property}.column`] || error}
      label={valueIsColumn ? columnLabel : constantLabel}
      size="large"
      {...fieldProps}
    >
      <Flex sx={{ alignItems: "center" }}>
        {valueIsColumn ? (
          <Select
            isError={Boolean(errors?.[`${property}.column`])}
            isLoading={loadingModel}
            options={hightouchColumns}
            placeholder="Select a column..."
            reload={reloadModel}
            sx={{ maxWidth: "280px" }}
            value={value?.column}
            width="280px"
            onChange={(selected) => {
              const val = selected?.value;
              setValue(val ? { column: val } : undefined);
            }}
          />
        ) : (
          <Flex>{constantInput}</Flex>
        )}
        {!disabled && (
          <Toggle
            label={"Use column"}
            sx={{ ml: 4 }}
            value={valueIsColumn}
            onChange={(value) => {
              if (value) setValue({ column: undefined });
              else setValue(undefined);
            }}
          />
        )}
      </Flex>
    </Field>
  );
};
