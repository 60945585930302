import { useState, FC } from "react";

import { useToasts } from "react-toast-notifications2";
import { Image, Text } from "theme-ui";

import {
  SourceDefinitionFragment as SourceDefinition,
  useDeleteSourceMutation,
  useListAllSourceDependenciesQuery,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Badge } from "src/ui/badge";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { ExternalLinkIcon, WarningCircleIcon } from "src/ui/icons";
import { Input } from "src/ui/input";
import { Link } from "src/ui/link";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { Tabs } from "src/ui/tabs";
import { useDestinations } from "src/utils/destinations";
import { useNavigate } from "src/utils/navigate";

enum Tab {
  MODELS = "models",
  AUDIENCES = "audiences",
  SYNCS = "syncs",
}

type Props = {
  source: any;
  onClose: any;
  sourceDefinition: SourceDefinition | undefined;
};

export const DeleteSourceWarning: FC<Readonly<Props>> = ({ onClose, source, sourceDefinition }) => {
  const [tab, setTab] = useState<Tab>(Tab.MODELS);
  const [confirmation, setConfirmation] = useState<string>("");
  const { addToast } = useToasts();
  const navigate = useNavigate();

  const {
    data: { definitions: destinationDefinitions },
  } = useDestinations();

  const { data: sourceDependenciesData, isLoading: loadingDependencies } = useListAllSourceDependenciesQuery({
    ids: [source?.id],
  });

  const { isLoading: deleting, mutateAsync: deleteSource } = useDeleteSourceMutation();

  const { audiences, models, syncs } = sourceDependenciesData?.listAllSourceDependencies.sources[0]?.dependencies || {};

  const syncColumns = [
    {
      name: "Model/Audience",
      cell: ({ modelName }) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            <Image
              alt={sourceDefinition?.name}
              src={sourceDefinition?.icon}
              sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
            />
            <Text sx={{ color: "base.6" }}>{modelName}</Text>
          </Row>
        );
      },
    },
    {
      name: "Destination",
      cell: ({ destinationName, destinationType, syncId }) => {
        const destinationDefinition = destinationDefinitions?.[destinationType];

        return (
          <Row sx={{ alignItems: "center" }}>
            <Image
              alt={destinationDefinition?.name}
              src={destinationDefinition?.icon}
              sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
            />
            <Text sx={{ color: "base.6" }}>{destinationName}</Text>
            <Link newTab sx={{ ml: "auto", mr: "0" }} to={`/syncs/${syncId}`}>
              <ExternalLinkIcon color="base.6" size={20} />
            </Link>
          </Row>
        );
      },
    },
  ];

  const TABS = [
    { value: Tab.MODELS, label: "Models", length: models?.length },
    { value: Tab.AUDIENCES, label: "Audiences", length: audiences?.length },
    { value: Tab.SYNCS, label: "Syncs", length: syncs?.length },
  ];

  const toSingleColumn = (resourceType) => [
    {
      name: "Name",
      cell: (resource) => {
        return (
          <Row sx={{ alignItems: "center" }}>
            <Image
              alt={sourceDefinition?.name}
              src={sourceDefinition?.icon}
              sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2, flexShrink: 0 }}
            />
            <Text sx={{ color: "base.6" }}>{resource.name}</Text>
            <Link newTab sx={{ ml: "auto", mr: "0" }} to={`/${resourceType}/${resource.id}`}>
              <ExternalLinkIcon color="base.6" size={20} />
            </Link>
          </Row>
        );
      },
    },
  ];

  const singleColumnTable = (resourceType, resourceData) => {
    return (
      <Table
        columns={toSingleColumn(resourceType)}
        data={resourceData}
        loading={loadingDependencies}
        placeholder={placeholderContent(resourceType)}
        placeholderSx={{ mt: 2, bg: "none" }}
        tableSx={{ bg: "none" }}
      />
    );
  };

  const placeholderContent = (resource) => ({
    title: `No ${resource} associated with this source were found`,
    error: `Failed to load ${resource}, please try again.`,
  });

  return (
    <Modal
      bodySx={{ pb: 6 }}
      footerContent={
        <Field label="Please type the name of your source to confirm deletion" sx={{ width: "100%" }}>
          <Row>
            <Input placeholder={source.name} sx={{ mr: 2 }} value={confirmation} onChange={(value) => setConfirmation(value)} />
            <Button sx={{ alignContent: "baseline", mr: 2 }} variant="secondary" onClick={onClose}>
              Cancel
            </Button>
            <Button
              disabled={confirmation !== source.name}
              loading={deleting}
              variant="red"
              onClick={async () => {
                await deleteSource({ id: source?.id });

                analytics.track("Source Deleted", {
                  source_id: source?.id,
                  source_name: source?.name,
                  source_type: sourceDefinition?.name,
                });

                addToast(`Source ${source?.name} deleted!`, {
                  appearance: "success",
                });

                navigate("/sources");
              }}
            >
              Yes, delete all
            </Button>
          </Row>
        </Field>
      }
      header={
        <Row sx={{ alignItems: "center" }}>
          <WarningCircleIcon color="red" />
          <Heading sx={{ px: 2 }} variant="h2">
            {"Delete this source?"}
          </Heading>
        </Row>
      }
      sx={{
        width: "100%",
        height: "100%",
        maxWidth: "625px",
        maxHeight: "625px",
      }}
      onClose={onClose}
    >
      <Text sx={{ color: "base.6", fontSize: 1, mb: 3 }}>
        This action will permanently delete the <i>{source.name}</i> source and cannot be undone. In order to properly delete
        this source, we will also need to permanently delete all of its associated resources:
      </Text>

      <Tabs
        setTab={(tab) => setTab(tab as Tab)}
        sx={{ bg: "base.1" }}
        tab={tab}
        tabs={TABS.map(({ value, label, length }) => ({
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              <Text sx={{ mr: 2 }}>{label}</Text>
              <Badge variant="baseCircle">{length}</Badge>
            </Row>
          ),
          value,
        }))}
      />

      {tab === Tab.MODELS && singleColumnTable("models", models)}
      {tab === Tab.AUDIENCES && singleColumnTable("audiences", audiences)}

      {tab === Tab.SYNCS && (
        <Table
          columns={syncColumns}
          data={syncs}
          loading={loadingDependencies}
          placeholder={placeholderContent("syncs")}
          placeholderSx={{ mt: 2, bg: "none" }}
          tableSx={{ bg: "none" }}
        />
      )}
    </Modal>
  );
};
