import { useToasts } from "react-toast-notifications2";
import { Grid } from "theme-ui";

import { useSlackChannelsQuery, useSlackCredentialsQuery } from "src/graphql";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { TextArea } from "src/ui/input";
import { Select } from "src/ui/select";

import { SlackAlert, SlackAlertConfig } from "./types";

export const SlackForm = ({
  alert,
  setAlert,
  config,
  setConfig,
}: {
  alert: SlackAlert;
  setAlert: (alert: SlackAlert) => void;
  config: SlackAlertConfig;
  setConfig: (config: SlackAlertConfig) => void;
}) => {
  const { addToast } = useToasts();
  const {
    data: slackChannelsData,
    isLoading: slackChannelsLoading,
    error: slackChannelsError,
  } = useSlackChannelsQuery(
    {
      id: Number(alert?.slackCredentialId),
    },
    { enabled: Boolean(alert?.slackCredentialId) },
  );
  const {
    data: slackCredentialsData,
    isLoading: slackCredentialsLoading,
    error: slackCredentialsError,
  } = useSlackCredentialsQuery();

  return (
    <>
      <Field error={slackCredentialsError?.message} label={"Credentials"}>
        <Select
          isLoading={slackCredentialsLoading}
          options={slackCredentialsData?.slack_credentials?.map((credential) => ({
            label: `Default Slack Account (${credential.id})`,
            value: credential.id,
          }))}
          placeholder="Select a Slack account..."
          value={alert?.slackCredentialId}
          width="360px"
          onChange={(selected) => {
            setAlert({ ...alert, slackCredentialId: selected?.value });
          }}
        />
      </Field>
      <Field
        description="The Hightouch app must be invited to the channel for the channel to appear on this list."
        error={slackChannelsError?.message}
        label={"Channel"}
      >
        <Select
          isLoading={slackChannelsLoading}
          options={slackChannelsData?.listSlackChannelsByCredentials?.map((channel) => ({
            label: channel.name,
            value: channel.id,
          }))}
          placeholder="Select a Slack channel..."
          value={config?.channelId}
          width="360px"
          onChange={(selected) => {
            setConfig({ ...config, channelId: selected?.value });
          }}
        />
      </Field>
      <Field optional label={"Custom fatal error blocks"}>
        <TextArea
          placeholder={`Ex: [{"type": "section", "text": {"type": "plain_text", "text": "Sync {{ id }} failed with error: {{ error }}"}}]`}
          rows={5}
          value={config?.fatalErrorBlocks || ""}
          onValue={(fatalErrorBlocks) => setConfig({ ...config, fatalErrorBlocks })}
        />
      </Field>
      <Field optional label={"Custom row error blocks"}>
        <TextArea
          placeholder={`Ex: [{"type": "section", "text": {"type": "plain_text", "text": "Sync {{ id }} failed with error: {{ error }}"}}]`}
          rows={5}
          value={config?.rowErrorBlocks || ""}
          onValue={(rowErrorBlocks) => setConfig({ ...config, rowErrorBlocks })}
        />
      </Field>
      <Grid sx={{ gridAutoFlow: "column", gridAutoColumns: "max-content", alignItems: "center" }}>
        <Button
          label="Beautify JSON"
          variant="secondary"
          onClick={() => {
            const rowErrorBlocks = config?.rowErrorBlocks
              ? beautifyJSON(config?.rowErrorBlocks, () => {
                  addToast(`The custom row error blocks is not a valid JSON.`, {
                    appearance: "warning",
                  });
                })
              : undefined;
            const fatalErrorBlocks = config?.fatalErrorBlocks
              ? beautifyJSON(config?.fatalErrorBlocks, () => {
                  addToast(`The custom fatal error blocks is not a valid JSON.`, {
                    appearance: "warning",
                  });
                })
              : undefined;
            setConfig({ ...config, fatalErrorBlocks, rowErrorBlocks });
          }}
        />
        <Button
          label="Preview Block Kit"
          variant="secondary"
          onClick={() => {
            try {
              const rowErrorJSON = config?.rowErrorBlocks ? JSON.parse(config?.rowErrorBlocks) : [];
              const fatalErrorJSON = config?.fatalErrorBlocks ? JSON.parse(config?.fatalErrorBlocks) : [];

              if (!(rowErrorJSON instanceof Array) || !(fatalErrorJSON instanceof Array)) {
                addToast(`The custom fatal error blocks and custom row error blocks must be an array.`, {
                  appearance: "warning",
                });
                return;
              }

              const blockString = JSON.stringify({ blocks: [...rowErrorJSON, ...fatalErrorJSON] });
              const urlString = `https://app.slack.com/block-kit-builder#${encodeURIComponent(blockString)}`;
              window.open(urlString, "_blank");
            } catch (e) {
              addToast(`The custom fatal error blocks and custom row error blocks are not valid JSON.`, {
                appearance: "warning",
              });
            }
          }}
        />
      </Grid>
    </>
  );
};

const beautifyJSON = (body, onError: null | (() => void) = null) => {
  let obj;
  try {
    obj = JSON.parse(body);
  } catch (err) {
    if (onError) {
      onError();
    }
    return body;
  }
  return JSON.stringify(obj, null, 4);
};

export const slackValidator = (alert: SlackAlert): boolean => {
  const config = alert?.config;

  let jsonError;

  try {
    let obj;
    if (config?.fatalErrorBlocks) {
      obj = JSON.parse(config.fatalErrorBlocks);
      if (!(obj instanceof Array)) jsonError = true;
    }
    if (config?.rowErrorBlocks) {
      JSON.parse(config.rowErrorBlocks);
      if (!(obj instanceof Array)) jsonError = true;
    }
  } catch (err) {
    jsonError = true;
  }

  return Boolean(alert?.slackCredentialId && config?.channelId) && !jsonError;
};
