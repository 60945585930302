import { FC } from "react";

import { Text } from "theme-ui";

import {
  ConditionType,
  EventCondition,
  FunnelCondition,
  TimestampOperator,
  Window,
  windowOperatorOptions,
  funnelWindowOperatorOptions,
} from "src/types/visual";
import { Row } from "src/ui/box";
import { NewSelect } from "src/ui/new-select";

import { RemoveButton } from "./condition";
import { Filter } from "./filter";
import { getTimeValue } from "./property-filter";
import { TimestampInput } from "./timestamp-input";

type Props = { eventName?: string } & (
  | {
      condition: EventCondition;
      onChange: (updates: Partial<EventCondition>) => void;
    }
  | {
      condition: FunnelCondition;
      onChange: (updates: Partial<FunnelCondition>) => void;
    }
);

export const WindowFilter: FC<Readonly<Props>> = ({ condition, onChange, eventName }) => {
  const { window } = condition;

  const isFunnelWindow = condition.type === ConditionType.Funnel;
  const operatorLabel = windowOperatorOptions.find((option) => option.value === window?.operator)?.label;
  const formattedValue = getTimeValue(window?.value, window?.timeType, isFunnelWindow);

  const update = (updates: Partial<Window>) => {
    onChange({ window: { ...window!, ...updates } });
  };

  return (
    <Row sx={{ alignItems: "center", pl: 12 }}>
      <Filter
        content={
          <>
            <NewSelect
              options={isFunnelWindow ? funnelWindowOperatorOptions : windowOperatorOptions}
              placeholder={"Filter on"}
              sx={{ flex: "0 0 auto" }}
              value={window?.operator}
              width={200}
              onChange={(operator) => {
                if ([operator, window?.operator].includes(TimestampOperator.Between)) {
                  update({ operator, value: undefined });
                } else {
                  update({ operator });
                }
              }}
            />
            {window && <TimestampInput condition={window} hideTime={false} onChange={update} />}
          </>
        }
      >
        <Text sx={{ color: "base.5" }}>{operatorLabel}</Text>
        {formattedValue && (
          <>
            {formattedValue}
            {eventName && (
              <>
                <Text sx={{ color: "base.5" }}>{window?.operator === TimestampOperator.Between ? "after" : "of"}</Text>
                <Text sx={{ overflow: "hidden", whiteSpace: "nowrap", textOverflow: "ellipsis" }}>{eventName}</Text>
              </>
            )}
          </>
        )}
      </Filter>
      <RemoveButton onRemove={() => onChange({ window: null })} />
    </Row>
  );
};
