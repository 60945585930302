import { FC, useState } from "react";

import {
  Box,
  Text,
  Button,
  ButtonGroup,
  ClipboardButton,
  FormField,
  Heading,
  Paragraph,
  TextInput,
  Link,
  useToast,
  Alert,
  Badge,
  Select,
  Switch,
  Spinner,
} from "@hightouchio/ui";
import { useQueryClient } from "react-query";
import { Text as LegacyText } from "theme-ui";

import { Permission } from "src/components/permission";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useConfigureSsoMutation,
  useDeleteSsoGroupRolesMutation,
  useInsertSsoGroupRolesMutation,
  useUpdateOrganizationMutation,
  useWorkspaceQuery,
  useWorkspacesOrganizationsGroupsQuery,
  WorkspaceQuery,
  WorkspacesOrganizationsGroupsQuery,
} from "src/graphql";
import { newIntercomMessage } from "src/lib/intercom";
import { Container } from "src/ui/box";
import { FileUploader } from "src/ui/file";
import { ArrowRightIcon } from "src/ui/icons";
import { Modal } from "src/ui/modal";

export const Organization: FC = () => {
  return (
    <Settings route="sso">
      <Container center={false} size="medium">
        <PermissionProvider permissions={[]}>
          <General />
        </PermissionProvider>
      </Container>
    </Settings>
  );
};

const General: FC = () => {
  const { workspace: _workspace } = useUser();
  const { data: workspaceData, refetch } = useWorkspaceQuery({ workspaceId: _workspace?.id }, { enabled: Boolean(_workspace) });
  const [ssoModalOpen, setSsoModalOpen] = useState(false);

  const organization = workspaceData?.workspaces_by_pk?.organization;

  const isSsoEnabled = (organization?.auth0_connections || []).length > 0;

  const loginUrl = "https://app.hightouch.com/sso/" + organization?.slug;
  const connectionName = organization?.auth0_connections[0]?.name || `${organization?.slug}-1`;

  const queryClient = useQueryClient();
  const { toast } = useToast();

  const updateOrganizationMutation = useUpdateOrganizationMutation({
    async onMutate({ can_invite_users }) {
      const queryKey = useWorkspaceQuery.getKey({ workspaceId: _workspace?.id });
      await queryClient.cancelQueries({ queryKey });

      const workspaceDataBeforeUpdate = queryClient.getQueryData<WorkspaceQuery>(queryKey);

      queryClient.setQueryData<WorkspaceQuery>(queryKey, (previousWorkspaceData) => {
        return {
          ...previousWorkspaceData!,
          workspaces_by_pk: {
            ...previousWorkspaceData!.workspaces_by_pk!,
            organization: {
              ...previousWorkspaceData!.workspaces_by_pk!.organization!,
              can_invite_users,
            },
          },
        };
      });

      return { workspaceDataBeforeUpdate };
    },
    onSuccess() {
      toast({
        id: "sso",
        title: "SSO settings updated",
        variant: "success",
      });
    },
    onError(_error, _variables, context) {
      const queryKey = useWorkspaceQuery.getKey({ workspaceId: _workspace?.id });

      if (context?.workspaceDataBeforeUpdate) {
        queryClient.setQueryData(queryKey, context.workspaceDataBeforeUpdate);
      }

      toast({
        id: "sso",
        title: "Something went wrong",
        message: "Failed to update SSO settings, please try again.",
        variant: "error",
      });
    },
    onSettled: () => {
      void refetch();
    },
  });

  const toggleAllowingLogin = (value: boolean) => {
    updateOrganizationMutation.mutate({
      id: organization?.id,
      can_invite_users: value,
    });
  };

  if (!workspaceData) {
    return (
      <Box display="flex" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" gap={12}>
      <Box>
        {organization?.plan?.sku !== "business_tier" && (
          <Alert
            actionText="Upgrade plan"
            mb={8}
            message={
              <>
                Upgrade to Business Tier to access this feature. SSO is recommended for enterprises that use a SAML-based
                identity provider or require SCIM support. This feature helps you control user authentication and authorization
                through your central identity provider. Create rules for which workspaces and roles users should be mapped to,
                and automatically de-provision users when they leave your organization. Read our{" "}
                <Link href="https://hightouch.com/docs/workspace-management/sso">docs</Link> to learn more.
              </>
            }
            title="Single sign-on (SSO) is not available in your workspace."
            variant="warning"
            onAction={() => newIntercomMessage("Hi, I'd like to enable SSO for my workspace.")}
          />
        )}

        {organization?.plan?.sku === "business_tier" && isSsoEnabled && (
          <Alert
            actionText="Contact us"
            mb={8}
            message="SSO cannot be disabled in the app, but our team can take care of this for you."
            title="Looking to disable single sign-on?"
            variant="info"
            onAction={() => newIntercomMessage("Hi, I'd like to disable SSO for my workspace.")}
          />
        )}

        {organization?.plan?.sku === "business_tier" && (
          <>
            <Box alignItems="center" display="flex" gap={3}>
              <Heading>Single sign-on</Heading>
              <Badge color={isSsoEnabled ? "green" : "gray"}>{isSsoEnabled ? "Enabled" : "Disabled"}</Badge>
            </Box>

            <Button mt={4} variant={isSsoEnabled ? "secondary" : "primary"} onClick={() => setSsoModalOpen(true)}>
              {isSsoEnabled ? "Update SAML SSO" : "Configure SAML SSO"}
            </Button>
          </>
        )}

        <AddSsoModal
          close={() => {
            setSsoModalOpen(false);
          }}
          connectionName={connectionName}
          open={ssoModalOpen}
        />

        <Box display="flex" flexDirection="column" gap={8} mt={6}>
          {isSsoEnabled && (
            <>
              <FormField
                label="Login URL"
                tip="Copy and paste this somewhere safe and use it to invite teammates to this organization."
              >
                <Box display="flex" gap={3}>
                  <TextInput isReadOnly value={loginUrl} />
                  <ClipboardButton text={loginUrl} />
                </Box>
              </FormField>

              <FormField
                description="By allowing users to be invited, you can invite users via their email address in addition to letting users login via SSO"
                label="Allow inviting users"
              >
                <Box alignItems="center" display="flex" gap={3}>
                  <Switch
                    isChecked={Boolean(organization?.can_invite_users)}
                    isDisabled={updateOrganizationMutation.isLoading}
                    onChange={toggleAllowingLogin}
                  />

                  <Box opacity={updateOrganizationMutation.isLoading ? 1 : 0}>
                    <Spinner />
                  </Box>
                </Box>
              </FormField>
            </>
          )}
        </Box>
      </Box>

      <Permission
        permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update], resource_id: _workspace?.id }]}
      >
        <SsoGroupMapping />
      </Permission>
    </Box>
  );
};

interface AddSsoModalProps {
  open: boolean;
  close: () => void;
  connectionName: string;
}

const AddSsoModal: FC<AddSsoModalProps> = ({ open, close, connectionName }) => {
  const [cert, setCert] = useState("");
  const [signInEndpoint, setSignInEndpoint] = useState("");
  const { toast } = useToast();

  const { mutateAsync: addSso, isLoading } = useConfigureSsoMutation();

  const handleClose = () => {
    close();
  };

  const save = async () => {
    try {
      await addSso({
        details: {
          cert,
          signInEndpoint,
        },
      });

      toast({
        id: "sso",
        title: "SSO configuration updated",
        variant: "success",
      });
      close();
    } catch (err) {
      toast({
        id: "sso",
        title: "Failed to configure SSO",
        variant: "error",
      });
    }
  };

  const audienceValue = `urn:auth0:hightouch:${connectionName}`;
  const samlUrl = `https://hightouch.us.auth0.com/login/callback?connection=${connectionName}`;

  return (
    <Modal
      bodySx={{ pb: 6, bg: "white", maxHeight: "580px" }}
      footer={
        <ButtonGroup>
          <Button onClick={handleClose}>Close</Button>
          <Button isDisabled={isLoading || !cert || !signInEndpoint} isLoading={isLoading} variant="primary" onClick={save}>
            Save
          </Button>
        </ButtonGroup>
      }
      isOpen={open}
      sx={{ maxWidth: "640px", width: "100%" }}
      title="Add SSO Connection"
      onClose={handleClose}
    >
      <Box display="flex" flexDirection="column" gap={12}>
        <Box>
          <LegacyText
            sx={{ textTransform: "uppercase", fontWeight: 600, fontSize: 0, color: "#9AA4B2", letterSpacing: "0.03em" }}
          >
            Step 1
          </LegacyText>
          <Heading>Set up an SSO application in your identity provider</Heading>
          <Paragraph>
            If you need help settings things up, view our{" "}
            <Link href="https://hightouch.com/docs/workspace-management/sso">documentation</Link>.
          </Paragraph>

          <Box display="flex" flexDirection="column" gap={6} mt={4}>
            <FormField label="SAML URL">
              <Box display="flex" gap={3}>
                <TextInput isReadOnly value={samlUrl} />
                <ClipboardButton text={samlUrl} />
              </Box>
            </FormField>

            <FormField label="SAML audience URL">
              <Box display="flex" gap={3}>
                <TextInput isReadOnly value={audienceValue} />
                <ClipboardButton text={audienceValue} />
              </Box>
            </FormField>
          </Box>
        </Box>

        <Box>
          <LegacyText
            sx={{ textTransform: "uppercase", fontWeight: 600, fontSize: 0, color: "#9AA4B2", letterSpacing: "0.03em" }}
          >
            Step 2
          </LegacyText>

          <Heading>Provide the details of your SSO application</Heading>

          <Box display="flex" flexDirection="column" gap={6} mt={4}>
            <FormField
              description="This is the URL your identity provider (Okta, Azure AD, etc.) provides when completing the configuration of a SAML application."
              label="Identity provider SSO URL"
            >
              <TextInput
                placeholder="Enter your SAML sign in endpoint"
                value={signInEndpoint}
                onChange={(event) => setSignInEndpoint(event.target.value)}
              />
            </FormField>

            <FormField
              description="This is a text file that usually starts with BEGIN CERTIFICATE. Please upload the entire file as provided by your identity provider."
              label="x.509 certificate"
            >
              <FileUploader
                acceptedFileTypes={[".pem", ".crt", ".cert", ".cer"]}
                transformation="string"
                value={cert}
                onChange={setCert}
              />
            </FormField>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

const SsoGroupMapping: FC = () => {
  const { data, isLoading, refetch } = useWorkspacesOrganizationsGroupsQuery();
  const { workspace } = useUser();

  if (isLoading) {
    // return null because we return null if the user doesn't have an organization,
    // so they will just see a spinner then nothing else.
    return null;
  }

  // we want the organization that this workspace belongs to - we might get multiple back if the user is an admin.
  const organization = data?.organizations?.find((organization) => organization.id === workspace?.organization?.id);

  if (!organization || organization.workspaces.length === 0) {
    return null;
  }

  const hasOrgGroups = organization.sso_groups.length > 0;
  if (!hasOrgGroups) {
    return null;
  }

  return (
    <Box>
      <Heading>Role mapping</Heading>
      <Paragraph>Map your SSO groups to Hightouch roles.</Paragraph>

      <Box display="flex" flexDirection="column" gap={6} mt={6}>
        {organization.workspaces.map((workspace) => {
          if (organization.sso_groups.length === 0) {
            return null;
          }
          return (
            <Box key={workspace.id}>
              <Text fontWeight="semibold">{workspace.name}</Text>

              <Box alignItems="center" display="grid" gap={3} gridTemplateColumns="200px 24px 1fr" mt={1}>
                {organization.sso_groups.map((ssoGroup) => {
                  return <WorkspaceGroupMapping key={ssoGroup.id} group={ssoGroup} refetch={refetch} workspace={workspace} />;
                })}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};

interface WorkspaceGroupMappingProps {
  group: WorkspacesOrganizationsGroupsQuery["organizations"][0]["sso_groups"][0];
  refetch: () => Promise<unknown>;
  workspace: WorkspacesOrganizationsGroupsQuery["organizations"][0]["workspaces"][0];
}

const WorkspaceGroupMapping: FC<WorkspaceGroupMappingProps> = ({ group, refetch, workspace }) => {
  const { toast } = useToast();
  const insertSsoGroupRolesMutation = useInsertSsoGroupRolesMutation();
  const deleteSsoGroupRolesMutation = useDeleteSsoGroupRolesMutation();
  const [isUpdatingRole, setIsUpdatingRole] = useState(false);

  const existingRoleMapping = workspace.sso_group_roles.find((ssoGroupRole) => ssoGroupRole.sso_group.id === group.id);

  return (
    <>
      <Text>{group.name}</Text>

      <Box display="flex" justifyContent="center">
        <ArrowRightIcon color="#aaa" size={12} />
      </Box>

      <Box>
        <Select
          isLoading={isUpdatingRole}
          optionLabel={(role) => role.name}
          optionValue={(role) => role.id}
          options={workspace.roles}
          placeholder="Select role..."
          value={existingRoleMapping?.role.id ?? undefined}
          onChange={async (roleId) => {
            try {
              setIsUpdatingRole(true);

              // if this group is already mapped to a role, we delete the existing mapping.
              if (existingRoleMapping) {
                await deleteSsoGroupRolesMutation.mutateAsync({
                  where: {
                    group_id: {
                      _eq: group.id,
                    },
                    role_id: {
                      _eq: existingRoleMapping.role.id,
                    },
                    workspace_id: {
                      _eq: workspace.id,
                    },
                  },
                });
              }

              // map this group to this role.
              await insertSsoGroupRolesMutation.mutateAsync({
                objects: {
                  role_id: roleId,
                  group_id: group.id,
                  workspace_id: workspace.id,
                },
              });

              await refetch();

              toast({
                id: "sso",
                title: "SSO settings updated",
                variant: "success",
              });
            } catch (error: unknown) {
              toast({
                id: "sso",
                title: "Something went wrong",
                message: "Failed to update SSO settings, please try again.",
                variant: "error",
              });
            } finally {
              setIsUpdatingRole(false);
            }
          }}
        />
      </Box>
    </>
  );
};
