import FuzzySet from "fuzzyset";

import { ColumnOption } from "src/formkit/components/formkit-context";
import { ColumnReference, isColumnReference } from "src/types/visual";
import { flattenOptions } from "src/ui/select/select";
import { StandardFieldType } from "src/utils/destinations";

type BaseField = { label: string; value: string; object?: { label: string; value: string }; required?: boolean };

type StandardField = BaseField & { type: Exclude<StandardFieldType, StandardFieldType.REFERENCE> };
type ReferenceField = BaseField & { type: StandardFieldType.REFERENCE; referenceObjects: { label: string; value: string }[] };

type Field = StandardField | ReferenceField;

export const automap = (columns: ColumnOption[], fields: Field[]) => {
  const flatOptions = flattenOptions(columns);
  const matcher = new FuzzySet(flatOptions.map((f) => (isColumnReference(f.value) ? f.label : f.value)));
  const matched: Mapping[] = [];
  for (const field of fields) {
    const [result] = matcher.get(field.value, [], 0.8);

    if (!result) {
      continue;
    }

    const column = result[1];

    matched.push(getMappingFromField(column, field));
  }

  return matched;
};

export const suggest = (column: { label: string; value: ColumnReference | string }, fields: Field[] = []) => {
  const matcher = new FuzzySet(fields.map((f) => f.value));
  const [result] = matcher.get(isColumnReference(column.value) ? column.label : column.value, [], 0.8);

  if (!result) {
    return { from: column.value, to: null, type: "standard" };
  }

  const field = result[1];

  return getMappingFromField(
    column.value,
    fields.find((f) => field === f.value),
  );
};

interface Mapping {
  lookup?: {
    by: null;
    byType: null;
    from: ColumnReference | string;
    object: unknown;
  };
  from?: ColumnReference | string;
  to: string | undefined;
  object: string | undefined;
  type: "reference" | "standard";
}

export const getMappingFromField = (columnValue, field: Field | undefined): Mapping => {
  if (field?.type === StandardFieldType.REFERENCE) {
    return {
      lookup: { by: null, byType: null, from: columnValue, object: field?.referenceObjects?.[0]?.value || field.value },
      to: field.value,
      object: field?.object?.value,
      type: "reference",
    };
  } else {
    return { from: columnValue, to: field?.value, object: field?.object?.value, type: "standard" };
  }
};
