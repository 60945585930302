import { FC } from "react";

import { Box, BoxProps } from "@hightouchio/ui";

export const BrandHeading: FC<Readonly<BoxProps>> = (props) => {
  return (
    <Box as="h1" fontFamily="'Sharp Sans Display No 1'" fontWeight="bold" lineHeight="1.2" {...props}>
      {props.children}
    </Box>
  );
};
