import React from "react";

import { Controller } from "react-hook-form";
import { Text } from "theme-ui";

import { GitCredentials } from "src/graphql/types";
import { Badge } from "src/ui/badge";
import { Box, Row } from "src/ui/box";
import { Link } from "src/ui/link";
import { Message } from "src/ui/message";
import { Toggle } from "src/ui/toggle";

import { badgeStyles, caretStyles, tooltipStyles } from "../splits/splits";
import { githubCredentialsNeedUpdating } from "./git-credentials-fields";

interface GitChecksToggleProps {
  credentials: GitCredentials | undefined;
  description: string;
}

export const GitChecksToggle = (props: GitChecksToggleProps): React.ReactElement => {
  const blocked = !props.credentials || !(props.credentials.type === "github_app");
  const issue = githubCredentialsNeedUpdating(props.credentials)
    ? "Your GitHub App permissions need to be updated. Please update your Git credentials."
    : 'You must update your Git credentials to use the Hightouch "GitHub app" to enable commit checks.';
  return (
    <>
      <Row sx={{ justifyContent: "space-between", alignItems: "center", pb: 1 }}>
        <Row sx={{ alignItems: "center" }}>
          <Text>GitHub Pull Request checks</Text>
          <Box style={badgeStyles}>
            <Badge
              sx={caretStyles}
              tooltip="This feature is currently in beta. Any feedback is greatly appreciated."
              tooltipSx={tooltipStyles}
              variant="blue"
            >
              Beta
            </Badge>
          </Box>
        </Row>
        <Controller
          name="checks_enabled"
          render={({ field }) => {
            return (
              <Toggle
                disabled={blocked}
                label={field.value ? "Enabled" : "Disabled"}
                value={field.value}
                onChange={(value) => {
                  field.onChange(value);
                }}
              />
            );
          }}
        />
      </Row>
      <Row sx={{ flexDirection: "column", maxWidth: "70%" }}>
        <Row sx={{ pb: 1 }}>
          <Text sx={{ mb: 2, fontSize: "12px", color: "base.5" }}>
            <Text as="p" sx={{ pb: 1 }}>
              {props.description}
            </Text>
            {blocked && (
              <Text as="p">
                <Message variant="warning">{issue}</Message>
              </Text>
            )}
            <span>
              {" "}
              <Link
                newTab
                to="https://docs.github.com/en/pull-requests/collaborating-with-pull-requests/collaborating-on-repositories-with-code-quality-features/about-status-checks"
              >
                Learn more
              </Link>
            </span>
          </Text>
        </Row>
      </Row>
    </>
  );
};
