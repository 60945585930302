import { JsonObject } from "type-fest";

export const basicApiEndpoint = import.meta.env.VITE_API_BASE_URL as string;
export const regionalApiEndpoints = {
  "us-east-1": import.meta.env.VITE_API_REGIONAL_URL_US_EAST_1 as string,
  "eu-west-1": import.meta.env.VITE_API_REGIONAL_URL_EU_WEST_1 as string,
};

export class HttpError extends Error {
  constructor(public readonly code: number, message: string, public readonly body: Record<string, any> = {}) {
    super(message);
  }
}

export class GraphQlError extends Error {
  extensions: JsonObject;
  constructor(message: string, extensions: JsonObject = {}) {
    super(message);
    this.extensions = extensions;
  }
}

export const isFailedResponse = (res: Response): res is Response & { ok: false } => {
  return !res.ok;
};

/**
 * Converts a response to Error object.
 */
export const responseToError = async (res: Response & { ok: false }): Promise<HttpError> => {
  const contentType = res.headers.get("content-type");

  if (contentType && contentType.includes("application/json")) {
    const content = await res.json();
    return new HttpError(res.status, content?.message ?? "Error", content);
  }

  return new HttpError(res.status, await res.text());
};

export const fetcher = <TData, TVariables>(query: string, variables?: TVariables, options?: any): (() => Promise<TData>) => {
  return async () => {
    const headers = {
      "Content-Type": "application/json",
    };

    // Get API endpoint
    const apiRegion = window.Hightouch?.apiRegion;
    const workspaceId = window.Hightouch?.workspaceId;
    const apiEndpoint = apiRegion ? regionalApiEndpoints[apiRegion] : basicApiEndpoint;

    if (workspaceId) {
      headers["X-Hightouch-Requested-Workspace-Id"] = String(workspaceId);
    }

    if (options?.isAdmin) {
      headers["X-Hasura-Role"] = "cs_admin";
    }

    const matches = query.trim().match(/(query|mutation) (.*)\(/);
    if (matches && matches.length === 3) {
      headers["X-Hightouch-resolver-name"] = matches[2];
    }

    const res = await fetch(`${apiEndpoint}/v1/graphql`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        query,
        variables,
      }),
      credentials: "include",
    });

    const json = await res.json();

    if (json.errors) {
      const error = json.errors[0];

      if (!error) {
        throw new Error("Error");
      }

      throw new GraphQlError(error.message, error.extensions);
    }

    return json.data;
  };
};
