import { FC, useState, useCallback, useRef, useEffect, MutableRefObject } from "react";

import { Box, Label, Text, ThemeUIStyleObject } from "theme-ui";

import { Button } from "src/ui/button";
import { Input, TextArea } from "src/ui/input";

import { Row } from "../box";

interface Props {
  optional?: boolean;
  label?: string;
  value: string;
  hideSecret: boolean;
  onChange?: (value: any) => void;
  sx?: ThemeUIStyleObject;
  wrapperSx?: ThemeUIStyleObject;
  multiline?: boolean;
}

export const SensitiveField: FC<Props> = ({
  optional = false,
  label,
  value,
  hideSecret,
  onChange,
  sx = {},
  wrapperSx,
  multiline = false,
}) => {
  const [isEditing, setEditing] = useState(!hideSecret);

  const startEditing = useCallback(() => {
    setEditing(true);
    if (multiline && onChange) {
      onChange("");
    }
  }, []);

  const input = multiline ? useRef<HTMLTextAreaElement | null>(null) : useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (isEditing && hideSecret) {
      input.current?.focus();
    }
  }, [isEditing, hideSecret]);

  return (
    <Box sx={wrapperSx}>
      {label && (
        <Row sx={{ justifyContent: "space-between", mb: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Label variant="label">{label}</Label>
            {optional && <Text sx={{ color: "base.4", fontSize: 1, ml: 2 }}>(optional)</Text>}
          </Box>
        </Row>
      )}
      <Row sx={{ alignItems: "center" }}>
        {multiline ? (
          <TextArea
            ref={input as MutableRefObject<HTMLTextAreaElement>}
            disabled={!isEditing}
            sx={isEditing ? sx : { ...sx, fontSize: "5px", letterSpacing: "2px" }}
            type="password"
            value={isEditing ? value : "●".repeat(12)}
            onChange={onChange}
          />
        ) : (
          <Input
            ref={input as MutableRefObject<HTMLInputElement>}
            disabled={!isEditing}
            sx={sx}
            type="password"
            value={isEditing ? value : "●".repeat(12)}
            onChange={onChange}
          />
        )}
        {hideSecret && !isEditing && (
          <Button sx={{ visibility: isEditing ? "hidden" : undefined, ml: 2 }} variant="secondary" onClick={startEditing}>
            Edit
          </Button>
        )}
      </Row>
    </Box>
  );
};
