import { FC } from "react";

import { Box, Heading, Paragraph, Link, Button } from "@hightouchio/ui";

import BrandLogoLight from "src/components/logos/hightouch-light.svg";
import { CheckList, CheckListItem } from "src/ui/checklist";
import { Confetti } from "src/ui/confetti";
import { Modal } from "src/ui/modal";

type Props = {
  plan: string;
  checkList: string[];
  isOpen?: boolean;
  onClose: () => void;
};

export const PlanWelcomeModal: FC<Readonly<Props>> = ({ plan, checkList, isOpen, onClose }) => {
  return (
    <Modal
      bodySx={{ backgroundColor: "white", pt: 0, pb: 6, px: 6 }}
      footerContent={
        <>
          <Paragraph>
            If you have additional questions, check out our{" "}
            <Link
              href={
                plan === "Enterprise"
                  ? `${import.meta.env.VITE_DOCS_URL}/pricing/bt-pricing`
                  : `${import.meta.env.VITE_DOCS_URL}/pricing/ss-pricing`
              }
            >
              plans and pricing docs
            </Link>{" "}
            for guidance.
          </Paragraph>
          <Button isJustified mt={4} type="button" variant="primary" onClick={onClose}>
            Continue working in Hightouch
          </Button>
        </>
      }
      footerSx={{ border: "none", flexDirection: "column", pt: 0, pb: 6, px: 6 }}
      header={
        <Box>
          <Box alt="" as="img" src={BrandLogoLight} />

          <Heading mt={2} size="xl">
            Welcome to the {plan} Plan!
          </Heading>
        </Box>
      }
      headerSx={{ border: "none" }}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <Confetti showConfetti={true} />
        <Paragraph id="plan-welcome-modal--supports">Your Hightouch plan now supports...</Paragraph>

        <CheckList aria-labelledby="plan-welcome-modal--supports">
          {checkList?.map((listItem, index) => {
            return <CheckListItem key={index}>{listItem}</CheckListItem>;
          })}
        </CheckList>
      </Box>
    </Modal>
  );
};
