import { useState, FC, useMemo } from "react";

import { LinkButton, SearchInput } from "@hightouchio/ui";
import { useSearchParams } from "react-router-dom";
import { Text } from "theme-ui";

import { useObject } from "src/components/audiences/use-object";
import { Permission } from "src/components/permission";
import { SegmentsBoolExp, useEventsQuery, useParentModelsQuery, useRelatedModelsQuery } from "src/graphql";
import useQueryState from "src/hooks/use-query-state";
import { Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { Table } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useNavigate } from "src/utils/navigate";
import { SourceBadges, SourceIcon, useSources } from "src/utils/sources";
import { openUrl } from "src/utils/urls";

export const AudienceObjects: FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const eventCreated = searchParams.get("eventCreated");
  const [showEventWarning, setShowEventWarning] = useState(Boolean(eventCreated));

  const { objectPath, objectName, objectNameCapitalized, objectNameSingular, isParentModel, isEvent, isRelatedModel } =
    useObject();

  const [search, setSearch] = useQueryState("search");
  const hasuraFilters = useMemo(() => {
    const filters: Array<SegmentsBoolExp> = [{ is_schema: { _eq: true } }];

    if (isEvent) {
      filters.push({ event: {} });
    } else {
      filters.push({ _not: { event: {} } }, { primary_key: { _is_null: isRelatedModel } });
    }

    const hasuraFilters: SegmentsBoolExp = { _and: filters };

    if (search) {
      hasuraFilters._and!.push({ name: { _ilike: `%${search}%` } });
    }

    return hasuraFilters;
  }, [search, isEvent, isRelatedModel]);

  const {
    data: parentModelsData,
    error: parentModelsError,
    isLoading: parentModelsLoading,
  } = useParentModelsQuery({ filters: hasuraFilters }, { enabled: isParentModel, keepPreviousData: true });

  const {
    data: eventsData,
    error: eventsError,
    isLoading: eventsLoading,
  } = useEventsQuery({ filters: hasuraFilters }, { enabled: isEvent, keepPreviousData: true });

  const {
    data: relatedModelsData,
    error: relatedModelsError,
    isLoading: relatedModelsLoading,
  } = useRelatedModelsQuery({ filters: hasuraFilters }, { enabled: isRelatedModel, keepPreviousData: true });

  const { data: sources, error: sourcesError, loading: sourcesLoading } = useSources();

  const hideEventWarning = () => setShowEventWarning(false);

  const loading = sourcesLoading || parentModelsLoading || relatedModelsLoading || eventsLoading;

  const objects = (isParentModel ? parentModelsData : isEvent ? eventsData : relatedModelsData)?.segments;
  const error = isParentModel ? parentModelsError : isEvent ? eventsError : relatedModelsError || sourcesError;

  const columns = [
    {
      name: "Name",
      cell: ({ name, connection }) => {
        const source = sources.find((source) => source.id === connection.id);

        return (
          <Row sx={{ alignItems: "center" }}>
            <SourceIcon source={source} sx={{ width: "20px", maxHeight: "100%", objectFit: "contain", mr: 2 }} />
            <Text sx={{ fontWeight: "semi" }}>{name}</Text>
            <SourceBadges source={source} />
          </Row>
        );
      },
    },
    LastUpdatedColumn,
  ];

  if (loading) {
    return <PageSpinner />;
  }

  return (
    <>
      <Row
        sx={{
          justifyContent: "space-between",
          mt: 2,
          mb: 3,
        }}
      >
        <SearchInput placeholder="Search by name..." value={search ?? ""} onChange={(event) => setSearch(event.target.value)} />
        <Permission>
          <LinkButton href={`/audiences/setup/${objectPath}/new`} variant="primary">
            Add {objectNameSingular}
          </LinkButton>
        </Permission>
      </Row>
      <Table
        columns={columns}
        data={objects}
        error={Boolean(error)}
        placeholder={{
          title: `No ${objectName}`,
          body: `Add a ${objectNameSingular} to get started`,
          error: `${objectNameCapitalized} failed to load, please try again.`,
        }}
        onRowClick={({ id }, event) => openUrl(`/audiences/setup/${objectPath}/${id}`, navigate, event)}
      />

      <Modal
        bodySx={{ pb: 6 }}
        footer={
          <>
            <Button variant="secondary" onClick={hideEventWarning}>
              Go back
            </Button>
            <LinkButton href="/audiences/setup/parent-models" variant="primary">
              Go to parent models
            </LinkButton>
          </>
        }
        isOpen={showEventWarning}
        sx={{ maxWidth: "400px" }}
        title="Finish event setup"
        onClose={hideEventWarning}
      >
        <Text as="p">To use this event, please add a relationship to the event from the parent model.</Text>
      </Modal>
    </>
  );
};
