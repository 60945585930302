import { FC, useState, useEffect, ReactNode } from "react";

import { useToasts } from "react-toast-notifications2";
import { Box, Grid, Text, Textarea } from "theme-ui";

import { DestinationDefinitionFragment as DestinationDefinition } from "src/graphql";
import * as analytics from "src/lib/analytics";
import { newIntercomMessage } from "src/lib/intercom";
import { Button } from "src/ui/button";
import { ChatMonoIcon, LockIcon, SendMonoIcon } from "src/ui/icons";

export type DestinationAccessCardProps = {
  category: string;
  definition: DestinationDefinition | null;
  onSelect?: (destination: DestinationDefinition | null, hit: any) => void;
};

export const DestinationAccessCard: FC<Readonly<DestinationAccessCardProps>> = ({ category, definition, onSelect }) => {
  const [formVisible, setFormVisible] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    if (definition?.name) {
      setFormVisible(false);
    }
  }, [definition?.name]);

  const setDestinationRequestIntent = () => {
    analytics.track("Destination Catalog Alpha Request Clicked", {
      destination_name: definition?.name,
      destination_slug: definition?.type,
      destination_status: definition?.status,
      current_category: category,
    });
    setFormVisible(true);
  };

  const submitDestinationRequest = (event) => {
    event.preventDefault();
    analytics.track("Destination Catalog Alpha Request Form Submitted", {
      destination_name: definition?.name,
      destination_slug: definition?.type,
      destination_status: definition?.status,
      current_category: category,
      request_form_text: event.target.elements?.usageRequest?.value,
    });
    // close sidebar and deselect definition
    if (onSelect) {
      onSelect(null, null);
    }
    addToast(`Thank you for requesting early access to ${definition?.name}! We will follow up with you shortly.`, {
      appearance: "success",
    });
  };

  if (definition?.status === "alpha") {
    return (
      <ThemedCard theme="base">
        <Text>
          This destination is in <strong>private preview</strong>.
        </Text>
        <Text sx={{ my: 5 }}>We are working with a small group of users to verify functionality.</Text>
        {!formVisible && (
          <ThemedButton type="button" onClick={setDestinationRequestIntent}>
            <LockIcon color="base.4" size={16} />
            Request early access
          </ThemedButton>
        )}
        {formVisible && (
          <Box as="form" onSubmit={(event) => submitDestinationRequest(event)}>
            <Textarea
              aria-label={`Tell us about your ${definition?.name} use case. (optional)`}
              my={6}
              name="usageRequest"
              placeholder={`Tell us about your ${definition?.name} use case. (optional)`}
              rows={6}
              sx={{ borderColor: "base.3" }}
            />
            <ThemedButton type="submit">
              <SendMonoIcon color="base.4" size={16} />
              Apply for access
            </ThemedButton>
          </Box>
        )}
      </ThemedCard>
    );
  }
  if (definition?.status === "beta") {
    return (
      <ThemedCard theme="pink">
        <Grid gap={5}>
          <Text>
            This destination is in <strong>beta</strong>.
          </Text>
          <Text>Some functionality may change as we receive feedback and test more edge cases.</Text>
          <Text>
            Have questions or feature requests related to <strong>{definition?.name}</strong>?
          </Text>
          <ThemedButton
            type="button"
            onClick={() => {
              analytics.track("Destination Catalog Beta Intercom Clicked", {
                destination_name: definition?.name,
                destination_slug: definition?.type,
                destination_status: definition?.status,
                current_category: category,
              });
              newIntercomMessage(`Hi, I have a question about the ${definition?.name} destination.`);
            }}
          >
            <ChatMonoIcon color="base.4" size={16} />
            Chat with us
          </ThemedButton>
        </Grid>
      </ThemedCard>
    );
  }
  return null;
};

const ThemedCard: FC<Readonly<{ theme: "pink" | "base"; children: ReactNode }>> = ({ theme, children }) => (
  <Box
    sx={{
      bg: theme === "base" ? "base.0" : "pinks.0",
      border: "small",
      borderColor: theme === "base" ? "base.3" : "pinks.3",
      borderRadius: 1,
      color: "base.8",
      p: 5,
    }}
  >
    {children}
  </Box>
);

type ButtonProps = {
  children: ReactNode;
  type?: "button" | "submit";
  onClick?: () => void;
};

const ThemedButton: FC<Readonly<ButtonProps>> = ({ children, ...props }) => (
  <Button sx={{ alignItems: "center", bg: "white", gap: 2, fontSize: 1, width: "100%" }} variant="secondary" {...props}>
    {children}
  </Button>
);
