import { FC, useEffect, useState } from "react";

import { useParams, useNavigate } from "react-router-dom";
import { useToasts } from "react-toast-notifications2";
import { Text } from "theme-ui";

import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { isQueryDraft } from "src/components/drafts/model-draft";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { OverageModal } from "src/components/modals/overage-modal";
import { SaveWarning } from "src/components/modals/save-warning";
import { ColumnSelect } from "src/components/models/column-select";
import { ColumnSettings } from "src/components/models/column-settings";
import { Query } from "src/components/models/query";
import { Syncs } from "src/components/models/syncs";
import { Permission } from "src/components/permission";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ModelDraft,
  ModelQuery,
  ResourcePermissionGrant,
  ResourceToPermission,
  useDeleteModelMutation,
  useModelQuery,
  useUpdateModelMutation,
} from "src/graphql";
import * as analytics from "src/lib/analytics";
import { Avatar } from "src/ui/avatar";
import { SquareBadge } from "src/ui/badge";
import { Row, Column } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field } from "src/ui/field";
import { Heading } from "src/ui/heading";
import { ChevronDownIcon, LookerIcon, PlusIcon } from "src/ui/icons";
import { InlineInput } from "src/ui/input";
import { PageSpinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { Popout } from "src/ui/popout";
import { Tabs } from "src/ui/tabs";
import { QueryType, useModelState } from "src/utils/models";
import { SourceTile, useSource } from "src/utils/sources";
import { formatDate } from "src/utils/time";

import { useLabels } from "../../components/labels/use-labels";
import { useModelSort } from "./use-model-sort";

enum Tab {
  QUERY = "Query",
  CONFIGURATION = "Configuration",
  SYNCS = "Syncs",
  COLUMNS = "Columns",
}

export const ModelWrapper: FC = () => {
  const { model_id: id } = useParams<{ model_id?: string }>();
  const { columnsOrderBy, syncsOrderBy } = useModelSort();

  const {
    data: modelData,
    isLoading: modelLoading,
    refetch,
  } = useModelQuery({ id: id ?? "", syncsOrderBy, columnsOrderBy }, { enabled: Boolean(id) });

  if (!id) {
    return <PageSpinner />;
  }

  return (
    <DraftProvider
      initialResourceIsDraft={modelData?.segments_by_pk?.draft || false}
      resourceId={id}
      resourceType={ResourceToPermission.Model}
    >
      <Model modelData={modelData} modelLoading={modelLoading} refetchModel={refetch} />
    </DraftProvider>
  );
};

interface ModelProps {
  modelData: ModelQuery | undefined;
  modelLoading: boolean;
  refetchModel: () => void;
}

const Model: FC<ModelProps> = ({ modelData, modelLoading, refetchModel }: ModelProps) => {
  const { addToast } = useToasts();
  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.QUERY);
  const { modelState, setPrimaryKey, initModelState } = useModelState();
  const [tableLoading, setTableLoading] = useState(false);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const {
    setSubmitDraftModalOpen,
    updateResourceOrDraft,
    editingDraft,
    draft,
    editingDraftChanges,
    mergeResourceWithDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();
  const [model, setModel] = useState<ModelQuery["segments_by_pk"]>();

  const { columnsOrderBy, curriedOnSort, syncsOrderBy } = useModelSort();

  const id = modelData?.segments_by_pk?.id;

  const { labels } = useLabels();

  const { mutateAsync: updateModel, isLoading: updating } = useUpdateModelMutation();
  const { mutateAsync: deleteModel, isLoading: deleting } = useDeleteModelMutation();

  const currentLabels = model?.tags ?? {};
  const labelKeys = Object.keys(currentLabels);
  const type = model?.query_type;
  const syncs = model?.syncs;

  const draftResource = draft?.new_resource ? (draft.new_resource as ModelDraft) : undefined;
  const columns = editingDraft ? draftResource?.modelColumns ?? model?.columns ?? [] : model?.columns ?? [];

  const { data: source, loading: sourceLoading } = useSource(model?.connection?.id);

  useEffect(() => {
    const model = modelData?.segments_by_pk;
    if (draft && model) {
      const copy = mergeResourceWithDraft(model);
      setModel(copy as ModelQuery["segments_by_pk"]);
      initModelState(copy);
    } else {
      setModel(model);
      initModelState(model);
    }
  }, [modelData, editingDraft]);

  const onUpdate = () => {
    analytics.track("Model Updated", {
      model_id: model?.id,
      model_type: type,
      model_name: model?.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    addToast("Model updated!", {
      appearance: "success",
    });
    refetchModel();
  };

  const updateName = async (name: string) => {
    if (!model) {
      return;
    }

    await updateModel({
      id: model.id,
      input: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });

    onUpdate();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    if (!id) {
      return;
    }

    try {
      await updateModel({
        id: id,
        input: {
          tags: labels,
        },
      });

      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      addToast(`Label update failure. ${error.message}`, { appearance: "error", autoDismiss: false });
    }
  };

  const update = async () => {
    if (!model) {
      return;
    }

    const updatePayload = {
      primary_key: modelState?.primaryKey,
      updated_by: user?.id != null ? String(user?.id) : undefined,
    };

    updateResourceOrDraft &&
      (await updateResourceOrDraft(
        { _set: updatePayload },
        onUpdate,
        async () => {
          await updateModel({
            id: model?.id,
            input: updatePayload,
          });
        },
        model.draft,
      ));
    setSubmitDraftModalOpen(true);
  };

  const dirty = modelState?.primaryKey !== model?.primary_key;

  useEffect(() => {
    setTableLoading(true);
  }, [columnsOrderBy, syncsOrderBy]);

  useEffect(() => {
    setTableLoading(false);
  }, [model]);

  const hasPrimaryKeyIssue = Boolean(model?.columns?.length && !model?.columns.some((c) => c.name === model?.primary_key));

  const TABS = [
    editingDraft && draftResource?._set && isQueryDraft(draftResource?._set)
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Query <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.QUERY,
        }
      : Tab.QUERY,
    editingDraft && draftResource?._set?.primary_key
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Configuration <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.CONFIGURATION,
        }
      : Tab.CONFIGURATION,
    {
      render: () => (
        <Row sx={{ alignItems: "center" }}>
          <Text>Syncs</Text>
          {Array.isArray(syncs) && syncs.length > 0 && <SquareBadge sx={{ ml: 2 }}>{syncs.length}</SquareBadge>}
        </Row>
      ),
      value: Tab.SYNCS,
    },
    editingDraft && draftResource?.modelColumns
      ? {
          render: () => (
            <Row sx={{ alignItems: "center" }}>
              Columns <DraftCircle sx={{ ml: 2 }} />
            </Row>
          ),
          value: Tab.COLUMNS,
        }
      : Tab.COLUMNS,
  ].filter(Boolean);

  if (sourceLoading || modelLoading) {
    return <PageSpinner />;
  }

  if (!sourceLoading && !modelLoading && (!modelData || !modelData.segments_by_pk)) {
    return <Warning subtitle="It may have been deleted" title="Model not found" />;
  }
  const updatedByUsername = model?.updated_by_user?.name || model?.created_by_user?.name;

  return (
    <>
      <PermissionProvider
        permissions={workspace?.approvals_required ? [] : [{ resource: "model", grants: [ResourcePermissionGrant.Update] }]}
      >
        <Page
          crumbs={[
            { label: "Models", link: "/models" },
            {
              label: model?.name ?? "",
            },
          ]}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Model}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          size="medium"
        >
          <Column sx={{ width: "100%", height: "100%" }}>
            <Column sx={{ mb: 6 }}>
              <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                <InlineInput value={model?.name} onChange={updateName} />
                <Row gap={2} sx={{ alignItems: "center", ml: 8 }}>
                  <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Delete] }]}>
                    <Button
                      label="Delete"
                      variant="secondary"
                      onClick={() => {
                        setDeleteModal(true);
                      }}
                    />
                  </Permission>
                  <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}>
                    <Button
                      label="Clone"
                      variant="secondary"
                      onClick={() => {
                        navigate(`/models/${model?.id}/clone`);
                      }}
                    />
                  </Permission>
                  <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                    <Button variant="secondary" onClick={() => navigate(`/syncs/new?model=${id}`)}>
                      Add sync
                    </Button>
                  </Permission>
                </Row>
              </Row>
              <Row sx={{ mt: 2 }}>
                <Row sx={{ alignItems: "center", mr: 4, pr: 4, borderRight: "small" }}>
                  {source && <SourceTile iconSx={{ width: "20px" }} source={source} />}
                </Row>
                <Row sx={{ alignItems: "center" }}>
                  <Text sx={{ mr: 1, color: "base.7" }}>Last updated:</Text>
                  <Text sx={{ mr: 1 }}>
                    {formatDate(model?.updated_at || model?.created_at)}
                    {updatedByUsername && " by"}
                  </Text>
                  {updatedByUsername && <Avatar name={updatedByUsername} />}
                </Row>
                <Row sx={{ borderLeft: "small", ml: 4, pl: 4, alignItems: "center" }}>
                  <Text sx={{ mr: 1, color: "base.7" }}>Slug:</Text>
                  <DisplaySlug currentSlug={model?.slug} />
                </Row>
                {!editingDraftChanges &&
                  (labelKeys.length > 0 ? (
                    <Row sx={{ height: "100%", alignItems: "center", pl: 4, ml: 4, borderLeft: "small" }}>
                      <Popout
                        content={({ close }) => (
                          <>
                            <Labels labels={currentLabels} sx={{ maxWidth: "200px" }} />
                            <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Update] }]}>
                              <Button
                                sx={{ mt: 4 }}
                                variant="secondary"
                                onClick={() => {
                                  setIsEditLabelModalOpen(true);
                                  close();
                                }}
                              >
                                Edit labels
                              </Button>
                            </Permission>
                          </>
                        )}
                        contentSx={{ p: 3, minWidth: "90px" }}
                      >
                        <Text sx={{ mr: 1 }}>Labels</Text>
                        <SquareBadge>{Object.keys(labels || {}).length}</SquareBadge>
                        <ChevronDownIcon size={16} sx={{ ml: 2 }} />
                      </Popout>
                    </Row>
                  ) : (
                    <Row sx={{ pl: 4, ml: 4, borderLeft: "small" }}>
                      <Button
                        iconBefore={<PlusIcon color="gray.700" size={14} />}
                        size="small"
                        variant="secondary"
                        onClick={() => {
                          setIsEditLabelModalOpen(true);
                        }}
                      >
                        Add labels
                      </Button>
                    </Row>
                  ))}
              </Row>
            </Column>

            <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

            {hasPrimaryKeyIssue && (
              <Message sx={{ width: "100%", maxWidth: "100%", mb: 8 }} variant="warning">
                <Field label="Looks like your primary key is set to an undefined column.">
                  As a result, your syncs may fail or undefined behavior may occur, go to the Configuration tab to select a new
                  primary key.
                </Field>
              </Message>
            )}

            {tab === Tab.QUERY && (
              <Column sx={{ gap: 6 }}>
                <Row sx={{ alignItems: "center", justifyContent: "space-between", flex: 1 }}>
                  <Heading>
                    <Row>
                      {type === QueryType.Dbt || type === QueryType.DbtModel
                        ? "dbt Model"
                        : type === QueryType.LookerLook
                        ? "This model is based on a Look"
                        : "Query"}
                      {type === QueryType.LookerLook && <LookerIcon size={24} />}
                    </Row>
                  </Heading>
                  <Permission>
                    <Button
                      variant="secondary"
                      onClick={() => navigate(editingDraft ? `/models/${id}/query?editing=true` : `/models/${id}/query`)}
                    >
                      Edit
                    </Button>
                  </Permission>
                </Row>
                <Query model={model} />
              </Column>
            )}

            {tab === Tab.CONFIGURATION && (
              <Row sx={{ justifyContent: "space-between" }}>
                <Column sx={{ flex: 1, maxWidth: "500px" }}>
                  <Text sx={{ fontWeight: "semi", fontSize: 3, mb: 6 }}>Configuration</Text>
                  <Field label="Primary key">
                    {model && (
                      <ColumnSelect columns={columns} model={model} value={modelState?.primaryKey} onChange={setPrimaryKey} />
                    )}
                  </Field>
                </Column>
                <Permission
                  permissions={
                    workspace?.approvals_required ? [] : [{ resource: "model", grants: [ResourcePermissionGrant.Update] }]
                  }
                >
                  <Button disabled={!dirty} label="Save changes" loading={updating} onClick={update} />
                </Permission>
              </Row>
            )}

            {tab === Tab.SYNCS && (
              <Syncs
                isAudience={false}
                loading={modelLoading || tableLoading}
                orderBy={syncsOrderBy}
                syncs={syncs}
                onAdd={() => {
                  navigate(`/syncs/new?model=${id}`);
                }}
                onSort={curriedOnSort("syncs")}
              />
            )}

            {tab === Tab.COLUMNS && (
              <ColumnSettings
                columns={columns}
                isDraft={model?.draft || false}
                loading={modelLoading || tableLoading}
                modelId={id}
                orderBy={columnsOrderBy}
                onSort={curriedOnSort("columns")}
              />
            )}
          </Column>
        </Page>
      </PermissionProvider>
      <Modal
        footer={
          <>
            <Button
              variant="secondary"
              onClick={() => {
                setDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              loading={deleting}
              variant="red"
              onClick={async () => {
                if (!id) {
                  return;
                }

                await deleteModel({
                  id,
                });

                analytics.track("Model Deleted", {
                  model_id: model?.id,
                  model_type: type,
                  model_name: model?.name,
                  source_id: source?.id,
                  source_type: source?.type,
                });

                addToast("Model deleted successfully!", {
                  appearance: "success",
                });

                navigate("/models");
              }}
            >
              Delete
            </Button>
          </>
        }
        isOpen={deleteModal}
        sx={{ width: "500px" }}
        title="Delete model"
        onClose={() => {
          setDeleteModal(false);
        }}
      >
        <Text>Are you sure you want to delete this model? You won't be able to undo this.</Text>
      </Modal>

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels ?? {}}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      <OverageModal />

      <SaveWarning dirty={dirty && !deleteModal} />
    </>
  );
};
