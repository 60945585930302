import { FC } from "react";

import { Grid, Box, Flex } from "theme-ui";
import * as Yup from "yup";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { useGoogleCampaignManagerFloodlightActivitiesQuery, useGoogleCampaignManagerProfilesQuery } from "src/graphql";
import { Checkbox } from "src/ui/checkbox";
import { Field } from "src/ui/field";
import { Input } from "src/ui/input";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { Select } from "src/ui/select";
import { Toggle } from "src/ui/toggle";
import { COMMON_SCHEMAS, StandardFieldType } from "src/utils/destinations";

import { MappingsField } from "../mappings-field";
import { ObjectField } from "../object-field";

// https://developers.google.com/doubleclick-advertisers/rest/v3.5/Conversion
const MAPPINGS = {
  Conversion: [
    { label: "Encrypted User Id", value: "encryptedUserId", type: StandardFieldType.STRING },
    { label: "Mobile Device Id", value: "mobileDeviceId", type: StandardFieldType.STRING },
    { label: "Timestamp (In micros)", value: "timestampMicros", type: StandardFieldType.NUMBER },
    { label: "Timestamp", value: "timestamp", type: StandardFieldType.DATETIME },
    { label: "Value", value: "value", type: StandardFieldType.NUMBER },
    { label: "Quantity", value: "quantity", type: StandardFieldType.NUMBER },
    { label: "Ordinal", value: "ordinal", type: StandardFieldType.STRING },
    { label: "Limit Ad Tracking", value: "limitAdTracking", type: StandardFieldType.BOOLEAN },
    { label: "User under the age of 13 (COPPA)", value: "childDirectedTreatment", type: StandardFieldType.BOOLEAN },
    { label: "Encrypted User Id Candidates", value: "encryptedUserIdCandidates", type: StandardFieldType.ARRAY },
    { label: "Google Click Id", value: "gclid", type: StandardFieldType.STRING },
    { label: "Is not personalized ad", value: "nonPersonalizedAd", type: StandardFieldType.BOOLEAN },
    { label: "User under the age of 16 (COPPA)", value: "treatmentForUnderage", type: StandardFieldType.BOOLEAN },
    { label: "Match Id", value: "matchId", type: StandardFieldType.STRING },
    { label: "Display Click Id", value: "dclid", type: StandardFieldType.STRING },
  ],
  ConversionAdjustment: [
    { label: "Encrypted User Id", value: "encryptedUserId", type: StandardFieldType.STRING },
    { label: "Mobile Device Id", value: "mobileDeviceId", type: StandardFieldType.STRING },
    { label: "Timestamp (In micros)", value: "timestampMicros", type: StandardFieldType.NUMBER },
    { label: "Timestamp", value: "timestamp", type: StandardFieldType.DATETIME },
    { label: "Value", value: "value", type: StandardFieldType.NUMBER },
    { label: "Quantity", value: "quantity", type: StandardFieldType.NUMBER },
    { label: "Ordinal", value: "ordinal", type: StandardFieldType.STRING },
    { label: "Limit Ad Tracking", value: "limitAdTracking", type: StandardFieldType.BOOLEAN },
    { label: "User under the age of 13 (COPPA)", value: "childDirectedTreatment", type: StandardFieldType.BOOLEAN },
    { label: "Google Click Id", value: "gclid", type: StandardFieldType.STRING },
    { label: "Is not personalized ad", value: "nonPersonalizedAd", type: StandardFieldType.BOOLEAN },
    { label: "User under the age of 16 (COPPA)", value: "treatmentForUnderage", type: StandardFieldType.BOOLEAN },
    { label: "Match Id", value: "matchId", type: StandardFieldType.STRING },
    { label: "Display Click Id", value: "dclid", type: StandardFieldType.STRING },
  ],
};

const CUSTOM_MAPPINGS = Array.from({ length: 100 }, (_, i) => 1 + i).map((i) => {
  return { label: `Custom Variable u${i}`, value: `customVariables.u${i}` };
});

const ENCRYPTION_ENTITY_TYPES = [
  { value: "ENCRYPTION_ENTITY_TYPE_UNKNOWN", label: "Unknown" },
  { value: "DCM_ACCOUNT", label: "DCM Account" },
  { value: "DCM_ADVERTISER", label: "DCM Advertiser" },
  { value: "DBM_PARTNER", label: "DBM Partner" },
  { value: "DBM_ADVERTISER", label: "DBM Advertiser" },
  { value: "ADWORDS_CUSTOMER", label: "Adwords Customer" },
  { value: "DFP_NETWORK_CODE", label: "DFP Network Code" },
];

const ENCRYPTION_SOURCES = [
  { value: "ENCRYPTION_SCOPE_UNKNOWN", label: "Unknown" },
  { value: "AD_SERVING", label: "Ad Serving" },
  { value: "DATA_TRANSFER", label: "Data Transfer" },
];

const OBJECTS = [
  { label: "Conversions", value: "Conversion" },
  { label: "Conversion Adjustments", value: "ConversionAdjustment" },
];

function requireEncryptionInfo(mappings: { from: string; to: string }[]): boolean {
  return mappings.filter((m) => ["encryptedUserId", "encryptedUserIdCandidates"].includes(m.to)).length > 0;
}

const validateEncryptionInfo = Yup.string().when("mappings", {
  is: requireEncryptionInfo,
  then: Yup.string().required(),
  otherwise: Yup.string().notRequired(),
});

export const validation = Yup.object().shape({
  profileId: Yup.string().required(),
  object: Yup.string().oneOf(["Conversion", "ConversionAdjustment"]).required(),
  mode: Yup.string().default("insert").required(),
  mappings: COMMON_SCHEMAS.mappings,
  customMappings: COMMON_SCHEMAS.mappings,
  floodlightActivityId: COMMON_SCHEMAS.standardOrStaticMapping,
  floodlightConfigurationId: COMMON_SCHEMAS.standardOrStaticMapping,
  encryptionEntityType: validateEncryptionInfo,
  encryptionEntityId: validateEncryptionInfo,
  encryptionSource: validateEncryptionInfo,
});

export const GoogleCampaignManagerForm: FC = () => {
  const { errors, config, setConfig, destination } = useDestinationForm();

  const {
    data: profilesData,
    error: profilesError,
    isFetching: loadingProfiles,
    refetch: listProfiles,
  } = useGoogleCampaignManagerProfilesQuery({
    destinationId: String(destination?.id),
  });

  const {
    data: floodlightActivitiesData,
    error: floodlightActivitiesError,
    isFetching: loadingFloodlightActivities,
    refetch: listFloodlightActivities,
  } = useGoogleCampaignManagerFloodlightActivitiesQuery(
    {
      destinationId: String(destination?.id),
      profileId: config?.profileId,
    },
    { enabled: Boolean(config?.profileId) },
  );

  return (
    <>
      <Section>
        <Field
          description={"Select the profile you want to send data to."}
          error={profilesError || errors?.profileId}
          label={"Which profile would you like to sync to?"}
          loading={loadingProfiles}
          reload={listProfiles}
          size="large"
        >
          <Grid gap={2}>
            {profilesData?.googleCampaignManagerListProfiles?.map((profile, i) => (
              <Box key={i}>
                <Checkbox
                  label={`${profile?.name} (${profile?.id})`}
                  value={config?.profileId === profile?.id}
                  onChange={() => setConfig({ profileId: profile?.id })}
                />
              </Box>
            ))}
          </Grid>
        </Field>
      </Section>

      {config?.profileId && (
        <ObjectField
          options={OBJECTS}
          value={config?.object}
          onChange={(selected) => setConfig({ profileId: config.profileId, object: selected })}
        />
      )}

      {config?.profileId && config?.object && (
        <FloodlightActivitySelector
          error={floodlightActivitiesError}
          floodlightActivities={floodlightActivitiesData?.googleCampaignManagerListFloodlightActivities}
          loading={loadingFloodlightActivities}
          reload={listFloodlightActivities}
        />
      )}

      {config?.profileId && config?.object && config?.floodlightActivityId && (
        <>
          <Section>
            <MappingsField options={MAPPINGS[config.object]} />
          </Section>

          {config.object === "Conversion" && (
            <Section>
              <MappingsField isCustom options={CUSTOM_MAPPINGS} />
            </Section>
          )}

          {config.mappings && requireEncryptionInfo(config.mappings) && (
            <Section>
              <Grid gap={8}>
                <Field
                  description="This should match the encryption configuration for ad serving or Data Transfer."
                  label="What is the encryption entity type?"
                >
                  <Select
                    options={ENCRYPTION_ENTITY_TYPES}
                    placeholder="Select a entity type..."
                    value={
                      config.encryptionEntityType
                        ? ENCRYPTION_ENTITY_TYPES.find((type) => type.value === config.encryptionEntityType)
                        : null
                    }
                    width="240px"
                    onChange={(selected) => setConfig({ ...config, encryptionEntityType: selected.value })}
                  />
                </Field>

                <Field
                  description="This should match the encryption configuration for ad serving or Data Transfer."
                  label="What is the encryption entity Id?"
                >
                  <Input
                    placeholder={"Encryption entity ID"}
                    sx={{ width: "180px" }}
                    value={config.encryptionEntityId}
                    onChange={(encryptionEntityId) => setConfig({ ...config, encryptionEntityId })}
                  />
                </Field>
                <Field
                  description="Describes whether the encrypted cookie was received from ad serving (the %m macro) or from Data Transfer."
                  label="What is the encryption source?"
                >
                  <RadioGroup
                    options={ENCRYPTION_SOURCES}
                    value={config?.encryptionSource}
                    onChange={(encryptionSource) => setConfig({ ...config, encryptionSource })}
                  />
                </Field>
              </Grid>
            </Section>
          )}
        </>
      )}
    </>
  );
};

const FloodlightActivitySelector = ({ floodlightActivities, loading, reload, error }) => {
  const { config, setConfig, errors, hightouchColumns, loadingModel, reloadModel } = useDestinationForm();

  const floodlightActivityId = config?.floodlightActivityId;
  const valueIsColumn = floodlightActivityId && floodlightActivityId["type"] === "standard";

  return (
    <Field
      description={
        valueIsColumn
          ? "Select the columns that will contain the floodlight activity Id and configuration Id for each conversion"
          : "Select the floodlight activity that are conversions belong to."
      }
      error={
        errors?.floodlightActivityId ||
        errors?.floodlightConfigurationId ||
        errors?.["floodlightActivityId"] ||
        errors?.["floodlightConfigurationId"] ||
        error
      }
      label={
        valueIsColumn
          ? "Which columns contain the Floodlight Activity and Configuration?"
          : "Which Floodlight Activity are the conversions related to?"
      }
      loading={loadingModel || loading}
      size="large"
    >
      <Flex sx={{ alignItems: "center" }}>
        {valueIsColumn ? (
          <Grid gap={8}>
            <Select
              isError={Boolean(errors?.["floodlightActivityId"])}
              isLoading={loadingModel}
              options={hightouchColumns}
              placeholder="Floodlight Activity Id..."
              reload={reloadModel}
              sx={{ maxWidth: "280px" }}
              value={floodlightActivityId?.from}
              width="280px"
              onChange={(selected) => {
                const mapping = selected?.value
                  ? { from: selected.value, to: "floodlightActivityId", type: "standard" }
                  : undefined;
                setConfig({ ...config, floodlightActivityId: mapping });
              }}
            />
            <Select
              isError={Boolean(errors?.["floodlightConfigurationId"])}
              isLoading={loadingModel}
              options={hightouchColumns}
              placeholder="Floodlight Configuration Id..."
              reload={reloadModel}
              sx={{ maxWidth: "280px" }}
              value={config?.floodlightConfigurationId?.from}
              width="280px"
              onChange={(selected) => {
                const mapping = selected?.value
                  ? { from: selected.value, to: "floodlightConfigurationId", type: "standard" }
                  : undefined;
                setConfig({ ...config, floodlightConfigurationId: mapping });
              }}
            />
          </Grid>
        ) : (
          <Select
            isError={Boolean(errors?.["floodlightActivityId"]) || Boolean(errors?.["floodlightConfigurationId"])}
            isLoading={loading}
            options={floodlightActivities?.map((activity) => {
              return { label: activity.name, value: activity.id };
            })}
            placeholder="Select a Floodlight Activity"
            reload={reload}
            sx={{ maxWidth: "280px" }}
            value={floodlightActivities?.find((activity) => activity.id === config?.floodlightActivityId?.value)?.id || null}
            width="280px"
            onChange={(selected) => {
              if (selected?.value) {
                const floodlightActivity = floodlightActivities.find((activity) => activity.id === selected.value);
                if (floodlightActivity) {
                  setConfig({
                    ...config,
                    floodlightActivityId: {
                      type: "static",
                      to: "floodlightActivityId",
                      value: floodlightActivity.id,
                      valueType: "STRING",
                    },
                    floodlightConfigurationId: {
                      type: "static",
                      to: "floodlightConfigurationId",
                      value: floodlightActivity.floodlightConfigurationId,
                      valueType: "STRING",
                    },
                  });
                  return;
                }
              }

              setConfig({ ...config, floodlightActivityId: undefined, floodlightConfigurationId: undefined });
            }}
          />
        )}
        <Toggle
          label={"Use columns"}
          sx={{ ml: 4 }}
          value={valueIsColumn}
          onChange={(value) => {
            if (value) {
              setConfig({
                ...config,
                floodlightActivityId: { type: "standard", to: "floodlightActivityId", from: undefined },
                floodlightConfigurationId: { type: "standard", to: "floodlightConfigurationId", from: undefined },
              });
            } else {
              setConfig({
                ...config,
                floodlightActivityId: { type: "static", to: "floodlightActivityId", valueType: "STRING", value: undefined },
                floodlightConfigurationId: {
                  type: "static",
                  to: "floodlightConfigurationId",
                  valueType: "STRING",
                  value: undefined,
                },
              });
            }
          }}
        />
      </Flex>
    </Field>
  );
};

export default {
  form: GoogleCampaignManagerForm,
  validation,
};
