import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import { useLDClient, withLDProvider } from "launchdarkly-react-client-sdk";

import { useUser, UserContextType } from "./user-context";

const clientSideID = import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID?.toString() ?? "";

interface ContainerProps {
  children: ReactNode;
}

const Container: FC<ContainerProps> = ({ children }) => <>{children}</>;

interface LoaderProps {
  children: ReactNode;
}

const Loader: FC<LoaderProps> = ({ children }) => {
  const { user, workspace } = useUser();

  if (!user) {
    return <>{children}</>;
  }

  return (
    <FeatureFlagProvider user={user} workspace={workspace}>
      {children}
    </FeatureFlagProvider>
  );
};

interface FeatureFlagProviderProps {
  children: ReactNode;
  user: NonNullable<UserContextType["user"]>;
  workspace: UserContextType["workspace"];
}

export const FeatureFlagProvider: FC<FeatureFlagProviderProps> = ({ user, workspace, children }) => {
  const Provider = useMemo(
    () =>
      withLDProvider<ContainerProps>({
        clientSideID,

        // Configure user session.
        user: {
          key: `user-${user.id.toString()}`,
          name: user.name,
          email: user.email,
          custom: {
            workspaceId: workspace?.id,
          },
        },
      })(Container),
    [user.id, user.name, user.email, workspace?.id], // We only need to change if the workspace.id changes, other wise any change to workspace e.g. onboarding will cause a complete rerender
  );

  return (
    <Provider>
      <FeatureFlagLoader>{children}</FeatureFlagLoader>
    </Provider>
  );
};

interface FeatureFlagLoaderProps {
  children: ReactNode;
}

const FeatureFlagLoader: FC<FeatureFlagLoaderProps> = ({ children }) => {
  const launchDarkly = useLDClient();
  const [ready, setReady] = useState<boolean>(false);

  useEffect(() => {
    if (launchDarkly) {
      launchDarkly.waitUntilReady().then(() => setReady(true));
    }
  }, [launchDarkly]);

  if (ready) {
    return <>{children}</>;
  }

  return null;
};

export default Loader;
