import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const ChatIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M12.02,0C5.05,0,0,5.69,0,12c0,2.02,.59,4.09,1.62,5.99,.19,.31,.22,.71,.08,1.08l-.8,2.69c-.18,.65,.37,1.13,.98,.94l2.42-.72c.66-.22,1.18,.06,1.79,.43,1.75,1.03,3.93,1.56,5.9,1.56,5.95,0,12-4.6,12-12C24,5.58,18.84,0,12.02,0Z"
        opacity={0.4}
      />
      <path d="M12,13.52c-.85-.01-1.54-.7-1.54-1.55s.7-1.54,1.54-1.52c.85,0,1.54,.68,1.54,1.54s-.68,1.54-1.54,1.54Zm-5.53,0c-.84,0-1.54-.7-1.54-1.54s.68-1.54,1.54-1.54,1.54,.68,1.54,1.54-.68,1.52-1.54,1.54Zm9.53-1.54c0,.84,.68,1.54,1.54,1.54s1.54-.7,1.54-1.54-.68-1.54-1.54-1.54-1.54,.68-1.54,1.54Z" />
    </Icon>
  );
};
