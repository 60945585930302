import { FC, useState, useMemo, ReactNode } from "react";

import { yupResolver } from "@hookform/resolvers/yup";
import * as Sentry from "@sentry/browser";
import {
  Controller,
  FieldErrorsImpl,
  UseControllerProps,
  UseControllerReturn,
  useForm,
  UseFormRegister,
} from "react-hook-form";
import { useToasts } from "react-toast-notifications2";
import { Grid, Text } from "theme-ui";
import { lazy, object, string } from "yup";

import {
  ObjectQuery,
  RelationshipFragment,
  ResourcePermissionGrant,
  TraitDefinitionFragment,
  useAudiencesWithTraitQuery,
  useCreateTraitMutation,
  useDeleteTraitMutation,
  useUpdateTraitMutation,
} from "src/graphql";
import { TraitType, TraitTypeOptions, ColumnType } from "src/types/visual";
import { Column, Row } from "src/ui/box";
import { Button } from "src/ui/button";
import { Field, FieldError } from "src/ui/field";
import { Input, TextArea } from "src/ui/input";
import { Link } from "src/ui/link";
import { Spinner } from "src/ui/loading";
import { Menu, MenuOption } from "src/ui/menu";
import { Message } from "src/ui/message";
import { Modal } from "src/ui/modal";
import { NewSelect } from "src/ui/new-select";
import { Table } from "src/ui/table";
import { TextWithTooltip } from "src/ui/text";

import useHasPermission from "../../hooks/use-has-permission";

const traitValidationSchema = lazy(({ type }: any) => {
  let configShape;

  switch (type) {
    case TraitType.RawSql:
      configShape = {
        aggregation: string().nullable().required("SQL is required"),
        defaultValue: string().nullable(),
        resultingType: string().nullable().required("Property type is required"),
      };
      break;
    case TraitType.Count:
    case TraitType.Sum:
    case TraitType.Average:
      configShape = { column: object().nullable().required("Column is required") };
      break;
    case TraitType.MostFrequent:
    case TraitType.LeastFrequent:
      configShape = { toSelect: object().nullable().required("Column is required") };
      break;
    case TraitType.First:
    case TraitType.Last:
      configShape = {
        toSelect: object().nullable().required("Column is required"),
        orderBy: object().nullable().required("Order by is required"),
      };
      break;
    default:
      configShape = {};
      break;
  }

  return object()
    .shape({
      name: string().nullable().required("Trait name is required"),
      type: string().nullable().required("Trait aggregation is required"),
      relationship_id: string().nullable().required("Related model is required"),
      config: object(configShape).nullable().default(undefined).required(),
    })
    .default(undefined)
    .required();
});

type Model = NonNullable<ObjectQuery["segments_by_pk"]>;

type Config = {
  aggregation?: string;
  column?: any;
  defaultValue?: string;
  orderBy?: any;
  resultingType?: string;
  toSelect?: any;
};

type Props = {
  model: Model;
};

type NewTrait = {
  name: string;
  type: string;
  relationship_id: any;
  config: Config;
};

const ControllerWithError: FC<
  UseControllerProps<NewTrait> & { render: (props: UseControllerReturn<NewTrait>) => ReactNode }
> = ({ render, ...props }) => {
  return (
    <Controller
      {...props}
      render={(renderProps) => (
        <>
          {render(renderProps)}
          <FieldError error={renderProps.fieldState.error?.message} />
        </>
      )}
    />
  );
};

export const ParentTraits: FC<Readonly<Props>> = ({ model }) => {
  const { traits } = model;

  const { addToast } = useToasts();

  const { mutate: deleteTrait, isLoading: isDeleting } = useDeleteTraitMutation();
  const { hasPermission: hasDeletePermission, isLoading: deletePermissionIsLoading } = useHasPermission([
    { resource: "audience_schema", grants: [ResourcePermissionGrant.Delete] },
  ]);

  const [trait, setTrait] = useState<TraitDefinitionFragment | null>(null);
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [createModalIsOpen, setCreateModalIsOpen] = useState(false);

  const {
    data: traitReferences,
    error: traitReferencesError,
    isLoading: traitReferencesAreLoading,
    refetch: refetchTraitReferences,
  } = useAudiencesWithTraitQuery(
    { traitId: trait?.id },
    {
      // Only make this query when we're deleting a trait.
      enabled: Boolean(trait) && deleteModalIsOpen && !isDeleting,
      onError: (error: Error) => {
        Sentry.captureException(error);
      },
    },
  );

  const tableColumns = [
    {
      key: "name",
      name: "Name",
    },
    {
      key: "relationship.to_model.name",
      name: "Related model",
    },
    {
      key: "type",
      name: "Type",
      cell: (type) => TraitTypeOptions.find((option) => option.value === type)?.label ?? null,
    },
    {
      max: "24px",
      cell: (trait) => {
        return (
          <Menu
            options={[
              {
                label: "Edit",
                onClick: () => setTrait(trait),
              } as MenuOption,
            ].concat(
              !deletePermissionIsLoading && hasDeletePermission
                ? [
                    {
                      label: "Delete",
                      onClick: () => {
                        setTrait(trait);
                        setDeleteModalIsOpen(true);
                      },
                      variant: "danger",
                    },
                  ]
                : [],
            )}
            strategy="fixed"
          />
        );
      },
    },
  ];

  const handleDeleteTrait = async (): Promise<void> => {
    if (!trait) {
      return;
    }
    deleteTrait(
      { id: trait.id },
      {
        onSuccess: () => {
          addToast(`${trait.name} deleted successfully!`, {
            appearance: "success",
          });
          setTrait(null);
          setDeleteModalIsOpen(false);
        },
        onError: (error: Error) => {
          Sentry.captureException(error);
          addToast(`Trait deletion failed`, {
            appearance: "error",
          });
        },
      },
    );
  };

  return (
    <>
      {traits?.length ? (
        <>
          <Row sx={{ justifyContent: "flex-end", width: "100%", mb: 4 }}>
            <Button onClick={() => setCreateModalIsOpen(true)}>+ Add trait</Button>
          </Row>
          <Table columns={tableColumns} data={traits} />
        </>
      ) : (
        <Column sx={{ p: 8, borderRadius: 1, border: "small", alignItems: "center" }}>
          <Text sx={{ fontWeight: "bold", fontSize: 3, mb: 4, color: "base.5" }}>You haven’t added any traits</Text>
          <Text sx={{ mb: 6, color: "base.5", textAlign: "center", maxWidth: "60ch" }}>
            Traits allow you to define and sync specific data from this model
          </Text>
          <Button onClick={() => setCreateModalIsOpen(true)}>+ Add trait</Button>
        </Column>
      )}
      {(createModalIsOpen || (trait && !deleteModalIsOpen)) && (
        <TraitForm
          modelId={model.id}
          relationships={model.relationships}
          trait={trait}
          onClose={() => {
            setTrait(null);
            setCreateModalIsOpen(false);
          }}
        />
      )}
      <DeleteTraitModal
        isOpen={Boolean(trait) && deleteModalIsOpen}
        references={traitReferences?.listAllTraitReferences.audiences}
        referencesAreLoading={traitReferencesAreLoading}
        referencesError={traitReferencesError ? traitReferencesError : undefined}
        traitName={trait?.name}
        onClose={() => {
          setTrait(null);
          setDeleteModalIsOpen(false);
        }}
        onDelete={handleDeleteTrait}
        onRetryReferences={refetchTraitReferences}
      />
    </>
  );
};

const DeleteTraitModal = ({
  isOpen,
  referencesError,
  references,
  referencesAreLoading,
  traitName,
  onClose,
  onDelete,
  onRetryReferences,
}: {
  isOpen: boolean;
  referencesError?: Error;
  references?: {
    id: string;
    name: string;
  }[];
  referencesAreLoading: boolean;
  traitName?: string;
  onClose: () => void;
  onDelete: () => Promise<void>;
  onRetryReferences: () => void;
}) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    await onDelete();
    setIsDeleting(false);
  };

  const getBody = (): ReactNode => {
    if (referencesAreLoading) {
      return (
        <Text>
          <Spinner sx={{ display: "inline-block" }} /> Loading trait references...
        </Text>
      );
    }
    if (referencesError) {
      return <Message variant={"error"}>Failed to check where this trait is used: {referencesError.toString()}</Message>;
    }
    if (references && references.length > 0) {
      return (
        <>
          <Message variant={"error"}>Cannot delete a trait that is used in an audience.</Message>
          <Text sx={{ mt: 6 }}>
            Please remove this trait from all of the audiences it is used in first. This trait is used in the following
            audiences:
          </Text>
          <Grid gap={1} sx={{ ml: 4, mr: 4, mt: 6 }}>
            {references.map(({ name, id }) => (
              <TextWithTooltip key={id} text={name}>
                <Link sx={{ width: "max-content" }} to={`/audiences/${id}`}>
                  {name}
                </Link>
              </TextWithTooltip>
            ))}
          </Grid>
        </>
      );
    }
    return (
      <>
        <Text>
          Are you sure you want to delete the trait {'"'}
          <strong>{traitName}</strong>
          {'"'}?
        </Text>
        <Message sx={{ mt: 6 }} variant={"green"}>
          This trait is not used in any audiences, so it's safe to delete.
        </Message>
      </>
    );
  };

  const getFooter = (): ReactNode => {
    if (referencesAreLoading) {
      // Can't do anything while we query trait references, but still show a loading
      // button to indicate something's happening.
      return <Button loading variant="secondary"></Button>;
    }
    if (referencesError) {
      // Prevent deletion if there was a failure, and allow user to retry.
      return (
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={onRetryReferences}>
            Retry
          </Button>
        </>
      );
    }
    if (references && references.length > 0) {
      // Prevent deletion if there are any references.
      return (
        <Button variant="secondary" onClick={onClose}>
          OK
        </Button>
      );
    }
    // Normal delete dialog.
    return (
      <>
        <Button loading={isDeleting} variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button loading={isDeleting} variant="red" onClick={handleDelete}>
          Delete
        </Button>
      </>
    );
  };

  return (
    <Modal
      bodySx={{ pb: 6 }}
      footer={getFooter()}
      isOpen={isOpen}
      sx={{ maxWidth: 1000 }}
      title={`Delete trait: ${traitName}`}
      onClose={onClose}
    >
      {getBody()}
    </Modal>
  );
};

export const TraitForm: FC<
  Readonly<{
    modelId: string;
    trait?: TraitDefinitionFragment | null;
    relationships: RelationshipFragment[];
    onClose: () => void;
  }>
> = ({ trait, onClose, relationships, modelId }) => {
  const { addToast } = useToasts();
  const {
    formState: { errors },
    register,
    control,
    handleSubmit,
    watch,
  } = useForm({
    resolver: yupResolver(traitValidationSchema),
    defaultValues: {
      name: trait?.name || "",
      type: trait?.type || "",
      relationship_id: trait?.relationship?.id || "",
      config: trait?.config ?? {},
    } as NewTrait,
  });

  const { mutateAsync: updateTrait, isLoading: updating } = useUpdateTraitMutation();
  const { mutateAsync: createTrait, isLoading: creating } = useCreateTraitMutation();

  const type = watch("type");
  const relationshipId = watch("relationship_id");

  const relationshipOptions = useMemo(
    () => relationships.map(({ id, to_model: { name } }) => ({ label: name, value: id })),
    [relationships],
  );
  const propertyOptions = useMemo(() => {
    const columns = relationships.find(({ id }) => id === relationshipId)?.to_model?.filterable_audience_columns;
    if (columns) {
      const validColumns = columns?.filter((column) => (type === TraitType.Sum ? column.type === ColumnType.Number : true));
      return validColumns.map(({ alias, name, column_reference }) => ({
        label: alias || name,
        value: column_reference,
      }));
    }
    return [];
  }, [relationshipId, relationships, type]);

  const submit = async (data) => {
    if (trait) {
      await updateTrait({ id: trait.id, input: data });
      addToast("Trait updated", { appearance: "success" });
    } else {
      await createTrait({ input: { ...data, parent_model_id: modelId } });
      addToast("Trait created", { appearance: "success" });
    }
    onClose();
  };

  const submitting = updating || creating;

  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={submitting} onClick={handleSubmit(submit)}>
            {trait ? "Save" : "Add trait"}
          </Button>
        </>
      }
      sx={{ maxWidth: "568px", width: "100%" }}
      title={trait ? "Edit trait" : "Add trait"}
      onClose={onClose}
    >
      <Grid gap={8}>
        <Field label="Name">
          <Input {...register("name")} error={Boolean(errors.name)} />
          <FieldError error={errors.name?.message} />
        </Field>
        <Field label="Related model">
          <ControllerWithError
            control={control}
            name="relationship_id"
            render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
              <>
                <NewSelect {...fieldRest} error={Boolean(error)} options={relationshipOptions} />
              </>
            )}
          />
        </Field>
        <TraitAggregationFields
          control={control}
          disabled={!relationshipId}
          errors={errors}
          propertyOptions={propertyOptions}
          register={register}
          type={type as TraitType}
        />
      </Grid>
    </Modal>
  );
};

export const TraitAggregationFields: FC<
  Readonly<{
    control: any;
    disabled: boolean;
    errors?: FieldErrorsImpl<NewTrait>;
    propertyOptions: any;
    register: UseFormRegister<any>;
    type: TraitType;
  }>
> = ({ type, control, errors = {}, propertyOptions, register, disabled }) => (
  <>
    <Field description="How should the rows be aggregated?" label="Aggregation">
      <ControllerWithError
        control={control}
        name="type"
        render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
          <NewSelect {...fieldRest} error={Boolean(error)} options={TraitTypeOptions} />
        )}
      />
    </Field>
    {type === TraitType.RawSql && (
      <>
        <Field description="Rows will be aggregated according to a custom SQL aggregation." label="SQL">
          <ControllerWithError
            control={control}
            name="config.aggregation"
            render={({ field, fieldState: { error } }) => (
              <TextArea
                {...field}
                error={Boolean(error)}
                placeholder={"CASE WHEN SUM({{column \"price\"}}) > 100 THEN 'high' ELSE 'low' END`"}
              />
            )}
          />
        </Field>
        <Field description="Defines the value when the above SQL returns no rows." label="Default value">
          <Input {...register("config.defaultValue")} error={Boolean(errors.config?.defaultValue)} />
          <FieldError error={errors.config?.defaultValue?.message} />
        </Field>
        <Field description="Defines the type of the resulting value" label="Property type">
          <ControllerWithError
            control={control}
            name="config.resultingType"
            render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
              <NewSelect
                {...fieldRest}
                error={Boolean(error)}
                options={[
                  {
                    value: ColumnType.Boolean,
                    label: "Boolean",
                  },
                  {
                    value: ColumnType.Number,
                    label: "Number",
                  },
                  {
                    value: ColumnType.String,
                    label: "String",
                  },
                  {
                    value: ColumnType.Timestamp,
                    label: "Timestamp",
                  },
                  {
                    value: ColumnType.Date,
                    label: "Date",
                  },
                  {
                    value: ColumnType.JsonArrayNumbers,
                    label: "JSON Array (Numbers)",
                  },
                  {
                    value: ColumnType.JsonArrayStrings,
                    label: "JSON Array (Strings)",
                  },
                ]}
              />
            )}
          />
        </Field>
      </>
    )}
    {type === TraitType.Count && (
      <Field
        description="Rows will be counted according to the distinct values of this column. If not specified, all rows will be counted."
        label="Count by"
      >
        <ControllerWithError
          control={control}
          name="config.column"
          render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
            <NewSelect {...fieldRest} disabled={disabled} error={Boolean(error)} options={propertyOptions} />
          )}
        />
      </Field>
    )}
    {type === TraitType.Sum && (
      <Field description="The column that will be summed for all rows" label="Sum by">
        <ControllerWithError
          control={control}
          name="config.column"
          render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
            <NewSelect {...fieldRest} disabled={disabled} error={Boolean(error)} options={propertyOptions} />
          )}
        />
      </Field>
    )}
    {type === TraitType.Average && (
      <Field description="The column that will be averaged for all rows" label="Average by">
        <ControllerWithError
          control={control}
          name="config.column"
          render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
            <NewSelect {...fieldRest} disabled={disabled} error={Boolean(error)} options={propertyOptions} />
          )}
        />
      </Field>
    )}
    {(type === TraitType.MostFrequent || type === TraitType.LeastFrequent) && (
      <>
        <Field description="Frequency will be based on this column" label="Frequency column">
          <ControllerWithError
            control={control}
            name="config.toSelect"
            render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
              <NewSelect {...fieldRest} disabled={disabled} error={Boolean(error)} options={propertyOptions} />
            )}
          />
        </Field>
      </>
    )}
    {(type === TraitType.First || type === TraitType.Last) && (
      <>
        <Field description="The column that represents the value" label="Trait value">
          <ControllerWithError
            control={control}
            name="config.toSelect"
            render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
              <NewSelect {...fieldRest} disabled={disabled} error={Boolean(error)} options={propertyOptions} />
            )}
          />
        </Field>
        <Field description="Rows will be ordered according to this column" label="Order by">
          <ControllerWithError
            control={control}
            name="config.orderBy"
            render={({ field: { ref, ...fieldRest }, fieldState: { error } }) => (
              <NewSelect {...fieldRest} disabled={disabled} error={Boolean(error)} options={propertyOptions} />
            )}
          />
        </Field>
      </>
    )}
  </>
);
