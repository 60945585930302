import { FC } from "react";

import { Button, Spinner, Paragraph, Text, Link, Box, useToast } from "@hightouchio/ui";

import { useDeleteTunnelMutation, useTunnelSourceQuery } from "src/graphql";
import { Modal } from "src/ui/modal";

type Props = {
  onClose: () => void;
  name: string;
  id: string;
  refetch: () => void;
};

export const DeleteTunnel: FC<Readonly<Props>> = ({ name, id, onClose, refetch }) => {
  const { toast } = useToast();

  const { data: tunnelSourceData, isLoading: dependencyLoading } = useTunnelSourceQuery({ tunnelID: id });
  const { isLoading: deleting, mutateAsync: deleteTunnel } = useDeleteTunnelMutation();

  const dependency = tunnelSourceData?.connections?.[0];

  return (
    <Modal
      bodySx={{ bg: "white" }}
      footer={
        <>
          <Button onClick={onClose}>{dependency ? "Close" : "Cancel"}</Button>
          <Button
            isDisabled={dependencyLoading || Boolean(dependency)}
            isLoading={deleting}
            variant="danger"
            onClick={async () => {
              try {
                await deleteTunnel({ id });

                refetch();

                onClose();

                toast({
                  id: "delete-tunnel",
                  title: "Tunnel deleted",
                  variant: "success",
                });
              } catch (error: unknown) {
                toast({
                  id: "delete-tunnel",
                  title: "Something went wrong",
                  message: "Failed to delete the tunnel, please try again.",
                  variant: "error",
                });
              }
            }}
          >
            Delete tunnel
          </Button>
        </>
      }
      sx={{ width: "520px" }}
      title="Delete tunnel"
      onClose={onClose}
    >
      {dependencyLoading ? (
        <Box display="flex" justifyContent="center">
          <Spinner />
        </Box>
      ) : dependency ? (
        <>
          <Paragraph>
            You cannot delete <Text fontWeight="semibold">{name}</Text> because the{" "}
            <Link href={`/sources/${dependency.id}`}>{dependency.name}</Link> source depends on it.
          </Paragraph>
        </>
      ) : (
        <Paragraph>
          Are you sure you want to delete the <Text fontWeight="semibold">{name}</Text> tunnel?
        </Paragraph>
      )}
    </Modal>
  );
};
