import { FC } from "react";

import { Text } from "theme-ui";

import { Column, Row } from "src/ui/box";
import { CalendarIcon, StringIcon } from "src/ui/icons";

import { FormProps } from "./types";

enum VariableType {
  MODEL_NAME = "model-name",
  CURRENT_TIMESTAMP = "current-timestamp",
}

const VARIABLE_OPTIONS = [
  {
    label: "model-name",
    variable: VariableType.MODEL_NAME,
    icon: StringIcon,
  },
  {
    label: "current-timestamp",
    variable: VariableType.CURRENT_TIMESTAMP,
    icon: CalendarIcon,
  },
];

export const VariableInput: FC<Readonly<FormProps>> = ({ value, onChange }) => {
  const getInput = () => {
    switch (value.variable) {
      case VariableType.MODEL_NAME:
        return (
          <Text>
            <Text sx={{ fontWeight: "semi", fontFamily: "code", display: "inline-block" }}>model-name</Text> will send the name
            of the current model as a string.
          </Text>
        );
      case VariableType.CURRENT_TIMESTAMP:
        return (
          <Text>
            <Text sx={{ fontWeight: "semi", fontFamily: "code", display: "inline-block" }}>current-timestamp</Text> will send
            the current timestamp as a string.
          </Text>
        );
      default:
        return undefined;
    }
  };

  return (
    <Row sx={{ flex: 1 }}>
      <Column sx={{ p: 3, borderRight: "small", width: "180px" }}>
        {VARIABLE_OPTIONS.map((option) => {
          const active = option.variable === value.variable;
          return (
            <Row
              key={option.label}
              sx={{
                fontWeight: "semi",
                p: 2,
                borderRadius: 1,
                cursor: "pointer",
                minHeight: "32px",
                alignItems: "center",
                fontSize: 0,
                flex: 0,
                px: 2,
                bg: active ? "primaries.0" : undefined,
                ":hover": { bg: active ? undefined : "base.0" },
              }}
              onClick={() => onChange({ type: "variable", variable: option.variable })}
            >
              <option.icon color="base.5" size={18} sx={{ mr: 2 }} />
              <Text sx={{ color: active ? "primary" : "base.5" }}>{option.label}</Text>
            </Row>
          );
        })}
      </Column>
      <Column sx={{ flex: 1, p: 3 }}>
        <Row>{getInput()}</Row>
      </Column>
    </Row>
  );
};
