import { Fragment, FC, ReactElement } from "react";

import { Text } from "theme-ui";

import { Row } from "src/ui/box";
import { Link } from "src/ui/link";

export type Crumb = {
  label: string | ReactElement;
  link?: string;
};

type Props = {
  crumbs: Crumb[];
};

export const Breadcrumbs: FC<Readonly<Props>> = ({ crumbs }) => {
  return (
    <Row sx={{ overflow: "hidden", pr: 8, flex: 1 }}>
      {crumbs.map(({ label, link }, index) => (
        <Fragment key={index}>
          {link ? (
            <Link
              sx={{
                display: "flex",
                alignItems: "center",
                ":hover": {
                  color: "primaries.8",
                },
                color: index === crumbs.length - 1 ? "black" : "base.5",
                flexShrink: 0,
              }}
              to={link}
            >
              {label}
            </Link>
          ) : (
            <Text
              sx={{
                fontWeight: "semi",
                color: index === crumbs.length - 1 ? "black" : "base.5",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              {label}
            </Text>
          )}
          {index < crumbs.length - 1 && <Text sx={{ fontWeight: "bold", color: "base.5", mx: 3, flexShrink: 0 }}>/</Text>}
        </Fragment>
      ))}
    </Row>
  );
};
