import { FC } from "react";

import { IconProps, Icon } from "./icon";

export const OnboardingIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M23.69,.31c-.21-.21-.52-.31-.84-.31l-3.46,.52c-3.14,.42-6.18,1.99-8.49,4.19l-1.68,1.68c-1.05-.1-3.98-1.78-6.39,.63L.31,9.64c-.21,.21-.31,.42-.31,.73s.1,.52,.31,.73c.1,.1,5.87,5.87,5.55,5.55l3.25-3.25c.42-.42,1.05-.42,1.47,0s.42,1.05,0,1.47l-3.25,3.25c.1,.1,5.87,5.97,5.55,5.55,.21,.21,.52,.31,.73,.31,.31,0,.52-.1,.73-.31l2.52-2.52c1.15-1.15,1.57-2.83,1.26-4.3l-.52-2.1,1.68-1.68c2.31-2.31,3.77-5.24,4.19-8.49l.52-3.35c0-.42-.1-.73-.31-.94ZM3.88,11.74l-1.36-1.36,1.78-1.78c1.26-1.26,2.93-.42,3.25-.42l-3.67,3.56Zm11.53,7.97l-1.78,1.78-1.36-1.36,3.67-3.67,.21,.84c.1,.84-.1,1.78-.73,2.41Zm3.14-11.21c-.84,.84-2.31,.84-3.14,0-.84-.84-.84-2.2,0-3.14,.84-.84,2.2-.84,3.14,0,.84,.94,.84,2.31,0,3.14Z" />
      <g opacity={0.4}>
        <path d="M.29,16.29l.87-.88c.39-.29,.97-.39,1.36,0s.39,.98,0,1.37l-.87,.88c-.39,.39-.97,.39-1.36,0-.39-.39-.39-.98,0-1.37Z" />
        <path d="M8.55,22.83l-.87,.88c-.39,.39-.97,.39-1.36,0-.39-.39-.39-.98,0-1.37l.87-.88c.39-.39,.97-.39,1.36,0,.39,.39,.39,.98,0,1.37Z" />
        <path d="M5.54,19.82l-1.89,1.89c-.39,.39-.97,.39-1.36,0-.39-.39-.39-.98,0-1.37l1.89-1.89c.39-.39,.97-.39,1.36,0,.39,.39,.39,.98,0,1.37Z" />
      </g>
    </Icon>
  );
};
