import { FC } from "react";

import { useDestinationForm } from "src/contexts/destination-form-context";
import { Field } from "src/ui/field";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";

type PIIHashingFieldProps = {
  error?: string;
  value?: string;
  onChange?: (value: string) => void;
};

export const PIIHashingField: FC<Readonly<PIIHashingFieldProps>> = ({ error, value, onChange }) => {
  const { config, setConfig, errors } = useDestinationForm();

  const val = value || config?.disablePIIHashing;
  const err = error || errors?.disablePIIHashing;

  return (
    <Section>
      <Field
        description={
          "By default, Hightouch automatically hashes values mapped to certain \
          user data fields. For more information, check out the documentation."
        }
        error={err}
        label={"Would you like Hightouch to automatically hash your PII data?"}
        size="large"
      >
        <RadioGroup
          options={[
            { label: "Yes, automatically hash PII for me", value: undefined },
            { label: "No, I will hash it myself", value: true },
          ]}
          value={val}
          onChange={(disablePIIHashing) => {
            if (onChange) {
              onChange(disablePIIHashing as string);
            } else {
              setConfig({ ...config, disablePIIHashing });
            }
          }}
        />
      </Field>
    </Section>
  );
};
